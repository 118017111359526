import React, { } from 'react';
import PropTypes from 'prop-types';

import { createStyles, makeStyles } from '@mui/styles';
import { TextField, InputAdornment } from '@mui/material';

const useStyles = makeStyles((theme) =>
  createStyles({
    customTextField: {
      width: '100%',
      borderRadius: 4,
      border: 'none',
      '& input': {
        backgroundColor: 'white',
        borderRadius: 4,
        fontSize: 16,
        padding: 9,
        borderColor: '#B2B9CB',
        '& .Mui-error':{
          borderColor: '#d32f2f'
        },
        
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#B2B9CB',
        '& .Mui-error':{
          borderColor: '#d32f2f'
        },
      },
    },
    textField: {
      fontSize: 12,
      marginTop: 12,
    }
  })
);

const CustomTextField = (props) => {
  const {
    value,
    name,
    style,
    error,
    helperText,
    multiline,
    rows,
    placeholder,
    disabled,
    inputRef,
    variant,
    iconend,
    textTopField,
    maxLength,
    minLength
  } = props;
  const classes = useStyles(props);

  return (
    <div >
      {textTopField ?
        <p className={classes.textField} style={{ color: error ? '#d32f2f' : '#99A0AB' }}>
          {textTopField}
        </p>
        : ''
      }
      <TextField
        {...props}
        value={value}
        name={name}
        style={{ ...style, borderColor: error ? '#d32f2f !important' : '#99A0AB' }}
        className={classes.customTextField}
        error={error}
        focused={false}
        helperText={error ? helperText : ''}
        multiline={multiline || false}
        rows={rows}
        placeholder={placeholder}
        disabled={disabled || false}
        inputRef={inputRef}
        variant={variant}
        inputProps={{
          maxLength: maxLength,
          minLength: minLength,
        }}
        InputProps={{
          endAdornment: iconend && (
            <InputAdornment position="end">{iconend}</InputAdornment>
          ),
        }}
      />
    </div>
  );
};

CustomTextField.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  style: PropTypes.any,
  className: PropTypes.any,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  inputRef: PropTypes.any,
  variant: PropTypes.string,
};

export default CustomTextField;