import React from "react";
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import store from './firebase/services';
import { ProvideAuth } from '@context/auth';

import CustomMessage from "./components/CustomMessage";
import Routes from './routes';

function App() {
  const theme = createTheme();

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <ProvideAuth>
            <Routes />
          </ProvideAuth>
          <CustomMessage />
        </Provider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;