import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper,
    TableSortLabel,
    Box,
    Grid,
    IconButton,
    CircularProgress
} from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { makeStyles, withStyles, createStyles } from '@mui/styles';
import { visuallyHidden } from "@mui/utils";

import { getAllUISchema, deleteUISchema } from 'app/firebase/services/uiSchemaSlice';

import Navbar from 'app/components/Navbar';
import CustomDialog from 'app/components/CustomDialog';
import CustomButton from 'app/components/CustomButton';
import AddSchema from './AddSchema';
import EditSchema from './EditSchema';

const StyledTableCell = withStyles(() =>
    createStyles({
        head: {
            color: 'black',
            border: 'none',
            padding: '10 16px',
        },
        body: {
            fontSize: 14,
            padding: '10px 16px',
            border: 'none',
            height: 75,
            color: '#535F68',
            fontWeight: 500
        },
    }),
)(TableCell);

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: 'white',
            },
            backgroundColor: '#FBFBFE',
        },
    }),
)(TableRow);

const useStyles = makeStyles(() => ({
    tableCustom: {
        borderRadius: 6
    },
    headTableCus: {
        background: '#F1F3FB',
        height: 50
    },
    tablecellCust: {
        color: '#535F68',
        fontSize: 16,
        fontWeight: 'bold',
        width: '16%'
    },
    instuctionsText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    }
}))

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "name",
        oder: true,
        label: "Application Name",
        width: '15%'
    },
    {
        id: "displayName",
        oder: true,
        label: "Display Name",
        width: '15%'
    },
    {
        id: "appVersion",
        oder: true,
        label: "App Version",
        width: '10%'
    },
    {
        id: "instructionsReceive",
        oder: true,
        label: "Instructions Receive Payment",
        width: '20%'
    },
    {
        id: "instructionsPost",
        oder: true,
        label: "Instructions Post Payment",
        width: '20%'
    },
    {
        id: "instructionsRefund",
        oder: true,
        label: "Instructions Post Refund",
        width: '20%'
    },
    {
        id: "details",
        oder: false,
        label: "Details",
        width: '10%'
    },
    {
        id: "delete",
        oder: false,
        label: "",
        width: '5%'
    },
];
function EnhancedTableHead(props) {
    const classes = useStyles();
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.headTableCus}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className={classes.tablecellCust}
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ width: headCell.width, whiteSpace: 'nowrap' }}
                    >
                        {headCell.oder ?
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                            :
                            headCell.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


export default function Companies(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [openDialogAdd, setOpenDialogAdd] = useState(false);
    const [openDialogEdit, setOpenDialogEdit] = useState(false);
    const [uiShcemaDel, setuiShcemaDel] = useState(false);
    const [dialogDelete, setDialogDelete] = useState(false);
    const [loadingDel, setLoadingDel] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [itemSchema, setItemSchema] = useState('');
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");

    const dataSchema = useSelector(({ uiSchema }) => uiSchema.dataUISchema)

    useEffect(() => {
        const fetch = async () => {
            setLoadingData(true)
            await dispatch(getAllUISchema())
            setLoadingData(false)
        }
        fetch()
    }, [])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const sendDeleteLocatioSetting = async () => {
        setLoadingDel(true)
        await dispatch(deleteUISchema(uiShcemaDel))
        await dispatch(getAllUISchema())
        setLoadingDel(false)
        setDialogDelete(false)
    }

    return (
        <div style={{ minWidth: 600 }}>
            <Navbar />
            <Grid
                container
                justifyContent='center'
                className='pb-32'
            >
                <Grid
                    item
                    xs={11} lg={10}
                    style={{ marginTop: 15 }}
                >
                    <div className='flex justify-between items-end mb-32 mt-20'>
                        <div className='flex flex-col'>
                            <p className='text-primaryText text-15 font-600'>
                                UI Schemas
                            </p>
                            <div className='flex text-textLight mb-10' style={{ fontSize: 14 }}>
                                <a href='/dashboard' className='underline'>
                                    Companies list
                                </a>
                                &nbsp;/&nbsp;
                                <p className='underline'>
                                    UI Schemas
                                </p>
                            </div>
                            <CustomButton
                                label='Add UI Schema'
                                onClick={() => {
                                    setOpenDialogAdd(true);
                                }}
                            />
                        </div>
                    </div>

                    <div sx={{ width: "100%", mb: 2 }}>
                        {loadingData ?
                            <div className='w-full flex justify-center mt-12'>
                                <CircularProgress size={30} style={{ color: '#0594FB' }} />
                            </div>
                            :
                            <TableContainer component={Paper} className={classes.tableCustom}>
                                <Table sx={{ minWidth: 600 }}>
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={dataSchema.length}
                                    />
                                    <TableBody>
                                        {dataSchema && dataSchema.length > 0 ? (
                                            stableSort(dataSchema, getComparator(order, orderBy))
                                                .map((e) => {

                                                    return (
                                                        <StyledTableRow key={e.id}>
                                                            <StyledTableCell
                                                                align="left"
                                                                style={{ width: '15%' }}
                                                            >
                                                                <div className='bg-light w-max rounded-2 p-2 text-secondaryText'>
                                                                    {e.name}
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="left"
                                                                style={{ width: '15%' }}
                                                            >
                                                                {e.displayName}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="left"
                                                                style={{ width: '10%' }}
                                                            >
                                                                {e.appVersion}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                style={{ width: '20%', maxWidth: 300 }}
                                                                align="left"
                                                            >
                                                                <div className={classes.instuctionsText}>
                                                                    {e.instructions_receivePayment}
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                style={{ width: '20%', maxWidth: 300 }}
                                                                align="left"
                                                            >
                                                                <div className={classes.instuctionsText}>
                                                                    {e.instructions_postPayment}
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                style={{ width: '20%', maxWidth: 300 }}
                                                                align="left"
                                                            >
                                                                <div className={classes.instuctionsText}>
                                                                    {e.instructions_postRefund}
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                style={{
                                                                    width: '10%',
                                                                    color: '#0594FB',
                                                                    cursor: 'pointer'
                                                                }}
                                                                align="left"
                                                                onClick={() => {
                                                                    setOpenDialogEdit(true)
                                                                    setItemSchema(e)
                                                                }}
                                                            >
                                                                View
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left" style={{ with: '5%' }}>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setuiShcemaDel(e.id)
                                                                        setDialogDelete(true)
                                                                    }}
                                                                >
                                                                    <DeleteRoundedIcon style={{ color: '#99A0AB' }} />
                                                                </IconButton>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    );
                                                })) :
                                            <StyledTableRow>
                                                <StyledTableCell
                                                    align="left"
                                                    style={{ padding: 16, fontSize: 18 }}
                                                >
                                                    No data found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        }
                                        <CustomDialog
                                            open={dialogDelete}
                                            title='Are you sure you want to eliminate the UI Schema?'
                                            actions={
                                                loadingDel ?
                                                    <CircularProgress size={30} style={{ color: '#0594FB' }} />
                                                    :
                                                    <div className='w-full flex justify-center items-end mt-10'>
                                                        <p
                                                            className='underline text-textLight mr-10 cursor-pointer'
                                                            onClick={() => {
                                                                setDialogDelete(false)
                                                            }}
                                                        >
                                                            Cancel
                                                        </p>
                                                        <CustomButton
                                                            label='yes'
                                                            onClick={sendDeleteLocatioSetting}
                                                        />
                                                    </div>
                                            }
                                        />
                                    </TableBody>
                                    <AddSchema
                                        open={openDialogAdd}
                                        onClose={() => {
                                            setOpenDialogAdd(false);
                                        }}
                                    />
                                    <EditSchema
                                        open={openDialogEdit}
                                        data={itemSchema}
                                        onClose={() => {
                                            setOpenDialogEdit(false);
                                        }}
                                    />
                                </Table>
                            </TableContainer>
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}