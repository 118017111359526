import React, { useState } from 'react';

import { Card, CircularProgress, Divider, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CustomButton from 'app/components/CustomButton';
import CustomSelect from 'app/components/CustomSelect';
import CustomTextField from 'app/components/CustomTextField';

const useStyles = makeStyles({
    dialog: {
        '& .MuiPaper-root.MuiDialog-paper': {
            borderRadius: 20
        },
    },
    cardContainer: {
        margin: "auto",
        borderRadius: 20,
        minWidth: 500,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    textField: {
        color: '#99A0AB',
        fontSize: 12,
        marginTop: 12,
    }
});

function DialogPost(props) {
    const { form, setForm, onSave, onClose, open } = props
    const classes = useStyles();

    const [errorField, setErrorField] = useState(false)
    const [loading, setLoading] = useState(false)

    const onChangeText = (prop) => (event) => {
        if (prop === ('firstNameDataType' || 'chartNumDataType' || 'amountDataType' || 'transactionIdDataType')) {
            if (prop === 'firstNameDataType' && (event.target.value === 'windowTitle' || event.target.value === '')) {
                setForm({ ...form, [prop]: event.target.value, firstNamePath: '' })
            } else if (prop === 'chartNumDataType' && (event.target.value === 'windowTitle' || event.target.value === '')) {
                setForm({ ...form, [prop]: event.target.value, chartNumberPath: '' })
            } else if (prop === 'amountDataType' && (event.target.value === 'windowTitle' || event.target.value === '')) {
                setForm({ ...form, [prop]: event.target.value, amountPath: '' })
            } else if (prop === 'transactionIdDataType' && (event.target.value === 'windowTitle' || event.target.value === '')) {
                setForm({ ...form, [prop]: event.target.value, transactionIdPath: '' })
            } else {
                setForm({ ...form, [prop]: event.target.value })
            }
        } else {
            setForm({ ...form, [prop]: event.target.value })
        }
    };

    const send = async () => {
        setLoading(true)
        if (form.processName === '') {
            setErrorField(true)
            setLoading(false)
        } else {
            setLoading(false)
            onSave(form)
        }
    }

    const aplicaionsOptions = [
        { label: '', value: '', },
        { label: 'Field', value: 'field', },
        { label: 'Window Title', value: 'windowTitle', },
    ]

    const windowOptions = [
        { label: '', value: '' },
        { label: 'Starts With', value: 'startsWith' },
        { label: 'Contains', value: 'contains' },
    ]

    return (
        <Dialog
            style={{
                justifyContent: "center",
                justifyItems: "center",
                display: "flex",
            }}
            className={classes.dialog}
            open={open}
        >


            <Card className={classes.cardContainer}>
                <div
                    className='flex justify-center items-center py-20'
                    style={{ background: '#FAFAFD' }}
                >
                    <p className='text-primaryText text-13 font-600'>
                        Post Payment
                    </p>
                </div>
                <div style={{ marginBottom: 38 }} className='px-16'>
                    {/* --------------------------------- First Name ---------------------------------------------- */}
                    <CustomSelect
                        label='First Name'
                        options={aplicaionsOptions}
                        value={form.firstNameDataType}
                        name='firstNameDataType'
                        onChange={onChangeText('firstNameDataType')}
                    />
                    {form.firstNameDataType === 'field' || form.firstNameDataType === 'windowTitle' ?
                        <>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomSelect
                                        label={form.firstNameDataType === 'field' ? 'Field path' : 'Window Title'}
                                        options={windowOptions}
                                        value={form.fistNIndicatorType}
                                        name='fistNIndicatorType'
                                        onChange={onChangeText('fistNIndicatorType')}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField={form.fistNIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                        value={form.fistNIndicator}
                                        onChange={onChangeText('fistNIndicator')}
                                        name='fistNIndicator'
                                    />
                                </div>
                            </div>
                            {form.firstNameDataType === 'field' ?
                                <CustomTextField
                                    textTopField='First name path'
                                    value={form.firstNamePath}
                                    onChange={onChangeText('firstNamePath')}
                                    name='firstNamePath'
                                /> : ''
                            }
                            <Divider style={{ marginTop: 15 }} />
                        </> : ''
                    }
                    {/* --------------------------------- Last Name ---------------------------------------------- */}
                    <CustomSelect
                        label='Last Name'
                        options={aplicaionsOptions}
                        value={form.lastNameDataType}
                        name='lastNameDataType'
                        onChange={onChangeText('lastNameDataType')}
                    />
                    {form.lastNameDataType === 'field' || form.lastNameDataType === 'windowTitle' ?
                        <>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomSelect
                                        label={form.lastNameDataType === 'field' ? 'Field path' : 'Window Title'}
                                        options={windowOptions}
                                        value={form.lastNIndicatorType}
                                        name='lastNIndicatorType'
                                        onChange={onChangeText('lastNIndicatorType')}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField={form.lastNIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                        value={form.lastNIndicator}
                                        onChange={onChangeText('lastNIndicator')}
                                        name='lastNIndicator'
                                    />
                                </div>
                            </div>
                            {form.lastNameDataType === 'field' ?
                                <CustomTextField
                                    textTopField='Last name path'
                                    value={form.lastNamePath}
                                    onChange={onChangeText('lastNamePath')}
                                    name='lastNamePath'
                                /> : ''
                            }
                            <Divider style={{ marginTop: 15 }} />
                        </> : ''
                    }
                    {/* --------------------------------- Chart Number ---------------------------------------------- */}
                    <CustomSelect
                        label='Chart Number'
                        options={aplicaionsOptions}
                        value={form.chartNumDataType}
                        name='chartNumDataType'
                        onChange={onChangeText('chartNumDataType')}
                    />
                    {form.chartNumDataType === 'field' || form.chartNumDataType === 'windowTitle' ?
                        <>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomSelect
                                        label={form.chartNumDataType === 'field' ? 'Field path' : 'Window Title'}
                                        options={windowOptions}
                                        value={form.chartNumIndicatorType}
                                        name='chartNumIndicatorType'
                                        onChange={onChangeText('chartNumIndicatorType')}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField={form.chartNumIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                        value={form.chartNumIndicator}
                                        onChange={onChangeText('chartNumIndicator')}
                                        name='chartNumIndicator'
                                    />
                                </div>
                            </div>
                            {form.chartNumDataType === 'field' ?
                                <CustomTextField
                                    textTopField='Chart number path'
                                    value={form.chartNumberPath}
                                    onChange={onChangeText('chartNumberPath')}
                                    name='chartNumberPath'
                                /> : ''
                            }
                            <Divider style={{ marginTop: 15 }} />
                        </> : ''
                    }
                    {/* --------------------------------- Amount ---------------------------------------------- */}
                    <CustomSelect
                        label='Amount'
                        options={aplicaionsOptions}
                        value={form.amountDataType}
                        name='amountDataType'
                        onChange={onChangeText('amountDataType')}
                    />
                    {form.amountDataType === 'field' || form.amountDataType === 'windowTitle' ?
                        <>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomSelect
                                        label={form.amountDataType === 'field' ? 'Field path' : 'Window Title'}
                                        options={windowOptions}
                                        value={form.amountIndicatorType}
                                        name='amountIndicatorType'
                                        onChange={onChangeText('amountIndicatorType')}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField={form.amountIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                        value={form.amountIndicator}
                                        onChange={onChangeText('amountIndicator')}
                                        name='amountIndicator'
                                    />
                                </div>
                            </div>
                            {form.amountDataType === 'field' ?
                                <CustomTextField
                                    textTopField='Amount path'
                                    value={form.amountPath}
                                    onChange={onChangeText('amountPath')}
                                    name='amountPath'
                                />
                                : ''
                            }
                            <Divider style={{ marginTop: 15 }} />
                        </> : ''
                    }
                    {/* ----------------------------------------------- TransactionId Path -------------------------------- */}
                    <CustomSelect
                        label='Transaction Id'
                        options={aplicaionsOptions}
                        value={form.transactionIdDataType}
                        name='transactionIdDataType'
                        onChange={onChangeText('transactionIdDataType')}
                    />
                    {form.transactionIdDataType === 'field' || form.transactionIdDataType === 'windowTitle' ?
                        <>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomSelect
                                        label={form.transactionIdDataType === 'field' ? 'Field path' : 'Window Title'}
                                        options={windowOptions}
                                        value={form.transactionIdIndicatorType}
                                        name='transactionIdIndicatorType'
                                        onChange={onChangeText('transactionIdIndicatorType')}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField={form.transactionIdIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                        value={form.transactionIdIndicator}
                                        onChange={onChangeText('transactionIdIndicator')}
                                        name='transactionIdIndicator'
                                    />
                                </div>
                            </div>
                            {form.transactionIdDataType === 'field' ?
                                <CustomTextField
                                    textTopField='Transaction Id path'
                                    value={form.transactionIdPath}
                                    onChange={onChangeText('transactionIdPath')}
                                    name='transactionIdPath'
                                />
                                : ''
                            }
                            <Divider style={{ marginTop: 15 }} />
                        </> : ''
                    }
                    <CustomTextField
                        textTopField='Process name'
                        value={form.processName}
                        onChange={onChangeText('processName')}
                        name='processName'
                        error={form.processName === '' ? errorField : null}
                    />
                    {/* ---------------------- */}
                    {loading ?
                        <div className='w-full flex justify-center mt-12'>
                            <CircularProgress size={30} style={{ color: '#0594FB' }} />
                        </div>
                        :
                        <div className='w-full flex justify-end items-center mt-20'>
                            <p
                                className='underline text-textLight mr-10 cursor-pointer'
                                onClick={onClose}
                            >
                                Cancel
                            </p>
                            <CustomButton
                                label='save'
                                onClick={send}
                            />
                        </div>
                    }
                </div>
            </Card>
        </Dialog>
    );
}

export default DialogPost;