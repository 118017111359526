import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Card, CircularProgress, IconButton, Snackbar, Dialog } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { makeStyles } from '@mui/styles';

import { createContact } from 'app/firebase/services/contactSlice';
import { getCompanyById } from 'app/firebase/services/companiesSlice';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CustomTextField from 'app/components/CustomTextField';
import CustomButton from 'app/components/CustomButton';
import CustomPhoneNumberInput from 'app/components/CustomPhoneNumberInput';

const useStyles = makeStyles({
    dialog: {
        '& .MuiPaper-root.MuiDialog-paper': {
            borderRadius: 20
        },
    },
    cardContainer: {
        margin: "auto",
        minWidth: 500,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    textField: {
        color: '#99A0AB',
        fontSize: 12,
        marginTop: 12,
    },
    custNackbar: {
        '& .MuiSnackbarContent-root': {
            background: '#FD8585',
            color: 'white',
            borderRadius: 13
        }
    }
});

function AddContact(props) {
    const { onClose, companyId, open } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errorField, setErrorField] = useState(false)
    const [showPass, setShowPass] = useState(false)
    const [loading, setLoading] = useState(false)
    const [snackbar, setSnackbar] = useState(false)
    const [message, setMessage] = useState(false)
    const [form, setForm] = useState({
        name: '',
        email: '',
        title: '',
        phone: '',
        status: 'active',
        password: ''
    })

    const handleClickShowPassword = () => {
        setShowPass(!showPass);
    };

    const onChangeText = (prop) => (event) => {
        setForm({ ...form, [prop]: event.target.value })
    };

    const sendNewContact = async () => {
        setLoading(true)
        if (form.name === '' || form.email === '' || form.title === '' || form.phone === '' || form.phone === undefined
            || form.password === '' || (form.password.length >= 1 && form.password.length < 6)) {
            setErrorField(true)
            setLoading(false)
        } else {
            const result = await dispatch(createContact(companyId, form))
            if (result && result.status) {
                setLoading(false)
                setSnackbar(true)
                setMessage(result.message)
            } else {
                await dispatch(getCompanyById(companyId))
                setLoading(false)
                onClose()
            }
        }
    }

    return (
        <Dialog
            style={{
                justifyContent: "center",
                justifyItems: "center",
                display: "flex",
            }}
            className={classes.dialog}
            open={open}
        >
            <Card className={classes.cardContainer}>
                <Snackbar
                    open={snackbar}
                    onClose={() => {
                        setSnackbar(false)
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    autoHideDuration={4000}
                    className={classes.custNackbar}
                    message={message}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={() => {
                                setSnackbar(false)
                            }}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    }
                />
                <div
                    className='flex justify-center items-center py-12'
                    style={{ background: '#FAFAFD' }}
                >
                    <p className='text-primaryText text-13 font-600'>
                        Add Contact
                    </p>
                </div>
                <div style={{ marginBottom: 38 }} className='px-16'>
                    <CustomTextField
                        textTopField='Full name*'
                        value={form.name}
                        onChange={onChangeText('name')}
                        name='name'
                        error={form.name === '' ? errorField : null}
                    />
                    <CustomTextField
                        textTopField=' Email*'
                        value={form.email}
                        onChange={onChangeText('email')}
                        name='email'
                        error={form.email === '' ? errorField : null}
                    />
                    <CustomTextField
                        textTopField='Password'
                        value={form.password}
                        name='password'
                        placeholder="*******"
                        type={showPass ? 'text' : 'password'}
                        error={errorField || (form.password.length >= 1 && form.password.length < 6) ? true : false}
                        helperText={(form.password.length >= 1 && form.password.length < 6) ? 'Min 6 caracters' : ''}
                        onChange={e => {
                            setForm({ ...form, password: e.target.value });
                        }}
                        iconend={
                            <IconButton
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPass ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        }
                    />
                    <CustomTextField
                        textTopField='Title'
                        value={form.title}
                        onChange={onChangeText('title')}
                        name='title'
                        error={form.title === '' ? errorField : null}
                    />
                    <p
                        className={classes.textField}
                        style={{
                            color: (form.phone === '' || form.phone === undefined) && errorField ?
                                '#d32f2f' : '#99A0AB'
                        }}
                    >
                        Phone Number
                    </p>
                    <CustomPhoneNumberInput
                        country="US"
                        value={form.phone}
                        maxLength={14}
                        onChange={e => {
                            setForm({ ...form, phone: e });
                        }}
                        style={{
                            border: (form.phone === '' || form.phone === undefined) && errorField ?
                                '1px solid #d32f2f' : null
                        }}
                    />
                    {loading ?
                        <div className='w-full flex justify-center mt-12'>
                            <CircularProgress size={30} style={{ color: '#0594FB' }} />
                        </div>
                        :
                        <div className='w-full flex justify-end items-center mt-20'>
                            <p
                                className='underline text-textLight mr-10 cursor-pointer'
                                onClick={onClose}
                            >
                                Cancel
                            </p>
                            <CustomButton
                                label='save'
                                onClick={sendNewContact}
                            />
                        </div>
                    }
                </div>
            </Card>
        </Dialog>
    );
}

export default AddContact;
