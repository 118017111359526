import firebase from "firebase/app";
import "firebase/firebase-functions";
import "firebase/firebase-auth";
import "firebase/firebase-firestore";
import { getFirebaseConfig } from './firebase-config.js';

// eslint-disable-next-line
firebase.initializeApp(getFirebaseConfig());

// firebase.functions().useEmulator("localhost", 5000);

export default firebase;

window.firebase = firebase;