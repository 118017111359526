import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API from './config';

import firebase from "../firebase";

export const signInRedux = (data) => async (dispatch) => {
  try {
    const resul = await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
    if (resul) {
      const data = {
        uid: resul.user.uid,
        displayName: resul.user.displayName,
        email: resul.user.email,
        phoneNumber: resul.user.phoneNumber,
        photoURL: resul.user.photoURL,
        accessToken: resul.user.accessToken,
      };
      await window.localStorage.setItem('@user', JSON.stringify(data));
      await dispatch(setUser(data));
    }
  } catch (error) {
    throw new Error(`*** REDUX -> signIn *** ${error}`);
  }
};

export const signOutRedux = () => async (dispatch) => {
  try {
    await firebase.auth().signOut();
    // await window.localStorage.removeItem('@user');
    await dispatch(setUser({}));

  } catch (error) {
    throw new Error(`*** REDUX -> logoutUser *** ${error}`);
  }
};

export const validateMerchantAccountRedux = (body) => async (dispatch) => {
  try {
    const { merchantId, consumerKey, consumerSecret } = body;
    const base64 = window.btoa(unescape(encodeURIComponent(`${consumerKey}:${consumerSecret}`)));
    const URL = `${API.baseUrl}/merchant/${merchantId}`;
    await axios.get(URL, {
      headers: {
        Authorization: `Basic ${base64}`
      }
    });
    return true;

  } catch (error) {
    throw new Error(`*** REDUX -> validateMerchantAccount *** ${error}`);
  }
};

export const createMerchantAccountRedux = (body) => async (dispatch) => {

  const { name, merchantId, consumerKey, consumerSecret, defaultAccount } = body;
  try {
    const base64 = window.btoa(unescape(encodeURIComponent(`${consumerKey}:${consumerSecret}`)));
    const data = {
      id: guidGenerator(),
      name,
      merchantId,
      base64,
      defaultAccount,
    };
    await window.localStorage.setItem('@MerchantAccount', JSON.stringify([data]));

    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> createMerchantAccountRedux *** ${error}`);
  }

};
export const getListMerchantAccountsRedux = () => async (dispatch) => {

  try {
    const merchantAccounts = await window.localStorage.getItem(
      '@MerchantAccount'
    );
    if (merchantAccounts) {
      return JSON.parse(merchantAccounts);
    }
    return [];
  } catch (error) {
    throw new Error(`*** REDUX -> getListMerchantAccountsRedux *** ${error}`);
  }

};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    user: {},
    enabledIntegration: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;

function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}
