import { createSlice } from '@reduxjs/toolkit';

import firebase from "../firebase";

export const createUISchema = (data) => async (dispatch) => {
    const db = firebase.firestore();
    try {
        await db.collection('UISchema').add(data)
        return true
    } catch (error) {
        throw new Error(`*** REDUX -> createCompany *** ${error}`);
    }
};

export const UpdateUISchema = (id, data) => async (dispatch) => {
    const db = firebase.firestore();
    try {
        await db.collection('UISchema').doc(id).update(data)
        return true
    } catch (error) {
        throw new Error(`*** REDUX -> createCompany *** ${error}`);
    }
};

export const deleteUISchema = (id) => async (dispatch) => {
    const db = firebase.firestore();
    try {
        await db.collection('UISchema').doc(id).delete()
        return true
    } catch (error) {
        throw new Error(`*** REDUX -> createCompany *** ${error}`);
    }
};

export const getAllUISchema = () => async (dispatch) => {
    const db = firebase.firestore();
    try {
        const refUISchema = await db.collection('UISchema').get()
        const dataUISchema = refUISchema.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        await dispatch(setDataUISchema(dataUISchema))
    } catch (error) {
        throw new Error(`*** REDUX -> createCompany *** ${error}`);
    }
};

const uiSchemaSlice = createSlice({
    name: 'uiSchema',
    initialState: {
        loading: false,
        enabledIntegration: false,
        dataUISchema: [],
    },
    reducers: {
        setDataUISchema: (state, action) => {
            state.dataUISchema = action.payload;
        },
    },
});

export const { setDataUISchema } = uiSchemaSlice.actions;

export default uiSchemaSlice.reducer;
