import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CircularProgress,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    TableContainer,
    TableSortLabel,
    Paper,
    Table,
    Box,
    IconButton
} from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { makeStyles, withStyles, createStyles } from '@mui/styles';
import { visuallyHidden } from "@mui/utils";

import { changeDefaultUbication, deleteLocatioSetting } from 'app/firebase/services/locationsSettingsSlice';
import { getContactById } from 'app/firebase/services/contactSlice';
import { getAllUISchema } from 'app/firebase/services/uiSchemaSlice';

import AddLocationSetting from './AddLocationSetting';
import EditLocationSetting from './EditLocationSetting';
import CustomButton from 'app/components/CustomButton';
import CustomPhoneNumberInput from 'app/components/CustomPhoneNumberInput';
import CustomRadioCheck from 'app/components/CustomRadioCheck';
import CustomDialog from 'app/components/CustomDialog';

const StyledTableCell = withStyles(() =>
    createStyles({
        head: {
            color: 'black',
            border: 'none',
            padding: '10 16px',
        },
        body: {
            fontSize: 14,
            padding: '10px 16px',
            border: 'none',
            height: 75,
            color: '#535F68',
            fontWeight: 500
        },
    }),
)(TableCell);

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: 'white',
            },
            backgroundColor: '#FBFBFE',
        },
    }),
)(TableRow);

const useStyles = makeStyles(() => ({
    tableCustom: {
        borderRadius: 6,
        marginTop: 27
    },
    headTableCus: {
        background: '#F1F3FB',
        height: 50
    },
    tablecellCust: {
        color: '#535F68',
        fontSize: 16,
        fontWeight: 'bold',
        width: '20%'
    }
}))

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "name",
        oder: true,
        label: "Location name",
        width: '20%'
    },
    {
        id: "address",
        oder: false,
        label: "Address",
        width: '25%'
    },
    {
        id: "phone",
        oder: true,
        label: "Phone",
        width: '20%'
    },
    {
        id: "Main ubication",
        oder: false,
        label: "Main Location",
        width: '20%'
    },
    {
        id: "details",
        oder: false,
        label: "Details",
        width: '10%'
    },
    {
        id: "delete",
        oder: false,
        label: "",
        width: '10%'
    },
];

function EnhancedTableHead(props) {
    const classes = useStyles();
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };


    return (
        <TableHead className={classes.headTableCus}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        className={classes.tablecellCust}
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ width: headCell.width }}
                    >
                        {headCell.oder ?
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                            :
                            headCell.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function LocationSettings(props) {
    const { itemContact, companyId, contactId, dataLocation, loadingData } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const [locationIdDel, setLocationIdDel] = useState(false);
    const [dialogDelete, setDialogDelete] = useState(false);
    const [loadingDel, setLoadingDel] = useState(false);
    const [dialogAddLocationSetting, setDialogAddLocationSetting] = useState(false);
    const [editLocationSetting, setEditLocationSetting] = useState(false);
    const [dataLocationSetting, setDataLocationSetting] = useState('');
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [isDefault, setIsDefault] = useState('');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const dataSchema = useSelector(({ uiSchema }) => uiSchema.dataUISchema)

    useEffect(() => {
        const fetch = async () => {
            await dispatch(getAllUISchema())
        }
        fetch()
    }, [])

    const changeDefault = (defaultId) => async () => {
        setLoading(true);
        setIsDefault(defaultId)
        await dispatch(changeDefaultUbication(companyId, contactId, defaultId))
        await dispatch(getContactById(companyId, contactId))
        setLoading(false);
    }

    const sendDeleteLocatioSetting = async () => {
        setLoadingDel(true)
        await dispatch(deleteLocatioSetting(companyId, contactId, locationIdDel))
        await dispatch(getContactById(companyId, contactId))
        setLoadingDel(false)
        setDialogDelete(false)
    }

    return (
        <div className='w-full flex flex-col py-20 min-h-128'>
            <div className='flex justify-between items-end'>
                <p className='text-primaryText text-15 font-600'>
                    Contact Location Settings
                </p>
                <CustomButton
                    label='Add a Location Setting'
                    onClick={() => {
                        setDialogAddLocationSetting(true);
                    }}
                />
                <AddLocationSetting
                    open={dialogAddLocationSetting}
                    onClose={() => {
                        setDialogAddLocationSetting(false);
                    }}
                    companyId={companyId}
                    locations={dataLocation}
                    contactId={contactId}
                    dataSchema={dataSchema}
                    itemContact={itemContact}
                />
            </div>
            {loadingData ?
                <div className='w-full flex justify-center mt-12'>
                    <CircularProgress size={30} style={{ color: '#0594FB' }} />
                </div>
                :
                <TableContainer component={Paper} className={classes.tableCustom}>
                    <Table sx={{ minWidth: 700 }} >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={itemContact.locationSetting.length}
                        />
                        <TableBody>
                            {itemContact.locationSetting && itemContact.locationSetting.length > 0 ? (

                                stableSort(itemContact.locationSetting, getComparator(order, orderBy))
                                    .map((e) => {

                                        const defaultUbication = itemContact.contact && itemContact.contact.defaultUbication ?
                                            itemContact.contact.defaultUbication === e.id : false

                                        return (
                                            <StyledTableRow key={e.id}>
                                                <StyledTableCell align="left" style={{ width: '20%' }}>
                                                    {e.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{ width: '25%' }}>
                                                    {e.location && e.location.address && e.location.address.street1}
                                                    {e.location && e.location.address && e.location.address.city}&nbsp;
                                                    {e.location && e.location.address && e.location.address.zip}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ width: '20%' }} align="left">
                                                    <CustomPhoneNumberInput
                                                        country="US"
                                                        value={itemContact.contact.phone ? itemContact.contact.phone : ''}
                                                        maxLength={14}
                                                        disabled={true}
                                                        onChange={(e) => e.target.value}
                                                        style={{
                                                            border: '1px solid transparent',
                                                            fontSize: 14,
                                                            color: '#535F68',
                                                            background: 'transparent'
                                                        }}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell style={{ width: '20%' }} align="left">
                                                    {loading && isDefault === e.id ?
                                                        <div className='mt-12'>
                                                            <CircularProgress size={30} style={{ color: '#0594FB' }} />
                                                        </div>
                                                        :
                                                        <div className='flex items-center'>
                                                            <CustomRadioCheck
                                                                disabled={loading}
                                                                checked={defaultUbication}
                                                                onChange={changeDefault(e.id)}
                                                            />
                                                            {defaultUbication ?
                                                                <p className='font-500 text-primaryText'>
                                                                    Main Location
                                                                </p>
                                                                :
                                                                <p className='font-500 text-textLight'>
                                                                    Mark as Main
                                                                </p>

                                                            }
                                                        </div>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    style={{
                                                        width: '10%',
                                                        color: '#0594FB',
                                                        cursor: 'pointer'
                                                    }}
                                                    align="left"
                                                    onClick={() => {
                                                        setEditLocationSetting(true);
                                                        setDataLocationSetting(e)
                                                    }}
                                                >
                                                    View
                                                </StyledTableCell>
                                                <StyledTableCell align="center" style={{ with: '10%' }}>
                                                    {defaultUbication ? '' :
                                                        <IconButton
                                                            onClick={() => {
                                                                setLocationIdDel(e.id)
                                                                setDialogDelete(true)
                                                            }}
                                                        >
                                                            <DeleteRoundedIcon style={{ color: '#99A0AB' }} />
                                                        </IconButton>
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })) :
                                <StyledTableRow>
                                    <StyledTableCell
                                        align="left"
                                        style={{ padding: 16, fontSize: 18 }}
                                    >
                                        No data found
                                    </StyledTableCell>
                                </StyledTableRow>
                            }
                            <CustomDialog
                                open={dialogDelete}
                                title='Are you sure you want to eliminate the location setting?'
                                actions={
                                    loadingDel ?
                                        <CircularProgress size={30} style={{ color: '#0594FB' }} />
                                        :
                                        <div className='w-full flex justify-center items-end mt-10'>
                                            <p
                                                className='underline text-textLight mr-10 cursor-pointer'
                                                onClick={() => {
                                                    setDialogDelete(false)
                                                }}
                                            >
                                                Cancel
                                            </p>
                                            <CustomButton
                                                label='yes'
                                                onClick={sendDeleteLocatioSetting}
                                            />
                                        </div>
                                }
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            <EditLocationSetting
                open={editLocationSetting}
                companyId={companyId}
                dataSchema={dataSchema}
                contactId={contactId}
                dataLocation={dataLocationSetting}
                locations={dataLocation}
                onClose={() => {
                    setEditLocationSetting(false);
                }}
            />
        </div>
    );
}

export default LocationSettings;
