import { createSlice } from '@reduxjs/toolkit';

import firebase from '../firebase';
import { isEmpty } from 'ramda';

const getContacts = async (id) => {
  const db = firebase.firestore();
  const dataCompany = await db
    .collection('Companies')
    .doc(id)
    .collection('Contacts')
    .limit(4)
    .get();
  const arrayDataContacts = dataCompany.docs.map((doc) => ({
    ...doc.data(),
    User: doc.id
  }));
  return arrayDataContacts;
};

export const getLocatiosSettings = async (companyId, ContactId) => {
  const db = firebase.firestore();
  try {
    const dataContacts = await db
      .collection('Companies')
      .doc(companyId)
      .collection('Contacts')
      .doc(ContactId)
      .collection('LocationSettings')
      .get();
    const locationsSettings = dataContacts.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      location: doc.cocation ? doc.location.id : '',
      uiSchema: doc.uiSchema ? doc.uiSchema.id : ''
    }));
    return locationsSettings;
  } catch (error) {
    throw new Error(`*** REDUX -> getLocatiosSettings *** ${error}`);
  }
};

export const getCompanyById = (companyId) => async (dispatch) => {
  const db = firebase.firestore();
  try {
    const companieId = await db.collection('Companies').doc(companyId).get();
    const dataContacts = await db
      .collection('Companies')
      .doc(companyId)
      .collection('Contacts')
      .get();
    const dataLocations = await db
      .collection('Companies')
      .doc(companyId)
      .collection('Locations')
      .get();

    const getAllRelations = async (tt) => {
      const arrayData = tt.docs.map(async (doc) => {
        const promise2 = await getLocatiosSettings(companyId, doc.id);
        return { ...doc.data(), id: doc.id, locationsSettings: promise2 };
      });
      return Promise.all(arrayData);
    };
    const newData = await getAllRelations(dataContacts);

    const companie = companieId.data();
    const location = dataLocations.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
    const body = {
      companie,
      location,
      contact: newData
    };
    await dispatch(setCompanyById(body));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getCompanyById *** ${error}`);
  }
};

export const getAllCompanies = () => async (dispatch) => {
  const db = firebase.firestore();
  try {
    const dataCompany = await db.collection('Companies').get();
    const getAllRelations = async (tt) => {
      const arrayData = tt.docs.map(async (doc) => {
        const promise2 = await getContacts(doc.id);
        return { ...doc.data(), id: doc.id, contacts: promise2 };
      });
      return Promise.all(arrayData);
    };
    const newData = await getAllRelations(dataCompany);
    await dispatch(setDataCompanies(newData));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getAllCompanies *** ${error}`);
  }
};

/**
 * @author Randall Medina
 * @description Function for filtering of company data by matches
 * @param search
 */
export const getSearchCompanies = (search) => async (dispatch) => {
  const db = firebase.firestore();
  try {
    const collectionRef = await db.collection('Companies').get();
    const getAllRelations = async (tt) => {
      const arrayData = tt.docs.map(async (doc) => {
        const promise2 = await getContacts(doc.id);
        return { ...doc.data(), id: doc.id, contacts: promise2 };
      });
      return Promise.all(arrayData);
    };
    // ---------------------------------
    let filteredDocs = [];
    const newData = await getAllRelations(collectionRef);
    newData.map(async (doc) => {
      const fieldName = doc.name.toLowerCase();
      const fieldUrl = doc.url.toLowerCase();
      const fieldPhone = doc.phone;
      const fieldContacts = doc.contacts;
      if (
        (!isEmpty(fieldName) && fieldName.includes(search.toLowerCase())) ||
        (!isEmpty(fieldUrl) && fieldUrl.includes(search.toLowerCase())) ||
        (!isEmpty(fieldPhone) && fieldPhone.includes(search.toLowerCase())) ||
        (fieldContacts.length > 0 &&
          fieldContacts.filter((v) =>
            v.name.toLowerCase().includes(search.toLowerCase())
          ).length > 0)
      ) {
        filteredDocs = filteredDocs.concat(doc);
      }
    });
    // ---------------------------------
    await dispatch(setDataCompanies(filteredDocs));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getSearchCompanies *** ${error}`);
  }
};

export const createCompany = (data) => async (dispatch) => {
  const db = firebase.firestore();
  try {
    const newCompany = {
      name: data.name,
      url: data.url,
      phone: data.phone
    };
    const resultCompany = await db.collection('Companies').add(newCompany);
    if (resultCompany && resultCompany.id) {
      const newCompanyId = resultCompany.id;
      const newLocation = {
        name: data.locationName,
        phone: data.locationPhone,
        address: {
          city: data.city,
          state: data.state,
          street: data.street,
          zip: data.zip
        }
      };
      const resultLocation = await db
        .collection('Companies')
        .doc(newCompanyId)
        .collection('Locations')
        .add(newLocation);
      if (resultLocation && resultLocation.id) {
        const newLocationyId = resultLocation.id;
        await db.collection('Companies').doc(newCompanyId).update({
          defaultMainLocation: newLocationyId,
        })
      }
    }
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> createCompany *** ${error}`);
  }
};

export const updateCompany = (id, data) => async (dispatch) => {
  const db = firebase.firestore();
  try {
    const { city, street1, street2, state, zip, ...newData } = data;
    const dataCompania = {
      ...newData
    };
    await db.collection('Companies').doc(id).update(dataCompania);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> updateCompany *** ${error}`);
  }
};

export const deleteCompany = (id) => async (dispatch) => {
  const db = firebase.firestore();
  try {
    await db.collection('Companies').doc(id).delete();
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> updateCompany *** ${error}`);
  }
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    loading: false,
    dataCompanies: [],
    enabledIntegration: false,
    companyById: {}
  },
  reducers: {
    setDataCompanies: (state, action) => {
      state.dataCompanies = action.payload;
    },
    setCompanyById: (state, action) => {
      state.companyById = action.payload;
    }
  }
});

export const { setDataCompanies, setCompanyById } = companiesSlice.actions;

export default companiesSlice.reducer;
