import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    CircularProgress,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    TableContainer,
    TableSortLabel,
    Paper,
    Table,
    Switch,
    Box
} from '@mui/material';
import { makeStyles, withStyles, createStyles } from '@mui/styles';
import { visuallyHidden } from "@mui/utils";

import { updateContact } from 'app/firebase/services/contactSlice';
import { getCompanyById } from 'app/firebase/services/companiesSlice';

import CustomPhoneNumberInput from 'app/components/CustomPhoneNumberInput';
import CustomButton from 'app/components/CustomButton';

import AddContact from './AddContact';

const StyledTableCell = withStyles(() =>
    createStyles({
        head: {
            color: 'black',
            border: 'none',
            padding: '10 16px',
        },
        body: {
            fontSize: 14,
            padding: '10px 16px',
            border: 'none',
            height: 75,
            color: '#535F68',
            fontWeight: 500
        },
    }),
)(TableCell);

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: 'white',
            },
            backgroundColor: '#FBFBFE',
        },
    }),
)(TableRow);

const useStyles = makeStyles(() => ({
    tableCustom: {
        borderRadius: 6,
        marginTop: 27
    },
    headTableCus: {
        background: '#F1F3FB',
        height: 50
    },
    tablecellCust: {
        color: '#535F68',
        fontSize: 16,
        fontWeight: 'bold',
        width: '16%'
    },
}))

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "name",
        oder: true,
        label: "Name"
    },
    {
        id: "email",
        oder: true,
        label: "Email"
    },
    {
        id: "phone",
        oder: true,
        label: "Phone"
    },
    {
        id: "title",
        oder: true,
        label: "Title"
    },
    {
        id: "status",
        oder: false,
        label: "Status"
    },
    {
        id: "details",
        oder: false,
        label: "Details"
    },
];

function EnhancedTableHead(props) {
    const classes = useStyles();
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.headTableCus}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className={classes.tablecellCust}
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.oder ?
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                            :
                            headCell.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function Contacts(props) {
    const { dataCompany, companyId, loadingData } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [dialogNewContact, setDialogNewContact] = useState(false);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChange = (item) => async (event) => {
        setLoading(true)
        const contactId = item.id
        if (item.status === 'active') {
            const body = {
                ...item,
                status: 'locked',
            }
            await dispatch(updateContact(
                companyId,
                contactId,
                body
            ))
            await dispatch(getCompanyById(companyId))
        } else {
            const body = {
                ...item,
                status: 'active',
            }
            await dispatch(updateContact(
                companyId,
                contactId,
                body
            ))
            await dispatch(getCompanyById(companyId))

        }
        setLoading(false)
    };

    return (
        <div className='w-full flex flex-col py-20 min-h-128'>
            <div className='flex justify-between items-end'>
                <p className='text-primaryText text-15 font-600'>
                    Contacts
                </p>
                <CustomButton
                    label='Add a Contact'
                    onClick={() => {
                        setDialogNewContact(true);
                    }}
                />
                <AddContact
                    open={dialogNewContact}
                    onClose={() => {
                        setDialogNewContact(false);
                    }}
                    companyId={companyId}
                />
            </div>
            {loadingData ?
                <div className='w-full flex justify-center mt-12'>
                    <CircularProgress size={30} style={{ color: '#0594FB' }} />
                </div>
                :
                <TableContainer component={Paper} className={classes.tableCustom}>
                    <Table sx={{ minWidth: 700 }} >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={dataCompany.contact.length}
                        />
                        <TableBody>
                            {dataCompany.contact && dataCompany.contact.length > 0 ? (

                                stableSort(dataCompany.contact, getComparator(order, orderBy))
                                    .map((e) => {
                                        const disabledSwitch = e.locationsSettings && e.locationsSettings.length === 0

                                        return (
                                            <StyledTableRow key={e.id}>
                                                <StyledTableCell align="left" style={{ width: '16%' }}>
                                                    <div className='bg-light w-max rounded-4 p-4 text-secondaryText'>
                                                        {e.name}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{ width: '16%', color: '#5A99E4' }}>
                                                    {e.email}
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{ width: '16%' }}>
                                                    <CustomPhoneNumberInput
                                                        country="US"
                                                        value={e.phone ? e.phone : ''}
                                                        maxLength={14}
                                                        disabled={true}
                                                        style={{
                                                            border: '1px solid transparent',
                                                            fontSize: 14,
                                                            color: '#535F68',
                                                            background: 'transparent',
                                                            padding: 0
                                                        }}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{ width: '16%' }}>
                                                    {e.title}
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{ width: '16%' }}>
                                                    {loading ?
                                                        <div className='w-full flex justify-center mt-12'>
                                                            <CircularProgress size={30} style={{ color: '#0594FB' }} />
                                                        </div>
                                                        :
                                                        <div className='flex items-center'>
                                                            <p style={{ marginRight: 10, textTransform: 'capitalize' }}>
                                                                {e.status}
                                                            </p>
                                                            <div style={{ justifyContent: 'center' }}>
                                                                <Switch
                                                                    checked={e.status === 'active' ? true : false}
                                                                    onChange={handleChange(e)}
                                                                    name={e.id}
                                                                    disabled={disabledSwitch}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="left"
                                                    style={{ width: '16%', color: '#0594FB', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        history.push(`/contact/${e.id}`, {
                                                            companyId: companyId,
                                                            contactId: e.id
                                                        })
                                                    }}
                                                >
                                                    View contact
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })) :
                                <StyledTableRow>
                                    <StyledTableCell
                                        align="left"
                                        style={{ padding: 16, fontSize: 18 }}
                                    >
                                        No data
                                    </StyledTableCell>
                                </StyledTableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

            }
        </div>
    );
}

export default Contacts;