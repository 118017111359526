import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Grid, Snackbar, IconButton, CircularProgress, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CustomTextField from '../../CustomTextField';
import CustomButton from '../../CustomButton';

import firebase from 'app/firebase/firebase';
import Navbar from 'app/components/Navbar';


const useStyles = makeStyles({
    typography: {
        textAlign: "left",
        fontSize: 12,
        marginBottom: 8,
        marginTop: 16,
        fontWeight: 500,
        color: '#99A0AB'
    },
    custNackbar: {
        '& .MuiSnackbarContent-root': {
            background: 'rgba(157, 205, 42, 0.13)',
            color: '#7DA124',
            borderRadius: 13
        }
    }
});


function ForgotPassword() {
    const classes = useStyles();
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [emailValidate, setEmailValidate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorField, setErrorField] = useState(false);

    const handleClose = (event, reason) => {
        setOpenSnackbar(false);
        history.push("/");
    };

    const onChangeEmail = (event) => {
        const caracteres = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const t = event.currentTarget.value.split(" ");
        let text = "";
        t.map((r) => {
            if (r !== "") {
                text += r;
            }
        });
        setEmail(text.toLowerCase());
        if (text !== "") {
            if (caracteres.test(text)) {
                setEmailValidate(false);
            } else {
                setEmailValidate(true);
            }
        } else {
            setEmailValidate(true);
        }
    };

    const sendEmail = () => {
        setLoading(true);
        if (email === '') {
            setErrorField(true)
            setLoading(false);
        } else {
            firebase
                .auth()
                .sendPasswordResetEmail(email)
                .then(result => {
                    setOpenSnackbar(true)
                    setLoading(false);
                })
                .catch(err => {
                    // dispatch(showMessage({ message: err.message, variant: 'error' }))
                    setLoading(false);
                });
        }
    }


    return (
        <Container sx={{ mt: 10, mb: 4 }}>
            <Navbar />
            <Grid
                container
                style={{ marginTop: 30 }}
                justifyContent='center'
            >
                <Grid
                    item
                    xs={11} sm={7} md={5}
                    style={{ marginTop: 15 }}
                >
                    <p style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 20, fontFamily: 'SF Pro Display' }}>
                        Forgot Password?
                    </p>
                    <p
                        className={classes.typography}
                        style={{ fontSize: 16, color: '#535F68', }}
                    >
                        Enter the email address you used when you joined and we’ll send you
                        instructions to reset your password. <br /><br />
                        For security reasons, we do NOT store your password. So rest assured
                        that we will never send your password via email. <br /><br />
                    </p>
                    <p className={classes.typography}>
                        Email
                    </p>
                    <CustomTextField
                        name='email'
                        value={email}
                        type="text"
                        placeholder="your@email.com"
                        error={email !== '' ? emailValidate : errorField}
                        helperText={email && emailValidate ? "Please enter a valid email" : ''}
                        onChange={onChangeEmail}
                    />
                    <div style={{ marginTop: 30 }}>
                        {loading === true ?
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                            </div>
                            :
                            <CustomButton
                                label='Send'
                                onClick={sendEmail}
                            />
                        }
                    </div>
                    <Snackbar
                        open={openSnackbar}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        autoHideDuration={3000}
                        className={classes.custNackbar}
                        message="You can now check the email to create a new password."
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                            >
                                <CloseRoundedIcon />
                            </IconButton>
                        }
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default ForgotPassword;