import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Card, CircularProgress, Switch, Dialog, Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { makeStyles } from '@mui/styles';

import { addLocatioSetting } from 'app/firebase/services/locationsSettingsSlice';
import { getContactById } from 'app/firebase/services/contactSlice';

import CustomTextField from 'app/components/CustomTextField';
import CustomButton from 'app/components/CustomButton';
import CustomSelect from 'app/components/CustomSelect';

const useStyles = makeStyles({
  dialog: {
    '& .MuiPaper-root.MuiDialog-paper': {
      borderRadius: 20
    }
  },
  cardContainer: {
    margin: 'auto',
    minWidth: 500,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 0
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#DFE2E2',
      borderRadius: 4
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#888',
      borderRadius: 4
    },
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  textField: {
    color: '#99A0AB',
    fontSize: 12,
    marginTop: 12,
  },
  tabCustom: {
    '& .tabMenu' : {
      color: '#455866', 
      borderRight: '1px solid #455866',
      borderWidth: 'medium',
      padding: '0 16px',
      minHeight: 33,
    },
    '& .tabMenu.Mui-selected' : {
      color: '#455866', 
      fontWeight: '600',
      // padding: 0
    },
    '& .MuiTabs-root' : {
      minHeight: 0,
    },
    '& span.MuiTabs-indicator': { display: 'none' } 
  }
});

function AddLocationSetting(props) {
  const {
    onClose,
    locations,
    companyId,
    contactId,
    dataSchema,
    itemContact,
    open
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const locatSetNotEmpty =
    itemContact &&
    itemContact.locationSetting &&
    itemContact.locationSetting.length > 0; /* locations settings not empty */

  const emailUser =
    itemContact && itemContact.contact && itemContact.contact.email;

  const [value, setValue] = useState('1');
  const [errorField, setErrorField] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    consumerKey: '',
    consumerSecret: '',
    location: '',
    merchantId: '',
    name: '',
    uiSchema: '',
    usingIntegration: false,
    usingIntegrationLegacy: false,
    firstLocation: true,
    email: '',
    database: '',
    databaseName: '',
    chartMandatory: false,
    nonCashAdjustment: false,
    nonCashAdjustmentValue: '',
    reportFormat: 'standard',
    closeBatch: false,

    // arManagement: false,
    // consumerKeyAuthorizeNet: '',
    // consumerSecretAuthorizeNet: '',
    // consumerKeyFeatherPay: '',
    // consumerSecretFeatherPay: '',
  });

  useEffect(() => {
    if (emailUser) {
      setForm({
        ...form,
        email: emailUser,
        firstLocation: locatSetNotEmpty ? false : true
      });
    }
  }, [emailUser, itemContact]);

  const onChangeText = (prop) => (event) => {
    setForm({ ...form, [prop]: event.target.value });
    if (prop === 'uiSchema') {
      getDataType(event.target.value);
      setForm({
        ...form,
        databaseName: '',
        database: '',
        [prop]: event.target.value
      });
    } else if (prop === 'database' && event.target.value === 'global') {
      setForm({ ...form, databaseName: '', [prop]: event.target.value });
    }
  };

  const onChangeChartMandatory = (e) => {
    setForm({ ...form, chartMandatory: e.target.checked });
  };

  const enableNonCashAdjustment = (a) => {
    setForm({ ...form, nonCashAdjustment: a.target.checked });
  };
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const onChangeCloseBatch = (e) => {
    setForm({ ...form, closeBatch: e.target.checked });
  };
  // const onChangeAR = (e) => {
  //   setForm({
  //     ...form,
  //     arManagement: e.target.checked,
  //     consumerKeyAuthorizeNet: '',
  //     consumerSecretAuthorizeNet: '',
  //     consumerKeyFeatherPay: '',
  //     consumerSecretFeatherPay: '',
  //   });
  // };

  const onChangeIntegration = (e) => {
    if (e.target.checked === false) {
      setForm({
        ...form,
        usingIntegration: e.target.checked,
        uiSchema: '',
        database: '',
        databaseName: ''
      });
    } else {
      setForm({
        ...form,
        usingIntegration: e.target.checked,
        usingIntegrationLegacy: false,
        database: '',
        databaseName: ''
      });
    }
  };

  const onChangeIntegrationLegacy = (e) => {
    if (e.target.checked === false) {
      setForm({
        ...form,
        usingIntegrationLegacy: e.target.checked,
        uiSchema: '',
        database: '',
        databaseName: ''
      });
    } else {
      setForm({
        ...form,
        usingIntegrationLegacy: e.target.checked,
        usingIntegration: false,
        uiSchema: '',
        database: '',
        databaseName: ''
      });
    }
  };

  const sendNewLocationS = async () => {
    setLoading(true);
    if (
      form.consumerKey === '' || form.consumerSecret === '' ||
      form.location === '' || form.merchantId === '' ||
      form.name === '' || form.reportFormat === '' ||
      (form.nonCashAdjustment === true && form.nonCashAdjustmentValue === '') ||
      (form.usingIntegration === true && form.uiSchema === '') ||
      (form.usingIntegration === true && form.uiSchema === undefined) ||
      (showInputs && form.database === '') ||
      (showInputs && form.database === 'local' && form.databaseName === '')
      // (form.arManagement === true &&
      //   (form.consumerKeyAuthorizeNet === '' || form.consumerSecretAuthorizeNet === '' ||
      //     form.consumerKeyFeatherPay === '' || form.consumerSecretFeatherPay === '')
      // )
    ) {
      setErrorField(true);
      setLoading(false);
      setValue('1');
    } else {
      await dispatch(addLocatioSetting(companyId, contactId, form));
      await dispatch(getContactById(companyId, contactId));
      setLoading(false);
      clearData();
    }
  };

  const getDataType = (param) =>
    dataSchema &&
    dataSchema.length > 0 &&
    dataSchema.filter((e) => {
      if (e.id === param) {
        if (e.dataFromWindowTitle) {
          setShowInputs(true);
        } else {
          setShowInputs(false);
          setForm({ ...form, database: '', databaseName: '' });
        }
      }
    });

  const locationsOptions =
    locations && locations.length > 0
      ? locations.map((e) => ({
        label: `${e.name} ${e.address.street}, ${e.address.city} ${e.address.zip}`,
        value: e.id
      }))
      : { label: '', value: '' };

  const uiSchemaOptions =
    dataSchema && dataSchema.length > 0
      ? dataSchema.map((e) => ({ label: e.name, value: e.id }))
      : { label: '', value: '' };

  const databaseOptions = [
    { label: 'Global', value: 'global' },
    { label: 'Local setting', value: 'local' }
  ];

  const reportOptions = [
    { label: 'Standard Report', value: 'standard' },
    { label: 'Report with Service Fees', value: 'serviceFees' }
  ];

  const clearData = () => {
    setValue('1');
    setErrorField(false);
    setForm({
      consumerKey: '',
      consumerSecret: '',
      location: '',
      merchantId: '',
      name: '',
      uiSchema: '',
      usingIntegration: false,
      usingIntegrationLegacy: false,
      firstLocation: true,
      email: '',
      database: '',
      databaseName: '',
      chartMandatory: false,
      nonCashAdjustment: false,
      nonCashAdjustmentValue: '',
      reportFormat: 'standard',
      closeBatch: false,
      
      // arManagement: false,
      // consumerKeyAuthorizeNet: '',
      // consumerSecretAuthorizeNet: '',
      // consumerKeyFeatherPay: '',
      // consumerSecretFeatherPay: '',
    })
    onClose();
  }

  return (
    <Dialog
      style={{
        justifyContent: 'center',
        justifyItems: 'center',
        display: 'flex'
      }}
      className={classes.dialog}
      open={open}
    >
      <Card className={classes.cardContainer}>
        <div
          className="flex justify-center items-center py-12"
          style={{ background: '#FAFAFD' }}
        >
          <p className="text-primaryText text-13 font-600">
            Add Contact location Setting
          </p>
        </div>
        <Box sx={{ width: '100%', typography: 'body1' }} className={classes.tabCustom}>
          <TabContext value={value}>
            <Box className="px-16 mt-3">
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Location" value="1" className="tabMenu"/>
                <Tab label="Integration" value="2" className="tabMenu" />
                <Tab label="Reports" value="3" className="tabMenu" />
              </TabList>
            </Box>
            <TabPanel value="1" style={{ padding: 0 }}>
              <div style={{ marginBottom: 38 }} className="px-16">
                <CustomSelect
                  label="Location"
                  value={form.location}
                  onChange={onChangeText('location')}
                  name="consumerKey"
                  error={form.location === '' ? errorField : null}
                  options={locationsOptions}
                />
                <CustomTextField
                  textTopField="Location name"
                  value={form.name}
                  onChange={onChangeText('name')}
                  name="name"
                  error={form.name === '' ? errorField : null}
                />
                <CustomTextField
                  textTopField="Merchant ID"
                  value={form.merchantId}
                  onChange={onChangeText('merchantId')}
                  name="merchantId"
                  error={form.merchantId === '' ? errorField : null}
                />
                <CustomTextField
                  textTopField="Consumer Key"
                  value={form.consumerKey}
                  onChange={onChangeText('consumerKey')}
                  name="consumerKey"
                  error={form.consumerKey === '' ? errorField : null}
                />
                <CustomTextField
                  textTopField="Consumer Secret"
                  value={form.consumerSecret}
                  onChange={onChangeText('consumerSecret')}
                  name="consumerSecret"
                  error={form.consumerSecret === '' ? errorField : null}
                />
              </div>
            </TabPanel>
            <TabPanel value="2" style={{ padding: 0 }}>
              <div style={{ marginBottom: 38 }} className="px-16">
                <div className="flex justify-between">
                  <div>
                    <p className="text-primaryText font-500 mt-10" style={{ fontSize: 15 }}>
                      Universal Integration
                    </p>
                    <div className="flex items-center">
                      <p style={{ fontSize: 15 }}>No</p>
                      <Switch
                        checked={form.usingIntegration}
                        className={classes.switchCustom}
                        onChange={onChangeIntegration}
                      />
                      <p style={{ fontSize: 15 }}>Yes</p>
                    </div>
                  </div>
                  <div>
                    <p className="text-primaryText font-500 mt-10" style={{ fontSize: 15 }}>
                      Legacy Integration
                    </p>
                    <div className="flex items-center">
                      <p style={{ fontSize: 15 }}>No</p>
                      <Switch
                        checked={form.usingIntegrationLegacy}
                        className={classes.switchCustom}
                        onChange={onChangeIntegrationLegacy}
                      />
                      <p style={{ fontSize: 15 }}>Yes</p>
                    </div>
                  </div>
                </div>
                <CustomSelect
                  label="UI Schema"
                  options={uiSchemaOptions}
                  value={form.uiSchema}
                  disabled={form.usingIntegration === false ? true : false}
                  onChange={onChangeText('uiSchema')}
                  name="uiSchema"
                  error={
                    form.usingIntegration === true && form.uiSchema === ''
                      ? errorField
                      : null
                  }
                />
                {showInputs && form.uiSchema !== '' && (
                  <>
                    <CustomSelect
                      label="Database"
                      options={databaseOptions}
                      value={form.database}
                      disabled={form.usingIntegration === false ? true : false}
                      onChange={onChangeText('database')}
                      name="database"
                      error={
                        form.usingIntegration === true && form.database === ''
                          ? errorField
                          : null
                      }
                    />
                    <CustomTextField
                      textTopField="Database Name"
                      value={form.databaseName}
                      disabled={form.database === 'global' || form.database === ''}
                      onChange={onChangeText('databaseName')}
                      name="databaseName"
                      error={form.databaseName === '' ? errorField : null}
                    />
                  </>
                )}
                <div className='flex flex-row justify-between pt-14'>
                  <div>
                    <p className="text-primaryText font-500" style={{ fontSize: 15 }}>
                      Chart number mandatory
                    </p>
                    <div className="flex items-center">
                      <p style={{ fontSize: 15 }}>No</p>
                      <Switch
                        checked={form.chartMandatory}
                        className={classes.switchCustom}
                        onChange={onChangeChartMandatory}
                      />
                      <p style={{ fontSize: 15 }}>Yes</p>
                    </div>
                  </div>
                  <div className='flex'>
                    <div className='flex flex-col'>
                      <p
                        className="text-primaryText font-500"
                        style={{ display: 'inline', marginRight: 30, fontSize: 15 }}
                      >
                        Enable NCA
                      </p>
                      <div className='flex items-center'>
                        <p style={{ fontSize: 15 }}>No</p>
                        <Switch
                          checked={form.nonCashAdjustment}
                          className={classes.switchCustom}
                          onChange={enableNonCashAdjustment}
                        />
                        <p style={{ display: 'inline', marginRight: 15, fontSize: 15 }}>Yes</p>
                      </div>
                    </div>
                    <div className='flex flex-col'>
                      <p
                        className="text-primaryText font-500"
                        style={{ display: 'inline', fontSize: 15 }}
                      >
                        NCA %
                      </p>
                      <CustomTextField
                        style={{ width: 60 }}
                        value={form.nonCashAdjustmentValue}
                        onChange={onChangeText('nonCashAdjustmentValue')}
                        name="nonCashAdjustmentValue"
                        disabled={form.nonCashAdjustment === false ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value="3" style={{ padding: 0 }}>
              <div style={{ marginBottom: 38 }} className="px-16">
                <CustomSelect
                  label="Report Format"
                  options={reportOptions}
                  value={form.reportFormat}
                  onChange={onChangeText('reportFormat')}
                  name="reportFormat"
                  error={form.reportFormat === ''
                    ? errorField
                    : null
                  }
                />
                <div className='flex flex-row justify-between pt-14'>
                  <div>
                    <p className="text-primaryText font-500" style={{ fontSize: 15 }}>
                      Show Close Batch in Batch Detail
                    </p>
                    <div className="flex items-center">
                      <p style={{ fontSize: 15 }}>No</p>
                      <Switch
                        checked={form.closeBatch}
                        className={classes.switchCustom}
                        onChange={onChangeCloseBatch}
                      />
                      <p style={{ fontSize: 15 }}>Yes</p>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </Box>
        <div style={{ marginBottom: 38 }} className="px-16">
          {/* ----------------------------- */}
          {/* <div>
            <p className="text-primaryText font-500 mt-10">
              Enable AR Management
            </p>
            <div className="flex items-center">
              <p>No</p>
              <Switch
                checked={form.arManagement}
                className={classes.switchCustom}
                onChange={onChangeAR}
              />
              <p>Yes</p>
            </div>
          </div> */}
          {/* {form.arManagement && (
            <>
              <div>
                <p className="text-primaryText font-500 mt-10">
                  Authorize.net API
                </p>
              </div>
              <CustomTextField
                textTopField="Consumer Key"
                value={form.consumerKeyAuthorizeNet}
                onChange={onChangeText('consumerKeyAuthorizeNet')}
                name="consumerKey"
                error={form.consumerKeyAuthorizeNet === '' ? errorField : null}
              />
              <CustomTextField
                textTopField=" Consumer Secret"
                value={form.consumerSecretAuthorizeNet}
                onChange={onChangeText('consumerSecretAuthorizeNet')}
                name="consumerSecret"
                error={form.consumerSecretAuthorizeNet === '' ? errorField : null}
              />
              <div>
                <p className="text-primaryText font-500 mt-10">
                  Featherpay API
                </p>
              </div>
              <CustomTextField
                textTopField="Consumer Key"
                value={form.consumerKeyFeatherPay}
                onChange={onChangeText('consumerKeyFeatherPay')}
                name="consumerKey"
                error={form.consumerKeyFeatherPay === '' ? errorField : null}
              />
              <CustomTextField
                textTopField=" Consumer Secret"
                value={form.consumerSecretFeatherPay}
                onChange={onChangeText('consumerSecretFeatherPay')}
                name="consumerSecret"
                error={form.consumerSecretFeatherPay === '' ? errorField : null}
              />
            </>
          )}
          */}
          {/* ----------------------------- */}
          {loading ? (
            <div className="w-full flex justify-center mt-12">
              <CircularProgress size={30} style={{ color: '#0594FB' }} />
            </div>
          ) : (
            <div className="w-full flex justify-end items-center mt-20">
              <p
                className="underline text-textLight mr-10 cursor-pointer"
                onClick={clearData}
              >
                Cancel
              </p>
              <CustomButton label="save" onClick={sendNewLocationS} />
            </div>
          )}
        </div>
      </Card>
    </Dialog>
  );
}

export default AddLocationSetting;
