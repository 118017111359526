import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  TableSortLabel,
  Box,
  CircularProgress,
  IconButton
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { makeStyles, withStyles, createStyles } from '@mui/styles';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import DesktopWindowsRoundedIcon from '@mui/icons-material/DesktopWindowsRounded';

import { getConections } from 'app/firebase/services/conectionsSlice';
import { isEmpty } from 'ramda';

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      color: '#535F68',
      border: 'none',
      padding: '10 16px'
    },
    body: {
      fontSize: 14,
      padding: '10px 14px',
      border: 'none',
      height: 70,
      color: '#535F68',
      fontWeight: 500
    }
  })
)(TableCell);

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#FFFFFF'
      },
      backgroundColor: 'rgba(241, 243, 251, 0.6)'
    }
  })
)(TableRow);

const useStyles = makeStyles(() => ({
  tableCustom: {
    borderRadius: 8,
    boxShadow: 'none',
    border: '2px solid #E4E7F0'
  },
  headTableCus: {
    background: '#F1F3FB',
    height: 50
  },
  tablecellCust: {
    color: '#535F68',
    fontSize: 17,
    fontWeight: 'bold',
    width: '16%'
  }
}));

/**
 *
 * @param a - first item to compare
 * @param b  - second item to be compared
 * @param orderBy  - field by which the data is to be organized
 */

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * @param order - order type asc or desc
 * @param orderBy  - item by which the information will be organized
 * @returns data organized alphabetically
 */

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * @param array - data to organize
 * @param comparator  - function to compare and organize data
 * @returns data organized alphabetically in descending order
 */

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'company',
    oder: true,
    label: 'Company name',
    width: '16%'
  },
  {
    id: 'device',
    oder: true,
    label: 'Device name',
    width: '16%'
  },
  {
    id: 'user',
    oder: true,
    label: 'Device user',
    width: '16%'
  },
  {
    id: 'patform',
    oder: true,
    label: 'Platform',
    width: '16%'
  },
  {
    id: 'dtpversion',
    oder: false,
    label: 'DTP version',
    width: '16%'
  }
];

/**
 * @param order - order type asc or desc
 * @param orderBy  - item by which the information will be organized
 * @param onRequestSort  - function to select the item by which the info will be arranged
 * @returns table header with options for organizing the data
 */

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.headTableCus}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tablecellCust}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: headCell.width }}
          >
            {headCell.oder ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function Connections(props) {
  const { dataCompany } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [loading, setLoading] = useState(false);
  const [newConnection, setNewConnection] = useState([]);

  const dataDevices = useSelector(({ connections }) => connections.devices);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await dispatch(getConections());
    };
    fetch();
  }, []);

  useEffect(() => {
    function init() {
      if (dataDevices.length > 0 && !isEmpty(dataCompany)) {
        const filteredConnections = dataDevices.filter(e => e.companyName === dataCompany.companie.name)
        setNewConnection(filteredConnections);
        setLoading(false);
      }
    }
    init();
  }, [dataDevices, dataCompany]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /** Function to bring back the list of connected devices  */
  const refresh = async () => {
    setLoading(true);
    await dispatch(getConections());
    setLoading(false);
  };

  return (
    <div style={{ minWidth: 600 }}>
      <div className="flex flex-col mb-28 mt-24" style={{ marginBottom: '1px' }}>
        <p className="text-primaryText text-15 font-600" style={{ marginBottom: '1px' }}>Connections</p>
        <div className="flex text-textLight mt-10" style={{ fontSize: 14, marginTop: '1px' }}>
          <div className="flex items-center">
            <DesktopWindowsRoundedIcon style={{ color: '#535F68' }} />
            <div
              className="mx-4 rounded-8 h-14 w-14 flex items-center justify-center"
              style={{ background: '#F1F3FB' }}
            >
              {newConnection && newConnection.length}
            </div>
          </div>
          <IconButton onClick={refresh}>
            <CachedRoundedIcon style={{ color: '#535F68' }} />
          </IconButton>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="w-full flex justify-center mt-12">
            <CircularProgress size={30} style={{ color: '#0594FB' }} />
          </div>
        ) : (
          <TableContainer component={Paper} className={classes.tableCustom}>
            <Table sx={{ minWidth: 600 }}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={newConnection.length}
              />
              <TableBody>
                {newConnection && newConnection.length > 0 ? (
                  stableSort(newConnection, getComparator(order, orderBy))
                    .map((e, index) => {
                      return (
                        <StyledTableRow key={e.id}>
                          <StyledTableCell
                            align="left"
                            style={{ width: '16%' }}
                          >
                            <div className="w-max p-2 text-secondaryText">
                              {e.companyName}
                            </div>
                          </StyledTableCell>                          
                          <StyledTableCell
                            style={{ width: '16%', maxWidth: 300 }}
                            align="left"
                          >
                            <div className='w-max p-2 text-secondaryText'>
                              {e.deviceName}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '16%', maxWidth: 300 }}
                            align="left"
                          >
                            <div className="w-max p-2 text-secondaryText">
                              {e.deviceUser}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '16%', maxWidth: 300 }}
                            align="left"
                          >
                            <div className="w-max p-2 text-secondaryText">
                              {e.platform}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              width: '16%',
                              color: '#0594FB',
                              cursor: 'pointer'
                            }}
                            align="left"
                          >
                            <div className="w-max p-2 text-secondaryText">
                              {e.dtpVersion}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })) :
                  <StyledTableRow>
                    <StyledTableCell
                      align="left"
                      style={{ padding: 16, fontSize: 18 }}
                    >
                      No data found
                    </StyledTableCell>
                  </StyledTableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
}
