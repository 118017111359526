import React from 'react';
import { makeStyles } from '@mui/styles';
import {
    InputLabel,
    FormControl,
    Select,
    MenuItem
} from '@mui/material';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            borderRadius: 6,
            '& .MuiOutlinedInput-input': {
                padding: 9
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#B2B9CB',
                borderWidth: 1,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#B2B9CB',
                borderWidth: 1,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#B2B9CB',
                borderWidth: 1,
            },
            '&.Mui-disabled': {
                border: '1px !important',
            },
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#d32f2f'
        },
        '& .MuiOutlinedInput-root.Mui-error': {
            color: '#d32f2f',
            '& input': {
                borderRadius: 4,
                fontSize: 17,
                padding: 9,
                borderColor: '#B2B9CB',
            },
        },
        '& .MuiFormLabel-root': {
            fontWeight: 400,
            fontSize: 16,
            '&.Mui-focused': {
                fontWeight: 500,
            },
            '&.Mui-error': {
                color: '#d32f2f',
                fontWeight: 500,
            },
        },
        '& > div .MuiSelect-select': {
            '&:focus': {
                background: 'white',
            },
        },
    },
    optionMenu: {
        background: 'white',
        padding: '10px 0px',
        fontWeight: 500,
        fontSize: 13,
        marginLeft: 4,
        marginRight: 8,
        '&:hover': {
            borderRadius: 4,
        },
    },
    textField: {
        fontSize: 12,
        marginTop: 12,
    }
}));

// interface Props {
//     style?: any;
//     value: any;
//     onChange: (text: any) => void;
//     options: any[];
//     label: string;
//     name: string;
//     disabled?: boolean;
//     error?: boolean;
//     minLength?: number;
//     maxLength?: number;
//     defaultValue?: string;
// }

const CustomSelect = (props) => {

    const classes = useStyles();
    const { value, onChange, options, label, name, error, disabled, minLength, maxLength, defaultValue } = props;

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <p className={classes.textField} style={{color: error ? '#d32f2f' : '#99A0AB'}}>
                {label}
            </p>
            <Select
                value={value}
                onChange={onChange}
                error={error}
                disabled={disabled}
                inputProps={{
                    name: name,
                    id: name,
                    maxLength,
                    minLength,
                }}
                defaultValue={defaultValue}
            >
                {options && options.length > 0 ? options.map(option => (
                    <MenuItem key={option.value} value={option.value} className={classes.optionMenu}>
                        {option.label}
                    </MenuItem>
                )) : []}
            </Select>
        </FormControl>
    );
};

export default CustomSelect;