import { createSlice } from "@reduxjs/toolkit";
import firebase from "../firebase";

/*  request to fetch all records of the devices in session  */

export const getConections = () => async (dispatch) => {
    const db = firebase.firestore();
    try {
        const connections = await db.collection('Devices').get()
        const listDevices = connections.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(d => d.inSession === true)
        await dispatch(setDevices(listDevices))
    } catch (error) {
        throw new Error(`*** REDUX -> getConections *** ${error}`);
    }
}

/**
 * @author Randall Medina
 * @description Function for filtering of device data by matches
 * @param search
 */
export const getSearchConections = (search) => async (dispatch) => {
    const db = firebase.firestore();
    try {
        const collectionRef = await db.collection('Devices').get();
        const getAllRelations = async (tt) => {
            let filteredDocs = [];
            tt.docs.map(async doc => {
                const fieldCompany = doc.get('companyName')?.toLowerCase();
                const fieldName = doc.get('contactName')?.toLowerCase();
                const fieldDeviceName = doc.get('deviceName')?.toLowerCase();
                const fieldDeviceUser = doc.get('deviceUser')?.toLowerCase();
                const fieldPlatform = doc.get('platform')?.toLowerCase();
                const fieldVersion= doc.get('dtpVersion')?.toLowerCase();
                if ( 
                    (fieldCompany && fieldCompany.includes(search.toLowerCase())) ||
                    (fieldName && fieldName.includes(search.toLowerCase())) ||
                    (fieldDeviceName && fieldDeviceName.includes(search.toLowerCase())) ||
                    (fieldDeviceUser && fieldDeviceUser.includes(search.toLowerCase())) ||
                    (fieldPlatform && fieldPlatform.includes(search.toLowerCase())) ||
                    (fieldVersion && fieldVersion.includes(search.toLowerCase())) 
                ) {
                    filteredDocs = filteredDocs.concat(doc);
                }
            });
            const arrayData = filteredDocs.map(async doc => {
                return { ...doc.data(), id: doc.id }
            });
            return Promise.all(arrayData);
        }
        const newData = await getAllRelations(collectionRef);
        await dispatch(setDevices(newData))
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> getSearchCompanies *** ${error}`);
    }
};

const conectionsSlice = createSlice({
    name: 'connections',
    initialState: {
        devices: [],
    },
    reducers: {
        setDevices: (state, action) => {
            state.devices = action.payload;
        },
    }
});

export const { setDevices } = conectionsSlice.actions;

export default conectionsSlice.reducer;