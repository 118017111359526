import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CircularProgress,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  TableSortLabel,
  Paper,
  Table,
  Box,
  IconButton,
} from '@mui/material';
import { makeStyles, withStyles, createStyles } from '@mui/styles';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { visuallyHidden } from '@mui/utils';

import {
  deleteLocation,
  changeDefaultLocation
} from 'app/firebase/services/locationsSlice';
import { getCompanyById } from 'app/firebase/services/companiesSlice';

import CustomButton from 'app/components/CustomButton';
import CustomDialog from 'app/components/CustomDialog';
import CustomPhoneNumberInput from 'app/components/CustomPhoneNumberInput';
import AddLocation from './AddLocation';
import CustomRadioCheck from 'app/components/CustomRadioCheck';
import EditLocation from './EditLocation';

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      color: 'black',
      border: 'none',
      padding: '10 16px'
    },
    body: {
      fontSize: 14,
      padding: '10px 16px',
      border: 'none',
      height: 75,
      color: '#535F68',
      fontWeight: 500
    }
  })
)(TableCell);

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: (props) => (props.state ? 'red' : 'white')
      },
      backgroundColor: (props) => (props.state ? 'green' : '#FBFBFE')
    }
  })
)(TableRow);

const useStyles = makeStyles(() => ({
  tableCustom: {
    borderRadius: 6,
    marginTop: 27
  },
  headTableCus: {
    background: '#F1F3FB',
    height: 50
  },
  tablecellCust: {
    color: '#535F68',
    fontSize: 16,
    fontWeight: 'bold',
    width: '25%'
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    oder: true,
    label: 'Location name',
    width: '20%'
  },
  {
    id: 'address',
    oder: false,
    label: 'Address',
    width: '25%'
  },
  {
    id: 'phone',
    oder: true,
    label: 'Phone',
    width: '15%'
  },
  {
    id: 'Main ubication',
    oder: false,
    label: 'Main Location',
    width: '20%'
  },
  {
    id: 'details',
    oder: false,
    label: 'Details',
    width: '15%'
  },
  {
    id: 'Delete',
    oder: false,
    label: '',
    width: '5%'
  }
];

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.headTableCus}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tablecellCust}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: headCell.width }}
          >
            {headCell.oder ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Locations(props) {
  const { dataCompany, companyId, loadingData } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [locationIdDel, setLocationIdDel] = useState(false);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [dialogNewLocation, setDialogNewLocation] = useState(false);
  const [dialogEditLocation, setDialogEditLocation] = useState(false);
  const [contactData, setContactData] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [isDefault, setIsDefault] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sendDeleteLocation = async () => {
    setLoading(true);
    await dispatch(deleteLocation(companyId, locationIdDel));
    await dispatch(getCompanyById(companyId));
    setLoading(false);
    setDialogDelete(false);
  };

  const changeDefault = (defaultId) => async () => {
    setLoading(true);
    setIsDefault(defaultId);
    await dispatch(changeDefaultLocation(companyId, defaultId));
    await dispatch(getCompanyById(companyId));
    setLoading(false);
  };

  return (
    <div className="w-full flex flex-col py-20 min-h-128">
      <div className="flex justify-between items-end">
        <p className="text-primaryText text-15 font-600">Locations</p>
        <CustomButton
          label="Add new Location"
          onClick={() => {
            setDialogNewLocation(true);
          }}
        />
        <AddLocation
          open={dialogNewLocation}
          onClose={() => {
            setDialogNewLocation(false);
          }}
          companyId={companyId}
        />
      </div>
      {loadingData ? (
        <div className="w-full flex justify-center mt-12">
          <CircularProgress size={30} style={{ color: '#0594FB' }} />
        </div>
      ) : (
        <TableContainer component={Paper} className={classes.tableCustom}>
          <Table sx={{ minWidth: 700 }}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={dataCompany.location.length}
            />
            <TableBody>
              {dataCompany.location && dataCompany.location.length > 0 ? (
                stableSort(
                  dataCompany.location,
                  getComparator(order, orderBy)
                ).map((e) => {
                  const address = e.address
                  const defaultUbication =
                    dataCompany.companie &&
                      dataCompany.companie.defaultMainLocation
                      ? dataCompany.companie.defaultMainLocation === e.id
                      : false;
                  return (
                    <StyledTableRow key={e.id}>
                      <StyledTableCell align="left" style={{ width: '20%' }}>
                        <div className="bg-light w-max rounded-4 p-4 text-secondaryText">
                          {e.name}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: '25%' }}>
                        {address.street}&nbsp;
                        {address.city}&nbsp;{address.zip}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }} align="left">
                        <CustomPhoneNumberInput
                          country="US"
                          value={e.phone ? e.phone : ''}
                          maxLength={14}
                          disabled={true}
                          style={{
                            border: '1px solid transparent',
                            fontSize: 14,
                            color: '#535F68',
                            background: 'transparent',
                            padding: 0
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '20%' }} align="left">
                        {loading && isDefault === e.id ? (
                          <div className="mt-12">
                            <CircularProgress
                              size={30}
                              style={{ color: '#0594FB' }}
                            />
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <CustomRadioCheck
                              disabled={loading}
                              checked={defaultUbication}
                              onChange={changeDefault(e.id)}
                            />
                            {defaultUbication ? (
                              <p className="font-500 text-primaryText">
                                Main Location
                              </p>
                            ) : (
                              <p className="font-500 text-textLight">
                                Mark as Main
                              </p>
                            )}
                          </div>
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          width: '15%',
                          color: '#0594FB',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setDialogEditLocation(true);
                          setContactData({ id: e.id, ...e });
                        }}
                      >
                        View location
                      </StyledTableCell>
                      {!defaultUbication ? 
                        <StyledTableCell align="center" style={{ with: '5%' }}>
                          <IconButton
                            onClick={() => {
                              setLocationIdDel(e.id);
                              setDialogDelete(true);
                            }}
                          >
                            <DeleteRoundedIcon style={{ color: '#99A0AB' }} />
                          </IconButton>
                        </StyledTableCell> : ''
                      }
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    align="left"
                    style={{ padding: 16, fontSize: 18 }}
                  >
                    No data
                  </StyledTableCell>
                </StyledTableRow>
              )}
              <CustomDialog
                open={dialogDelete}
                title="Are you sure you want to eliminate the location?"
                actions={
                  loading ? (
                    <CircularProgress size={30} style={{ color: '#0594FB' }} />
                  ) : (
                    <div className="w-full flex justify-center items-end mt-10">
                      <p
                        className="underline text-textLight mr-10 cursor-pointer"
                        onClick={() => {
                          setDialogDelete(false);
                        }}
                      >
                        Cancel
                      </p>
                      <CustomButton label="yes" onClick={sendDeleteLocation} />
                    </div>
                  )
                }
              />
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {dialogEditLocation && (
        <EditLocation
          open={dialogEditLocation}
          companyId={companyId}
          contactData={contactData}
          onClose={() => {
            setDialogEditLocation(false);
          }}
        />
      )}
    </div>
  );
}

export default Locations;
