import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import { isEmpty } from 'ramda';
import { Card, CircularProgress, Dialog, Switch, Autocomplete, TextField, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getContactsById, getAllCompanies, getAllAppVersioning, setDataContacts } from 'app/firebase/services/notificationsSlice';

const useStyles = makeStyles({
    dialog: {
        '& .MuiPaper-root.MuiDialog-paper': {
            borderRadius: 20
        },
    },
    cardContainer: {
        margin: "auto",
        minWidth: 500,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        },

        '& #boxCompanyName': {
            '& .MuiOutlinedInput-root': {
                padding: 3
            },
        },
        '& #boxContact': {
            '& .MuiOutlinedInput-root': {
                padding: 3
            },
        }
    },
    textField: {
        fontSize: 12,
        marginTop: 12,
    }
});

function DetailNotification(props) {
    const { onClose, open, data } = props
    const classes = useStyles();
    const dispatch = useDispatch();
    // --------------------------------------------------------
    const listCompanies = useSelector(({ notifications }) => notifications.dataCompanies);
    const listContacts = useSelector(({ notifications }) => notifications.dataContacts);
    const versioningList = useSelector(({ notifications }) => notifications.dataVersioning);
    // --------------------------------------------------------
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        subject: '',
        message: '',
        companyName: '',
        contact: [],
        dtpVersion: '',
        status: '',
        created: ''
    });
    // --------------------------------------------------------
    useEffect(() => {
        async function fetch() {
            setLoading(true)
            await dispatch(getAllCompanies());
            await dispatch(getAllAppVersioning());
        };
        fetch();
    }, []);

    useEffect(() => {
        async function fetch() {
            if (data) {
                console.log('data', data)
                await dispatch(getContactsById(data.companyName));
                setLoading(false)
            };
        }
        fetch();
    }, [data]);

    useEffect(() => {
        async function fetchData() {
            if (data) {

                let res01;
                let res03;
                if (listCompanies.length > 0 && data.companyName !== 'all') {
                    res01 = listCompanies.find(t => t.id === data.companyName);
                }
                if (versioningList.length > 0) {
                    res03 = versioningList.find(t => t.id === data.dtpVersion);
                }
                setForm({
                    ...data,
                    companyName: data.companyName === 'all' ? 'All' : res01?.name,
                    dtpVersion: data.dtpVersion === 'all' ? 'All' : res03?.version
                });
            }
        }
        fetchData();
    }, [data, listCompanies, listContacts, versioningList]);
    // --------------------------------------------------------
    /**
     * @author Randall Medina
     * @description Clear data when closing modal function
     */
    const clearData = async () => {
        setForm({
            subject: '',
            message: '',
            companyName: '',
            contact: [],
            dtpVersion: '',
            status: '',
            created: ''
        });
        await dispatch(setDataContacts([]))
    }
    return (
        <Dialog
            style={{
                justifyContent: "center",
                justifyItems: "center",
                display: "flex",
            }}
            className={classes.dialog}
            open={open}
        >
            <Card className={classes.cardContainer}>
                <div className='flex justify-center items-center py-14' style={{ background: '#FAFAFD' }}>
                    <p className='text-primaryText text-13 font-600'>
                        Detail Notification
                    </p>
                </div>
                <div
                    style={{ marginBottom: 38 }}
                    className='px-20'
                >
                    {loading ?
                        <div className='w-full flex justify-center mt-12'>
                            <CircularProgress size={30} style={{ color: '#0594FB' }} />
                        </div>
                        :
                        <div>
                            <div className='flex flex-col'>
                                <div className='font-bold'>Created:</div>
                                <div>{moment(form.created).format('MM-DD-YYYY hh:mm a')}</div>
                            </div>
                            <div className='flex flex-col'>
                                <div className='font-bold'>Subject:</div>
                                <div>{form.subject}</div>
                            </div>
                            <div className='flex flex-col'>
                                <div className='font-bold'>Message:</div>
                                <div>{form.message}</div>
                            </div>
                            <div className='flex flex-col'>
                                <div className='font-bold'>Status:</div>
                                <div>{form.status === 1 ? 'Draft ' : 'Sent'}</div>
                            </div>
                            <div className='flex flex-col'>
                                <div className='font-bold'>DTP Version:</div>
                                <div>{form.dtpVersion}</div>
                            </div>
                            <div className='flex flex-col'>
                                <div className='font-bold'>Company Name:</div>
                                <div>{form.companyName}</div>
                            </div>
                            <div className='flex flex-col'>
                                <div className='font-bold'>Contact:</div>
                                <div>
                                    {form.contact.map(i => (
                                        <span>{i.title}{form.contact[form.contact.length - 1].id === i.id ? '' : ','}&nbsp;</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                    {/* ------------------------------------------------------ */}
                    <div className='w-full flex justify-end items-center mt-20'>
                        <p
                            className='underline text-textLight mr-10 cursor-pointer'
                            onClick={() => {
                                onClose();
                                clearData();
                            }}
                        >
                            Cancel
                        </p>
                    </div>
                </div>
            </Card>
        </Dialog>
    );
}

export default DetailNotification;