import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    customGeneral: {
        height: 33,
        padding: '8px 16px',
        borderRadius: 8,
        fontWeight: 500,
        fontSize: 16,
        color: 'white',
        textTransform:'capitalize',
        background: '#789B3E',
        '&:disabled': {
            background: '#979797',
        },
        '&:hover': {
            background: '#789B3E',
        },
    }
}))
const CustomButton = (props) => {
    const { label, disabled, style, onClick, width} = props;
    const classes = useStyles();
    const sizeButtonWidth = width === 'full' ? '100%' : 'fit-content';

    return (
        <Button
            {...props}
            className={classes.customGeneral}
            style={{ ...style, width: sizeButtonWidth, minWidth:120 }}
            disabled={disabled}
            disableElevation={true}
            disableRipple={false}
            onClick={onClick}
            variant='contained'
        >
            {label}
        </Button>
    );
}
export default CustomButton;