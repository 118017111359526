import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import { useAuth } from '../@context/auth';
import Login from "./components/pages/Login";
import UISchema from "./components/pages/UISchema";
import Dashboard from "./components/pages/Dashboard";
import CompanyDetail from "./components/pages/CompanyDetail";
import ForgotPassword from "./components/pages/ForgotPassword";
import ContactDetail from './components/pages/CompanyDetail/ContactDetail';
import { CircularProgress } from '@mui/material';
import Conections from './components/pages/Connections';
import Notifications from './components/pages/Notifications';

/**
 * Component that defines the main routes.
 *
 * @returns {Function} - react component.
 */
function Routes() {
    const auth = useAuth();

    // Wait until you have information from the logged in user - while a blank page
    // will be displayed with a spinner in the middle of the page.
    if (auth.initializing) {
        return (
            <div className="app-loader-container">
                <div className="app-spinner">
                    <CircularProgress size={30} style={{ color: '#0594FB' }} />
                </div>
            </div>
        );
    }

    return (
        <Router >
            <Switch>
                <PrivateRoute exact path="/">
                    <Redirect to="/dashboard" />
                </PrivateRoute>
                <Route exact path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />

                <PrivateRoute path="/dashboard">
                    <Dashboard />
                </PrivateRoute>
                <PrivateRoute path="/ui-schema">
                    <UISchema />
                </PrivateRoute>
                <PrivateRoute path="/connections">
                    <Conections />
                </PrivateRoute>
                <PrivateRoute path="/company/:id">
                    <CompanyDetail />
                </PrivateRoute>
                <PrivateRoute path="/contact/:id">
                    <ContactDetail />
                </PrivateRoute>
                <PrivateRoute path="/notifications">
                    <Notifications />
                </PrivateRoute>
                <Route exact path="*">
                    <Redirect to="/" />
                </Route>
            </Switch>
        </Router>
    );
}

/**
 * Route that will verify if the user is authenticated to render the
 * children component, otherwise it will be redirected to the login route.
 *
 * @param {Object} params - component params
 * @param {React.Component} children - children react component
 * @returns {Route} - new route component
 */
function PrivateRoute({ children, ...rest }) {
    const auth = useAuth();

    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export default Routes;