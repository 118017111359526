import { createSlice } from '@reduxjs/toolkit';

import firebase from '../firebase';

export const createLocation = (companyId, data) => async (dispatch) => {
  const db = firebase.firestore();
  try {
    const newLocation = {
      name: data.name,
      phone: data.phone,
      address: {
        city: data.city,
        state: data.state,
        street: data.street,
        zip: data.zip
      }
    };
    await db
      .collection('Companies')
      .doc(companyId)
      .collection('Locations')
      .add(newLocation);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> createLocation *** ${error}`);
  }
};

export const getLocations = (idCompany) => async (dispatch) => {
  const db = firebase.firestore();
  try {
    const dataLocations = await db
      .collection('Companies')
      .doc(idCompany)
      .collection('Locations')
      .get();
    const location = dataLocations.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
    await dispatch(setLocations(location));
  } catch (error) {
    throw new Error(`*** REDUX -> getLocations *** ${error}`);
  }
};

/* Function to change default Location in location section */
export const changeDefaultLocation = (companyId, locationId) => async (dispatch) => {
  const db = firebase.firestore();
  try {
    await db.collection('Companies')
      .doc(companyId)
      .update({
        defaultMainLocation: locationId
      });
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> changeDefaultLocation *** ${error}`);
  }
};

export const updateLocation =
  (companyId, locationId, data) => async (dispatch) => {
    const dataLocation = {
      id: data.id,
      name: data.name,
      phone: data.phone,
      address: {
        city: data.city,
        state: data.state,
        street: data.street,
        zip: data.zip
      }
    };
    const db = firebase.firestore();
    try {
      await db
        .collection('Companies')
        .doc(companyId)
        .collection('Locations')
        .doc(locationId)
        .update(dataLocation);
      return true;
    } catch (error) {
      throw new Error(`*** REDUX -> updateLocation *** ${error}`);
    }
  };

export const deleteLocation = (companyId, locationId) => async (dispatch) => {
  const db = firebase.firestore();
  try {
    await db
      .collection('Companies')
      .doc(companyId)
      .collection('Locations')
      .doc(locationId)
      .delete();
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> deleteLocation *** ${error}`);
  }
};

const companiesSlice = createSlice({
  name: 'locations',
  initialState: {
    enabledIntegration: false,
    locations: []
  },
  reducers: {
    setLocations: (state, action) => {
      state.locations = action.payload;
    }
  }
});

export const { setLocations } = companiesSlice.actions;

export default companiesSlice.reducer;
