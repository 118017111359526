import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { useDispatch, useSelector } from 'react-redux';
import { hideMessage } from '../../firebase/services/messageSlice';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomMessage = (props) => {

    const dispatch = useDispatch();
    const state = useSelector(({ message }) => message.state);
    const options = useSelector(({ message }) => message.options);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(hideMessage(state))
    };

    return (
        <Stack
            {...options}
            open={state}
            onClose={handleClose}

            spacing={2} sx={{ width: '100%' }}>
            <Snackbar
                open={state}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={options.anchororigin}
                key={options.anchorOrigin.vertical + options.anchorOrigin.horizontal}
            >
                <Alert onClose={handleClose} severity={options.variant} sx={{ width: '100%' }}>
                    {options.message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}
export default CustomMessage;