import React, { useState, useEffect, useContext, createContext } from 'react';


import firebase from '../app/firebase/firebase';


const authContext = createContext();

/**
 * Provider component that wraps your app and makes auth object
 * available to any child component that calls useAuth().
 *
 * @param {Object} props
 * @param {React.Component} props.children
 * @returns {React.Component}
 */
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

/**
 * @name ReloadFunction
 * @function
 */

/**
 * @typedef {Object} AuthContext
 * @property {Object} user - firebase user information
 * @property {boolean} initializing
 * @property {boolean} loading
 * @property {ReloadFunction} reload
 */

/**
 * Hook for child components to get the auth object and
 * re-render when it changes.
 *
 * @returns {AuthContext}
 */
export const useAuth = () => {
    return useContext(authContext);
};

/**
 * Provider hook that creates auth object and handles state.
 *
 * @returns {React.Component}
 */
function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);

    const onAuthCompleted = async (user) => {
        setLoading(false);
        setUser(user);
        setInitializing(false);
    };

    /**
     * Firebase Logout method
     *
     * @returns {Promise.<firebase.auth.IdTokenResult>}
     */
    function reload() {
        if (!user) {
            console.warn('reload auth with out user.');
            return;
        }

        return user.getIdTokenResult(true);
    }

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            onAuthCompleted(user);
        });
        const unsubscribe_Token = firebase.auth().onIdTokenChanged((user) => {
            onAuthCompleted(user);
        });
        // Cleanup subscription on unmount
        return () => {
            unsubscribe();
            unsubscribe_Token();
        };
    }, []);

    return {
        user,
        initializing,
        loading,
        reload
    };
}