import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    CircularProgress,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    TableContainer,
    Paper,
    Table,
} from '@mui/material';
import { getCompanyById, deleteCompany } from 'app/firebase/services/companiesSlice';
import { makeStyles, withStyles, createStyles } from '@mui/styles';

import EditCompany from './EditCompany';
import CustomButton from 'app/components/CustomButton';
import CustomPhoneNumberInput from 'app/components/CustomPhoneNumberInput';
import CustomDialog from 'app/components/CustomDialog';

const StyledTableCell = withStyles(() =>
    createStyles({
        head: {
            color: 'black',
            border: 'none',
            padding: '10 16px',
        },
        body: {
            fontSize: 14,
            padding: '10px 16px',
            border: 'none',
            height: 75,
            color: '#535F68',
            fontWeight: 500
        },
    }),
)(TableCell);

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: 'white',
            },
            backgroundColor: '#FBFBFE',
        },
    }),
)(TableRow);

const useStyles = makeStyles(() => ({
    tableCustom: {
        borderRadius: 6,
        marginTop: 27
    },
    headTableCus: {
        background: '#F1F3FB',
        height: 50
    },
    tablecellCust: {
        color: '#535F68',
        fontSize: 16,
        fontWeight: 'bold',
        width: '25%'
    }
}))

function DataCompany(props) {
    const { dataCompany, companyId, loading } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [dialogEditCompany, setDialogEditCompany] = useState(false);
    const [dialogDelete, setDialogDelete] = useState(false);

    const deleteCompanyConfim = async () => {
        await dispatch(deleteCompany(companyId))
        history.push('/dashboard')
    }

    return (
        <div className='w-full flex flex-col py-20 min-h-128'>
            {loading ?
                <div className='w-full flex justify-center mt-12'>
                    <CircularProgress size={30} style={{ color: '#0594FB' }} />
                </div>
                :
                <div>
                    <CustomDialog
                        open={dialogDelete}
                        title='Are you sure you want to eliminate the company?'
                        actions={
                            <div className='w-full flex justify-center items-end mt-10'>
                                <p
                                    className='underline text-textLight mr-10 cursor-pointer'
                                    onClick={() => {
                                        setDialogDelete(false)
                                    }}
                                >
                                    Cancel
                                </p>
                                <CustomButton
                                    label='yes'
                                    onClick={deleteCompanyConfim}
                                />
                            </div>
                        }
                    />
                    <div className='flex justify-between items-end'>
                        <div className='flex flex-col'>
                            <p className='text-primaryText text-15 font-600'>
                                {dataCompany.companie.name}
                            </p>
                            <div className='flex text-textLight' style={{ fontSize: 14 }}>
                                <a href='/dashboard' className='underline'>
                                    Companies list
                                </a>
                                &nbsp;/&nbsp;
                                <p className='underline' onClick={() => dispatch(getCompanyById(companyId))}>
                                    {dataCompany.companie.name}
                                </p>
                            </div>
                        </div>
                        <div className='flex'>
                            <CustomButton
                                label='Delete Company'
                                style={{ background: '#FD8585', marginRight: 15 }}
                                onClick={() => {
                                    setDialogDelete(true)
                                }}
                            />
                            <CustomButton
                                label='Edit Company'
                                onClick={() => {
                                    setDialogEditCompany(true);
                                }}
                            />
                        </div>
                    </div>
                    <TableContainer component={Paper} className={classes.tableCustom}>
                        <Table sx={{ minWidth: 650 }} >
                            <TableHead className={classes.headTableCus}>
                                <TableRow>
                                    <TableCell className={classes.tablecellCust} style={{ width: '22%' }}>
                                        Phone
                                    </TableCell>
                                    <TableCell className={classes.tablecellCust}>
                                        URL
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow >
                                    <StyledTableCell
                                        align="left"
                                        style={{ width: '22%' }}
                                    >
                                        <CustomPhoneNumberInput
                                            country="US"
                                            value={dataCompany.companie.phone ? dataCompany.companie.phone : ''}
                                            maxLength={14}
                                            disabled={true}
                                            style={{
                                                border: '1px solid transparent',
                                                fontSize: 14,
                                                color: '#535F68',
                                                background: 'transparent',
                                                padding: 0
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell
                                        style={{ width: '25%', color: '#0594FB' }}
                                        align="left"
                                    >
                                        <a href={dataCompany.companie.url}>
                                            {dataCompany.companie.url}
                                        </a>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

            }
            {dialogEditCompany && (
                <EditCompany
                    open={dialogEditCompany}
                    companyId={companyId}
                    dataCompany={dataCompany.companie}
                    onClose={() => {
                        setDialogEditCompany(false);
                    }}
                />
            )}
        </div>
    );
}

export default DataCompany;
