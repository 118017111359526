import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import { sort } from 'ramda';

import { Card, CircularProgress, Dialog, Switch, Autocomplete, TextField, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CustomTextField from 'app/components/CustomTextField';
import CustomButton from 'app/components/CustomButton';
import CustomSelect from 'app/components/CustomSelect';

import { createNotification, getAllNotifications, getContactsById, getAllCompanies, getAllAppVersioning, setDataContacts } from 'app/firebase/services/notificationsSlice';

const useStyles = makeStyles({
    dialog: {
        '& .MuiPaper-root.MuiDialog-paper': {
            borderRadius: 20
        },
    },
    cardContainer: {
        margin: "auto",
        minWidth: 500,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        },

        '& #boxCompanyName': {
            '& .MuiOutlinedInput-root': {
                padding: 3
            },
        },
        '& #boxContact': {
            '& .MuiOutlinedInput-root': {
                padding: 3
            },
        }
    },
    textField: {
        fontSize: 12,
        marginTop: 12,
    }
});

function AddNotification(props) {
    const { onClose, open } = props
    const classes = useStyles();
    const dispatch = useDispatch();
    // --------------------------------------------------------
    const listCompanies = useSelector(({ notifications }) => notifications.dataCompanies);
    const listContacts = useSelector(({ notifications }) => notifications.dataContacts);
    const versioningList = useSelector(({ notifications }) => notifications.dataVersioning);
    // --------------------------------------------------------
    const [errorField, setErrorField] = useState(false)
    const [disableTag, setDisableTag] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        subject: '',
        message: '',
        companyName: '',
        dtpVersion: '',
        status: '',
        contact: [],
        allContact: false
    });
    const [arrayFiltered, setArrayFiltered] = useState([]);
    const [arrayCompanies, setArrayCompanies] = useState([]);
    const [arrayVersioning, setArrayVersioning] = useState([]);
    // --------------------------------------------------------
    useEffect(() => {
        async function fetch() {
            await dispatch(getAllCompanies());
            await dispatch(getAllAppVersioning());
        };
        fetch();
    }, []);

    useEffect(() => {
        function optionLoad() {
            if (form.contact.length > 0) {
                const r = form.contact.map(e => e.id);
                if (listCompanies.length > 0) {
                    const notlocked = listContacts.filter(e => e.status !== 'locked');
                    const newOption = notlocked.filter(resp => !r.includes(resp.id))
                    const t = newOption.map(e => ({
                        title: e.name,
                        id: e.id
                    }))
                    setArrayFiltered(t)
                } else {
                    setArrayFiltered([])
                }
            } else {
                const option2 = listContacts.length > 0 ? listContacts.filter(e => e.status !== 'locked').map(e => ({
                    title: e.name,
                    id: e.id
                })) : [];
                setArrayFiltered(option2)
            }
        }
        optionLoad()
    }, [listContacts, form.contact])

    useEffect(() => {
        if (listCompanies && listCompanies.length > 0) {
            let companiesOptions = [{ label: 'All', value: 'all' }];
            companiesOptions = companiesOptions.concat(
                listCompanies.map((e) => ({
                    label: e.name,
                    value: e.id
                })));
            setArrayCompanies(companiesOptions)
        }
    }, [listCompanies])
    // --------------------------------------------------------
    useEffect(() => {
        if (versioningList && versioningList.length > 0) {
            let appOptions = [{ label: 'All', value: 'all', order: '0' }];
            appOptions = appOptions.concat(
                versioningList.map((e) => ({
                    label: e.version,
                    value: e.id,
                    order: e.version,
                })));
            setArrayVersioning(sort((a, b) => a.order.localeCompare(b.order), appOptions))
        }
    }, [versioningList])
    // --------------------------------------------------------
    const onChangeText = (prop) => async (event) => {
        if (prop === 'companyName') {
            if (event.target.value === 'all') {
                let defaultInit = [{ title: 'All', id: 'all' }];
                setForm({ ...form, [prop]: event.target.value, contact: defaultInit, allContact: true })
                setArrayFiltered(defaultInit)
                setDisableTag(true)
                await dispatch(setDataContacts([]))
            } else {
                setForm({ ...form, [prop]: event.target.value, contact: [], allContact: false })
                await dispatch(getContactsById(event.target.value))
                setArrayFiltered([]);
                setDisableTag(false)
            }
        } else {
            setForm({ ...form, [prop]: event.target.value })
        }
    };
    /**
     * @author Randall Medina
     * @description Saving draft function
     */
    const saveNotification = async () => {
        setLoading(true)
        if (form.subject === '' || form.message === '' || form.contact.length == 0 || form.companyName === ''
            || form.dtpVersion === '') {
            setErrorField(true);
            setLoading(false);
        } else {
            const versionFind = arrayVersioning.find(e => e.value === form.dtpVersion);
            await dispatch(createNotification({ 
            ...form, 
            contact: form.contact.map(e => ({ id: e.id, title: e.title })), status: 1,
            numberVersion: versionFind.label.toLowerCase(),
            created: moment(new Date()).format("MM-DD-YYYY hh:mm:ss a"),
            updated: moment(new Date()).add(15, 'seconds').format("MM-DD-YYYY hh:mm:ss a"),
        }));
            await dispatch(getAllNotifications());
            setLoading(false);
            clearData();
            onClose()
        }
    }
    /**
     * @author Randall Medina
     * @description Sending notification to DTP App function
     */
    const sendNotification = async () => {
        setLoading(true)
        if (form.subject === '' || form.message === '' || form.contact.length == 0 || form.companyName === ''
            || form.dtpVersion === '') {
            setErrorField(true);
            setLoading(false);
        } else {
            const versionFind = arrayVersioning.find(e => e.value === form.dtpVersion);
            await dispatch(createNotification({ 
                ...form, 
                contact: form.contact.map(e => ({ id: e.id, title: e.title })), status: 2,
                numberVersion: versionFind.label.toLowerCase(),
                created: moment(new Date()).format("MM-DD-YYYY hh:mm:ss a"),
                updated: moment(new Date()).add(15, 'seconds').format("MM-DD-YYYY hh:mm:ss a"),
             }));
            await dispatch(getAllNotifications());
            setLoading(false);
            clearData();
            onClose();
        }
    }
    // --------------------------------------------------------
    /**
     * @author Randall Medina
     * @description Clear data when closing modal function
     */
    const clearData = async () => {
        setErrorField(false);
        setForm({
            subject: '',
            message: '',
            companyName: '',
            dtpVersion: '',
            status: '',
            contact: [],
            allContact: false
        });
        setArrayFiltered([])
        await dispatch(setDataContacts([]))
        setDisableTag(false)
    }

    return (
        <Dialog
            style={{
                justifyContent: "center",
                justifyItems: "center",
                display: "flex",
            }}
            className={classes.dialog}
            open={open}
        >
            <Card className={classes.cardContainer}>
                <div className='flex justify-center items-center py-14' style={{ background: '#FAFAFD' }}>
                    <p className='text-primaryText text-13 font-600'>
                        Add Notification
                    </p>
                </div>
                <div
                    style={{ marginBottom: 38 }}
                    className='px-20'
                >
                    <CustomTextField
                        textTopField='Subject*'
                        value={form.subject}
                        onChange={onChangeText('subject')}
                        name='subject'
                        error={form.subject === '' ? errorField : null}
                    />
                    <CustomTextField
                        textTopField='Message*'
                        value={form.message}
                        onChange={onChangeText('message')}
                        name='message'
                        error={form.message === '' ? errorField : null}
                    />
                    <CustomSelect
                        label='Companies'
                        options={arrayCompanies}
                        value={form.companyName}
                        name='companyName'
                        onChange={onChangeText('companyName')}
                        error={form.companyName === '' ? errorField : null}
                    />
                    <div id='boxContact'>
                        <label style={{ fontSize: 12, marginTop: 12, color: '#99A0AB' }}>Contacts*</label>
                        <Autocomplete
                            multiple
                            id="contact"
                            value={form.contact}
                            onChange={(event, newValue) => {
                                setForm({
                                    ...form,
                                    contact: newValue,
                                });
                            }}
                            disabled={disableTag}
                            disableClearable={disableTag}
                            options={arrayFiltered}
                            getOptionLabel={(option) => option.title}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option.title}
                                        {...getTagProps({ index })}
                                    // disabled={form.contact.indexOf(option) !== -1}
                                    />
                                ))
                            }
                            style={{ width: 500 }}
                            renderInput={(params) => (
                                <TextField {...params} label="" placeholder="" required error={form.contact.length == 0 ? errorField : null} />
                            )}
                        />
                    </div>
                    {/* <CustomSelect
                        label='Contacts'
                        options={arrayContacts}
                        value={form.contact}
                        name='contact'
                        onChange={onChangeText('contact')}
                        error={form.contact === '' ? errorField : null}
                    /> */}
                    <CustomSelect
                        label='DTP version'
                        options={arrayVersioning}
                        value={form.dtpVersion}
                        name='dtpVersion'
                        onChange={onChangeText('dtpVersion')}
                        error={form.dtpVersion === '' ? errorField : null}
                    />
                    {/* ------------------------------------------------------ */}
                    {loading ?
                        <div className='w-full flex justify-center mt-12'>
                            <CircularProgress size={30} style={{ color: '#0594FB' }} />
                        </div>
                        :
                        <div className='w-full flex justify-end items-center mt-20'>
                            <p
                                className='underline text-textLight mr-10 cursor-pointer'
                                onClick={() => {
                                    onClose();
                                    clearData();
                                }}
                            >
                                Cancel
                            </p>
                            <div className='mr-10'>
                                <CustomButton
                                    style={{ background: '#316296' }}
                                    label='save'
                                    onClick={saveNotification}
                                />
                            </div>
                            <CustomButton
                                label='send'
                                onClick={sendNotification}
                            />
                        </div>
                    }
                </div>
            </Card>
        </Dialog>
    );
}

export default AddNotification;