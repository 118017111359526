import firebase from "../firebase";
import { getHash } from "./hash";
import { createSlice } from '@reduxjs/toolkit';

export const createContact = (companyId, data) => async (dispatch) => {
    const db = firebase.firestore();
    try {
        const result = await firebase.functions().httpsCallable("allFunctions")({
            funcRole: "companyAdmin",
            funcName: "createUser",
            data: {
                email: data.email,
                password: data.password,
                displayName: companyId,
            }
        });
        if (result && result.data && result.data.errorInfo) {
            const resultData = { status: 'error', message: result.data.errorInfo.message }
            return resultData
        } else {
            const passwordHash = await getHash(`${data.password}`);
            const idContact = result.data.uid
            const newContact = {
                ...data,
                password: passwordHash,
                status: 'locked'
            }
            await db.collection('Companies').doc(companyId).collection('Contacts').doc(idContact).set(newContact)
            return true
        }
    } catch (error) {
        throw new Error(`*** REDUX -> createContact *** ${error}`);
    }
};

export const updateContact = (companyId, contactId, data) => async (dispatch) => {
    const db = firebase.firestore();
    try {
        const { id, locationSetting, ...newData } = data;
        await db.collection('Companies')
            .doc(companyId)
            .collection('Contacts')
            .doc(contactId)
            .update(newData)

        const disabledContact = data.status === 'locked' ? true : false
        
        await firebase.functions().httpsCallable("allFunctions")({
            funcRole: "companyAdmin",
            funcName: "updateStateUser",
            data: {
                uid: contactId,
                disabled: disabledContact
            }
        });
        return true
    } catch (error) {
        throw new Error(`*** REDUX -> updateContact *** ${error}`);
    }
};

export const deleteContact = (companyId, contactId) => async (dispatch) => {
    const db = firebase.firestore();
    try {
        await db.collection('Companies')
            .doc(companyId)
            .collection('Contacts')
            .doc(contactId)
            .delete()

        await firebase.functions().httpsCallable("allFunctions")({
            funcRole: "companyAdmin",
            funcName: "deleteUser",
            data: { uid: contactId }
        });
        return true
    } catch (error) {
        throw new Error(`*** REDUX -> createUser *** ${error}`);
    }
};

const getLocationsById = async (companyId, idLocation) => {
    const db = firebase.firestore();
    const data = await db.collection('Companies')
        .doc(companyId)
        .collection('Locations')
        .doc(idLocation)
        .get()

    const dataLocation = { ...data.data(), id: data && data.id }
    return dataLocation
}

export const getContactById = (companyId, ContactId) => async (dispatch) => {
    const db = firebase.firestore();
    try {
        const refContact = await db.collection('Companies')
            .doc(companyId)
            .collection('Contacts')
            .doc(ContactId)
            .get()

        const dataContact = refContact.data()
        const contact = {
            ...dataContact,
        }

        const dataLocations = await db.collection('Companies')
            .doc(companyId)
            .collection('Contacts')
            .doc(ContactId)
            .collection('LocationSettings')
            .get()

        const getAllRelations = async (tt) => {
            const arrayData = tt.docs.map(async doc => {
                const data = doc.data()
                if (data) {
                    const idLocation = data.location.id
                    const dataLocation = await getLocationsById(companyId, idLocation);
                    return {
                        ...doc.data(),
                        id: doc && doc.id,
                        location: dataLocation,
                        uiSchema: data && data.uiSchema && data.uiSchema.id
                    }
                }
            })
            return Promise.all(arrayData);
        }
        const locationSetting = await getAllRelations(dataLocations);
        const body = {
            contact,
            locationSetting
        }
        await dispatch(setContactById(body))
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> getContactById *** ${error}`);
    }
};

const contactSlice = createSlice({
    name: 'contacts',
    initialState: {
        loading: false,
        enabledIntegration: false,
        contactById: {},
        contactByCompany: {},
    },
    reducers: {
        setContactById: (state, action) => {
            state.contactById = action.payload;
        },
        setContactByCompany: (state, action) => {
            state.contactById = action.payload;
        },
    },
});

export const { setContactById, setContactByCompany } = contactSlice.actions;

export default contactSlice.reducer;
