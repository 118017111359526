import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  TablePagination,
  TableSortLabel,
  Box,
  CircularProgress
} from '@mui/material';
import { makeStyles, withStyles, createStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';

import { useHistory } from 'react-router-dom';
import CustomPhoneNumberInput from 'app/components/CustomPhoneNumberInput';

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      color: '#535F68',
      border: 'none',
      padding: '10 16px'
    },
    body: {
      fontSize: 14,
      padding: '10px 14px',
      border: 'none',
      height: 70,
      color: '#535F68',
      fontWeight: 500
    }
  })
)(TableCell);

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#FFFFFF'
      },
      backgroundColor: 'rgba(241, 243, 251, 0.6)'
    }
  })
)(TableRow);

const useStyles = makeStyles(() => ({
  tableCustom: {
    borderRadius: 8,
    boxShadow: 'none',
    border: '2px solid #E4E7F0'
  },
  headTableCus: {
    background: '#F1F3FB',
    height: 50
  },
  tablecellCust: {
    color: '#535F68',
    fontSize: 17,
    fontWeight: 'bold',
    width: '16%'
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    oder: true,
    label: 'Company Name',
    width: '18%'
  },
  {
    id: 'address',
    oder: false,
    label: 'Phone',
    width: '18%'
  },
  {
    id: 'url',
    oder: true,
    label: 'URL',
    width: '16%'
  },
  {
    id: 'contacts',
    oder: false,
    label: 'Contacts',
    width: '24%'
  },
  {
    id: 'view',
    oder: false,
    label: '',
    width: '12%'
  }
];
function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.headTableCus}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tablecellCust}
            style={{ width: headCell.width }}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.oder ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function Companies(props) {
  const { dataCompany, loading } = props;
  const classes = useStyles();
  const history = useHistory();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={dataCompany.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ minHeight: 40 }}
      />
      <div style={{ minHeight: 150 }}>
        {loading ? (
          <div className="w-full flex justify-center mt-12">
            <CircularProgress size={30} style={{ color: '#0594FB' }} />
          </div>
        ) : (
          <TableContainer component={Paper} className={classes.tableCustom}>
            <Table>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={dataCompany.length}
              />
              <TableBody>
                {dataCompany && dataCompany.length > 0 ? (
                  stableSort(dataCompany, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell
                            align="left"
                            style={{ width: '16%' }}
                          >
                            <div className="bg-light w-max rounded-4 p-4 text-secondaryText">
                              {row.name}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ width: '16%' }}
                          >
                            <CustomPhoneNumberInput
                              country="US"
                              value={row.phone ? row.phone : ''}
                              maxLength={14}
                              disabled={true}
                              style={{
                                border: '1px solid transparent',
                                fontSize: 14,
                                color: '#535F68',
                                background: 'transparent',
                                padding: 0
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '16%', color: '#0594FB' }}
                            align="left"
                          >
                            <a href={row.url} target="_blank">
                              {row.url}
                            </a>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '24%' }}
                            align="left"
                          >
                            <div className="w-full flex flex-wrap">
                              {row.contacts && row.contacts.length > 0
                                ? row.contacts.map((t) => {
                                  return (
                                    <div
                                      className="bg-light w-max rounded-4 p-4 text-secondaryText m-4 cursor-pointer"
                                      key={t.name}
                                      onClick={() => {
                                        history.push(`/contact/${t.User}`, {
                                          companyId: row.id,
                                          contactId: t.User
                                        });
                                      }}
                                    >
                                      {t && t.name}
                                    </div>
                                  );
                                })
                                : ''}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{
                              width: '12%',
                              color: '#0594FB',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              history.push(`/company/${row.id}`);
                            }}
                          >
                            View Company
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                ) : (
                  <StyledTableRow>
                    <StyledTableCell
                      align="left"
                      style={{ padding: 16, fontSize: 18 }}
                    >
                      No data found
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={dataCompany.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
