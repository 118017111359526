import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { getContactById } from 'app/firebase/services/contactSlice';
import { getLocations } from 'app/firebase/services/locationsSlice';
import { CompanyContext, useCompany } from '../companyContext';

import { Grid } from '@mui/material';

import DataContact from './DataContact';
import LocationsSettings from './LocationsSettings';
import Navbar from 'app/components/Navbar';

function ContactDetail(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    const data = useSelector(({ contacts }) => contacts.contactById);
    const dataLocation = useSelector(({ locations }) => locations.locations);
    const contactId = params.id
    const companyId = location && location.state && location.state.companyId

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!companyId || !contactId) {
            history.push('/dashboard')
        }
    }, [companyId, contactId])

    useEffect(() => {
        const fetch = async () => {
            setLoading(true)
            await dispatch(getContactById(companyId, contactId))
            await dispatch(getLocations(companyId))
            setLoading(false)
        }
        fetch()
    }, [contactId, companyId])

    return (
        <CompanyContext.Provider
            value={useCompany(companyId)}
        >
            <Navbar />
            <Grid
                container
                justifyContent='center'
                className='pb-32'
            >
                <Grid
                    item
                    xs={11} lg={10}
                    style={{ marginTop: 15 }}
                >
                    <DataContact
                        itemContact={data}
                        companyId={companyId}
                        contactId={contactId}
                        loadingData={loading}
                    />
                    <LocationsSettings
                        itemContact={data}
                        companyId={companyId}
                        contactId={contactId}
                        dataLocation={dataLocation}
                        loadingData={loading}
                    />
                </Grid>
            </Grid>
        </CompanyContext.Provider>
    );
}

export default ContactDetail;
