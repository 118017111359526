import React from 'react';
import Input from 'react-phone-number-input/input'

import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
    boxInputPhone: {
        color: 'black',
        border: '1px solid #B2B9CB',
        fontSize: 16,
        height: 42,
        backgroundColor: 'white',
        borderRadius: 4,
        padding: 9,
        width: '100%',
        '&:focus-visible': {
            outline: 'none'
        }
    },
});

const CustomPhoneNumberInput = (props) => {
    const { placeholder, value, maxLength } = props;
    const classes = useStyles();

    return (
        <Input
            {...props}
            placeholder={placeholder}
            value={value}
            maxLength={maxLength}
            className={classes.boxInputPhone}
        />
    )
}
export default CustomPhoneNumberInput;
