import { createSlice } from '@reduxjs/toolkit';

import firebase from '../firebase';

export const getLocatiosSettings =
  (companyId, ContactId) => async (dispatch) => {
    const db = firebase.firestore();
    try {
      const dataContacts = await db
        .collection('Companies')
        .doc(companyId)
        .collection('Contacts')
        .doc(ContactId)
        .collection('LocationSettings')
        .get();

      const locationsSettings = dataContacts.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      await dispatch(setLocationsSettings(locationsSettings));
      return true;
    } catch (error) {
      throw new Error(`*** REDUX -> getLocatiosSettings *** ${error}`);
    }
  };

export const changeDefaultUbication =
  (companyId, ContactId, locationSId) => async (dispatch) => {
    const db = firebase.firestore();
    try {
      await db
        .collection('Companies')
        .doc(companyId)
        .collection('Contacts')
        .doc(ContactId)
        .update({
          defaultUbication: locationSId
        });
      return true;
    } catch (error) {
      throw new Error(`*** REDUX -> changeDefaultUbication *** ${error}`);
    }
  };

export const addLocatioSetting =
  (companyId, ContactId, data) => async (dispatch) => {
    const db = firebase.firestore();
    let refSchema;
    let body;
    try {
      const refLocation = db
        .collection('Companies')
        .doc(companyId)
        .collection('locations')
        .doc(data.location);

      if (data.uiSchema !== '') {
        refSchema = db.collection('UISchema').doc(data.uiSchema);
        body = {
          ...data,
          location: refLocation,
          uiSchema: refSchema
        };
      } else {
        body = {
          ...data,
          location: refLocation,
          uiSchema: ''
        };
      }
      const result = await db
        .collection('Companies')
        .doc(companyId)
        .collection('Contacts')
        .doc(ContactId)
        .collection('LocationSettings')
        .add(body);

      if (data.firstLocation === true && result) {
        await firebase.functions().httpsCallable('allFunctions')({
          funcRole: 'companyAdmin',
          funcName: 'updateStateUser',
          data: {
            uid: ContactId,
            disabled: false
          }
        });
        const dataCont = {
          status: 'active'
        };
        await db
          .collection('Companies')
          .doc(companyId)
          .collection('Contacts')
          .doc(ContactId)
          .update(dataCont);

        await db
          .collection('Companies')
          .doc(companyId)
          .collection('Contacts')
          .doc(ContactId)
          .update({
            defaultUbication: result.id
          });
      }
      return result;
    } catch (error) {
      throw new Error(`*** REDUX -> addLocatioSetting *** ${error}`);
    }
  };

export const UpdateLocatioSetting =
  (companyId, ContactId, locSettId, data) => async (dispatch) => {
    const db = firebase.firestore();
    let refSchema;
    let body;
    try {
      const refLocation = db
        .collection('Companies')
        .doc(companyId)
        .collection('locations')
        .doc(data.location);

      if (data.uiSchema !== '') {
        refSchema = db.collection('UISchema').doc(data.uiSchema);
        body = {
          ...data,
          location: refLocation,
          uiSchema: refSchema
        };
      } else {
        body = {
          ...data,
          location: refLocation,
          uiSchema: ''
        };
      }
      await db
        .collection('Companies')
        .doc(companyId)
        .collection('Contacts')
        .doc(ContactId)
        .collection('LocationSettings')
        .doc(locSettId)
        .update(body);
      return true;
    } catch (error) {
      throw new Error(`*** REDUX -> UpdateLocatioSetting *** ${error}`);
    }
  };

export const deleteLocatioSetting =
  (companyId, ContactId, locSettId) => async (dispatch) => {
    const db = firebase.firestore();
    try {
      await db
        .collection('Companies')
        .doc(companyId)
        .collection('Contacts')
        .doc(ContactId)
        .collection('LocationSettings')
        .doc(locSettId)
        .delete();
      return true;
    } catch (error) {
      throw new Error(`*** REDUX -> UpdateLocatioSetting *** ${error}`);
    }
  };

const contactSlice = createSlice({
  name: 'contacts',
  initialState: {
    loading: false,
    enabledIntegration: false,
    locationsSettings: []
  },
  reducers: {
    setLocationsSettings: (state, action) => {
      state.contactById = action.payload;
    }
  }
});

export const { setLocationsSettings, setContactByCompany } =
  contactSlice.actions;

export default contactSlice.reducer;
