

import React, { useState } from "react";
import firebase from "../../firebase/firebase";
import { useAuth } from '@context/auth';
import { useHistory } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { AppBar, Toolbar, MenuItem, Popover } from "@mui/material/";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import CustomButton from "../CustomButton";

const useStyles = makeStyles(() => ({
    appBar: {
        width: "100%",
        margin: 0,
        boxShadow: 'none',
        minWidth: 600,
        background: 'white',
        borderBottom: '1px solid #B2B9CB',
        paddingLeft: '5%',
        paddingRight: '5%',
    },
}))

const Navbar = (props) => {
    const classes = useStyles();
    const { buttons, setDialogNewCompany } = props;
    const history = useHistory();
    const { user } = useAuth();

    const [userMenu, setUserMenu] = useState(null);

    const userMenuClick = event => {
        setUserMenu(event.currentTarget);
    };

    const userMenuClose = () => {
        setUserMenu(null);
    };

    return (
        <div>
            <AppBar
                position="fixed"
                className={classes.appBar}
            >
                <Toolbar
                    variant="dense"
                    disableGutters
                    style={{
                        minHeight: 80,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <a href='/' >
                        <img src='/images/logo_dtpayment.png' width='187' height='58' alt='logo' />
                    </a>
                    {user ?
                        <div className='flex'>
                            {buttons && (
                                <div className='mr-14 flex'>
                                    <CustomButton
                                        label='Add a new Company'
                                        onClick={() => {
                                            setDialogNewCompany(true);
                                        }}
                                    />
                                    <div className="mx-10">
                                        <CustomButton
                                            label='UI Schema Library'
                                            onClick={() => {
                                                history.push('/ui-schema')
                                            }}
                                        />
                                    </div>
                                    <div className="mx-10">
                                        <CustomButton
                                            label='Connections'
                                            onClick={() => {
                                                history.push('/connections')
                                            }}
                                        />
                                    </div>
                                    <CustomButton
                                        label='Notifications'
                                        onClick={() => {
                                            history.push('/notifications')
                                        }}
                                    />
                                </div>
                            )}
                            <div className="flex items-center">
                                <p style={{ color: '#316296', fontSize: 16, fontWeight: 600 }}>
                                    {user.email}
                                </p>
                                <ExpandMoreRoundedIcon
                                    style={{ color: '#316296', cursor: 'pointer', fontSize: 30 }}
                                    onClick={userMenuClick}
                                />
                                <Popover
                                    open={Boolean(userMenu)}
                                    anchorEl={userMenu}
                                    onClose={userMenuClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem
                                        style={{ color: '#979797', fontFamily: 'SF Pro Display' }}
                                        onClick={() => {
                                            firebase.auth().signOut();
                                            history.push("/");
                                            setUserMenu(null);
                                        }}
                                    >
                                        Logout
                                    </MenuItem>
                                </Popover>
                            </div>
                        </div>
                        : ''
                    }
                </Toolbar>
            </AppBar>
            <Toolbar />
        </div>
    );
}
export default Navbar;