import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Card, CircularProgress, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { updateLocation, deleteLocation } from 'app/firebase/services/locationsSlice';
import { getCompanyById } from 'app/firebase/services/companiesSlice';

import CustomTextField from 'app/components/CustomTextField';
import CustomButton from 'app/components/CustomButton';
import CustomPhoneNumberInput from 'app/components/CustomPhoneNumberInput';
import CustomDialog from 'app/components/CustomDialog';

const useStyles = makeStyles({
    dialog: {
        '& .MuiPaper-root.MuiDialog-paper': {
            borderRadius: 20
        },
    },
    cardContainer: {
        margin: "auto",
        minWidth: 450,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    textField: {
        color: '#99A0AB',
        fontSize: 12,
        marginTop: 12,
    }
});

function EditLocation(props) {
    const { onClose, companyId, contactData, open } = props
    const classes = useStyles();
    const dispatch = useDispatch();

    const [dialogDelete, setDialogDelete] = useState(false);
    const [errorField, setErrorField] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        name: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
    })

    useEffect(() => {
        if (contactData && contactData) {
            setForm({
                ...contactData,
                street: contactData.address.street,
                city: contactData.address.city,
                state: contactData.address.state,
                zip: contactData.address.zip,
            })
        }
    }, [contactData])

    const onChangeText = (prop) => (event) => {
        setForm({ ...form, [prop]: event.target.value })
    };
    const changeNumber = (prop) => (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value.length === 0 || re.test(event.target.value)) {
            setForm({ ...form, [prop]: event.target.value })
        }
    };

    const sendUpdateLocation = async () => {
        setLoading(true)
        if (form.name === '' || form.city === '' || form.state === '' || form.street === '' ||
            form.zip === '' || form.phone === '' || form.phone === undefined) {
            setErrorField(true)
            setLoading(false)
        } else {
            await dispatch(updateLocation(
                companyId,
                contactData.id,
                form
            ))
            await dispatch(getCompanyById(companyId))
            setLoading(false)
            onClose()
        }
    }

    const sendDeleteLocation = async () => {
        setLoading(true)
        await dispatch(deleteLocation(
            companyId,
            contactData.id
        ))
        await dispatch(getCompanyById(companyId))
        onClose()
        setLoading(false)
    }

    return (
        <Dialog
            style={{
                justifyContent: "center",
                justifyItems: "center",
                display: "flex",
            }}
            className={classes.dialog}
            open={open}
        >
            <Card className={classes.cardContainer}>
                <div
                    className='flex justify-center items-center py-12'
                    style={{ background: '#FAFAFD' }}
                >
                    <p className='text-primaryText text-13 font-600'>
                        Edit Location
                    </p>
                </div>
                <div style={{ marginBottom: 38 }} className='px-16'>
                    <CustomTextField
                        textTopField='Location Name*'
                        value={form.name}
                        onChange={onChangeText('name')}
                        name='name'
                        error={form.name === '' ? errorField : null}
                    />
                    <CustomTextField
                        textTopField='Address'
                        value={form.street}
                        onChange={onChangeText('street')}
                        name='street'
                        error={form.street === '' ? errorField : null}
                    />
                    <div className='flex'>
                        <div className='mr-6'>
                            <CustomTextField
                                textTopField='City'
                                value={form.city}
                                onChange={onChangeText('city')}
                                name='city'
                                error={form.city === '' ? errorField : null}
                            />
                        </div>
                        <div className='ml-6'>
                            <CustomTextField
                                textTopField='State'
                                value={form.state}
                                onChange={onChangeText('state')}
                                name='state'
                                error={form.state === '' ? errorField : null}
                            />
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='mr-6'>
                            <CustomTextField
                                textTopField='Zip'
                                value={form.zip}
                                onChange={changeNumber('zip')}
                                name='zip'
                                maxLength={15}
                                error={form.zip === '' ? errorField : null}
                            />
                        </div>
                        <div className='ml-6'>
                            <p
                                className={classes.textField}
                                style={{
                                    color: (form.phone === '' || form.phone === undefined) && errorField ?
                                        '#d32f2f' : '#99A0AB'
                                }}
                            >
                                Phone Number
                            </p>
                            <CustomPhoneNumberInput
                                country="US"
                                value={form.phone}
                                maxLength={14}
                                onChange={e => {
                                    setForm({ ...form, phone: e });
                                }}
                                style={{
                                    border: (form.phone === '' || form.phone === undefined) && errorField ?
                                        '1px solid #d32f2f' : null
                                }}
                            />
                        </div>
                    </div>
                    {loading ?
                        <div className='w-full flex justify-center mt-12'>
                            <CircularProgress size={30} style={{ color: '#0594FB' }} />
                        </div>
                        :
                        <div className='w-full flex justify-end items-center mt-20'>
                            <p
                                className='underline text-textLight mr-10 cursor-pointer'
                                onClick={onClose}
                            >
                                Cancel
                            </p>
                            <CustomButton
                                label='Delete'
                                style={{ background: '#FD8585', marginRight: 15 }}
                                onClick={() => {
                                    setDialogDelete(true)
                                }}
                            />
                            <CustomButton
                                label='save'
                                onClick={sendUpdateLocation}
                            />
                        </div>
                    }
                </div>
                <CustomDialog
                    open={dialogDelete}
                    title='Are you sure you want to eliminate the location?'
                    actions={
                        loading ?
                            <CircularProgress size={30} style={{ color: '#0594FB' }} />
                            :
                            <div className='w-full flex justify-center items-end mt-10'>
                                <p
                                    className='underline text-textLight mr-10 cursor-pointer'
                                    onClick={() => {
                                        setDialogDelete(false)
                                    }}
                                >
                                    Cancel
                                </p>
                                <CustomButton
                                    label='yes'
                                    onClick={sendDeleteLocation}
                                />
                            </div>
                    }
                />
            </Card>
        </Dialog>
    );
}

export default EditLocation;