import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import CustomTextField from 'app/components/CustomTextField';
import { useDebounce } from 'app/utils/index';

import Navbar from 'app/components/Navbar';

import Companies from './Companies';
import AddCompany from './AddCompany';
import { getAllCompanies, getSearchCompanies } from 'app/firebase/services/companiesSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [dialogNewCompany, setDialogNewCompany] = useState(false);
  const [loading, setLoading] = useState(false);
  const data = useSelector(({ companies }) => companies.dataCompanies);
  const [text, setText] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await dispatch(getAllCompanies());
      setLoading(false);
    };
    fetch();
  }, []);

  useEffect(() => {
    async function init() {
      if (text === '') {
        await dispatch(getAllCompanies());
      }
    }
    init();
  }, [text]);

 /**
     * @author Randall Medina
     * @description Function for delaying the execution of search functions
     * @param search
     */
  const updateUser = useDebounce(
    async (item) => {
      if (item.length > 0) {
        await dispatch(getSearchCompanies(item));
      }
      setLoading(false);
    },
    1000,
    false
  );

  const onChangeText = async (e) => {
    setText(e.target.value);
    setLoading(true);
    updateUser(e.target.value);
   
  };

  return (
    <div>
      <Navbar buttons={true} setDialogNewCompany={setDialogNewCompany} />
      <Grid container justifyContent="center" className="pb-32">
        <Grid item xs={11} lg={10} style={{ marginTop: 15 }}>
          <p className="text-primaryText text-15 font-600 mt-24">
            List Companies
          </p>
          <CustomTextField
            value={text}
            placeholder='Filter'
            onChange={onChangeText}
            name='search'
            variant="outlined"
            style={{
              width: 300
            }}
          />
          <Companies dataCompany={data} loading={loading} />
          <AddCompany
            onClose={() => {
              setDialogNewCompany(false);
            }}
            open={dialogNewCompany}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
