import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper,
    TableSortLabel,
    Box,
    Grid,
    CircularProgress,
    IconButton
} from '@mui/material';
import { visuallyHidden } from "@mui/utils";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { makeStyles, withStyles, createStyles } from '@mui/styles';
import Navbar from 'app/components/Navbar';
import CustomTextField from 'app/components/CustomTextField';
import CustomButton from 'app/components/CustomButton';
import CustomDialog from 'app/components/CustomDialog';
import { useDebounce } from 'app/utils/index';
import AddNotification from './AddNotification';
import EditNotification from './EditNotification';
import DetailNotification from './DetailNotification';

import { getAllNotifications, deleteNotification } from 'app/firebase/services/notificationsSlice';

const StyledTableCell = withStyles(() =>
    createStyles({
        head: {
            color: '#535F68',
            border: 'none',
            padding: '10 16px',
        },
        body: {
            fontSize: 14,
            padding: '10px 14px',
            border: 'none',
            height: 70,
            color: '#535F68',
            fontWeight: 500
        },
    }),
)(TableCell);

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: '#FFFFFF',
            },
            backgroundColor: 'rgba(241, 243, 251, 0.6)',
        },
    }),
)(TableRow);

const useStyles = makeStyles(() => ({
    tableCustom: {
        borderRadius: 8,
        boxShadow: 'none',
        border: '2px solid #E4E7F0',
    },
    headTableCus: {
        background: '#F1F3FB',
        height: 50
    },
    tablecellCust: {
        color: '#535F68',
        fontSize: 17,
        fontWeight: 'bold',
        width: '16%'
    }
}))


/**
   *
   * @param a - first item to compare
   * @param b  - second item to be compared
   * @param orderBy  - field by which the data is to be organized
*/

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

/**
   * @param order - order type asc or desc
   * @param orderBy  - item by which the information will be organized
   * @returns data organized alphabetically
*/

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
   * @param array - data to organize
   * @param comparator  - function to compare and organize data
   * @returns data organized alphabetically in descending order
*/

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "subject",
        oder: true,
        label: "Subject",
        width: '16%'
    },
    {
        id: "message",
        oder: true,
        label: "Message",
        width: '16%'
    },
    {
        id: "status",
        oder: false,
        label: "Status",
        width: '16%'
    },
    {
        id: "created",
        oder: false,
        label: "Created",
        width: '8%'
    },
    {
        id: "updated",
        oder: false,
        label: "Updated",
        width: '8%'
    },
    {
        id: 'detail',
        oder: false,
        label: 'Detail',
        width: '12%'
    },
    {
        id: 'edit',
        oder: false,
        label: '',
        width: '2%'
    },
    {
        id: 'delete',
        oder: false,
        label: '',
        width: '2%'
    }
];

/**
   * @param order - order type asc or desc
   * @param orderBy  - item by which the information will be organized
   * @param onRequestSort  - function to select the item by which the info will be arranged 
   * @returns table header with options for organizing the data 
*/

function EnhancedTableHead(props) {
    const classes = useStyles();
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.headTableCus}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className={classes.tablecellCust}
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ width: headCell.width }}
                    >
                        {headCell.oder ?
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                            :
                            headCell.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


export default function Conections(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("updated");
    const [loading, setLoading] = useState(false);
    const dataNT = useSelector(({ notifications }) => notifications.dataNotifications)
    // -----------------------------------
    const [openDialogAdd, setOpenDialogAdd] = useState(false);
    const [openDialogEdit, setOpenDialogEdit] = useState(false);
    const [selectData, setSelectData] = useState({});
    const [dialogDelete, setDialogDelete] = useState(false);
    const [openDialogDetail, setOpenDialogDetail] = useState(false);
    // -----------------------------------
    useEffect(() => {
        const fetch = async () => {
            setLoading(true)
            await dispatch(getAllNotifications())
            setLoading(false)
        }
        fetch()
    }, [])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    /**
     * @author Randall Medina
     * @description deleting notifications function
     */
    const deleteNoti = async () => {
        setLoading(true);
        await dispatch(deleteNotification(selectData.id));
        setLoading(false);
        setDialogDelete(false);
        await dispatch(getAllNotifications())
    };

    return (
        <div style={{ minWidth: 600 }}>
            <Navbar />
            <Grid
                container
                justifyContent='center'
                className='pb-32'
            >
                <Grid
                    item
                    xs={11} lg={10}
                    style={{ marginTop: 15 }}
                >
                    <div className='flex flex-col mb-28 mt-24'>
                        <p className='text-primaryText text-15 font-600'>
                            Notifications history
                        </p>
                        <div className='flex text-textLight' style={{ fontSize: 14 }}>
                            <a href='/dashboard' className='underline'>
                                Companies list
                            </a>
                            &nbsp;/&nbsp;
                            <p className='underline'>
                                Notifications history
                            </p>
                        </div>
                        <div className='flex text-textLight mt-10 mb-10' style={{ fontSize: 14 }}>
                            <CustomButton
                                label='Add Notification'
                                onClick={() => {
                                    setOpenDialogAdd(true);
                                }}
                            />
                        </div>
                    </div>
                    {dialogDelete && (
                        <CustomDialog
                            open={dialogDelete}
                            title='Are you sure you want to eliminate the notification?'
                            actions={
                                <div className='w-full flex justify-center items-end mt-10'>
                                    <p
                                        className='underline text-textLight mr-10 cursor-pointer'
                                        onClick={() => {
                                            setDialogDelete(false)
                                        }}
                                    >
                                        Cancel
                                    </p>
                                    <CustomButton
                                        label='yes'
                                        onClick={deleteNoti}
                                    />
                                </div>
                            }
                        />
                    )}
                    <div>
                        {loading ?
                            <div className='w-full flex justify-center mt-12'>
                                <CircularProgress size={30} style={{ color: '#0594FB' }} />
                            </div>
                            :
                            <TableContainer component={Paper} className={classes.tableCustom}>
                                <Table sx={{ minWidth: 600 }}>
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={dataNT.length}
                                    />
                                    <TableBody>
                                        {dataNT && dataNT.length > 0 ? (
                                            stableSort(dataNT, getComparator(order, orderBy))
                                                .map((e, index) => {

                                                    return (
                                                        <StyledTableRow key={e.id}>
                                                            <StyledTableCell
                                                                align="left"
                                                                style={{ width: '16%' }}
                                                            >
                                                                <div className='w-max p-2 text-secondaryText'>
                                                                    {e.subject}
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="left"
                                                                style={{ width: '16%' }}
                                                            >
                                                                <div className='w-max p-2 text-secondaryText'>
                                                                    {e.message}
                                                                </div>
                                                            </StyledTableCell>
                                                            {/* <StyledTableCell
                                                                align="left"
                                                                style={{ width: '16%' }}
                                                            >
                                                                <div className='w-max p-2 text-secondaryText'>
                                                                    {e.companyName}
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="left"
                                                                style={{ width: '16%' }}
                                                            >
                                                                {e.contact}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                style={{ width: '16%', maxWidth: 300 }}
                                                                align="left"
                                                            >
                                                                <div className='w-max p-2 text-secondaryText'>
                                                                    {e.dtpVersion}
                                                                </div>
                                                            </StyledTableCell> */}
                                                            <StyledTableCell
                                                                style={{ width: '16%', maxWidth: 300 }}
                                                                align="left"
                                                            >
                                                                <div className='w-max p-2 text-secondaryText'>
                                                                    {e.status === 1 ? 'Draft ' : 'Sent'}
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                style={{ width: '16%', maxWidth: 300 }}
                                                                align="left"
                                                            >
                                                                <div className='w-max p-2 text-secondaryText'>
                                                                    {moment(e.created).format('MM-DD-YYYY hh:mm a')}
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                style={{ width: '16%', maxWidth: 300 }}
                                                                align="left"
                                                            >
                                                                <div className='w-max p-2 text-secondaryText'>
                                                                    {moment(e.updated).format('MM-DD-YYYY hh:mm a')}
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="left"
                                                                style={{
                                                                    width: '12%',
                                                                    color: '#0594FB',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => {
                                                                    setSelectData(e)
                                                                    setOpenDialogDetail(true);
                                                                }}
                                                            >
                                                                View
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {e.status === 1 && (
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setSelectData(e)
                                                                            setOpenDialogEdit(true);
                                                                        }}
                                                                    >
                                                                        <EditRoundedIcon style={{ color: '#99A0AB' }} />
                                                                    </IconButton>
                                                                )}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {e.status === 1 && (
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setSelectData(e)
                                                                            setDialogDelete(true)
                                                                        }}
                                                                    >
                                                                        <DeleteRoundedIcon style={{ color: '#99A0AB' }} />
                                                                    </IconButton>
                                                                )}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    );
                                                })) :
                                            <StyledTableRow>
                                                <StyledTableCell
                                                    align="left"
                                                    style={{ padding: 16, fontSize: 18 }}
                                                >
                                                    No data found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        }

                                    </TableBody>
                                    {openDialogAdd && (
                                        <AddNotification
                                            open={openDialogAdd}
                                            onClose={() => {
                                                setOpenDialogAdd(false);
                                            }}
                                        />
                                    )}
                                    {openDialogEdit && (
                                        <EditNotification
                                            open={openDialogEdit}
                                            data={selectData}
                                            onClose={() => {
                                                setSelectData({});
                                                setOpenDialogEdit(false);
                                            }}
                                        />
                                    )}
                                    {openDialogDetail && (
                                        <DetailNotification
                                            open={openDialogDetail}
                                            data={selectData}
                                            onClose={() => {
                                                setSelectData({});
                                                setOpenDialogDetail(false);
                                            }}
                                        />
                                    )}
                                </Table>
                            </TableContainer>
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}