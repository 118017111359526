import React, { useState } from 'react';

import { Card, CircularProgress, Divider, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CustomTextField from 'app/components/CustomTextField';
import CustomButton from 'app/components/CustomButton';
import CustomSelect from 'app/components/CustomSelect';

const useStyles = makeStyles({
    dialog: {
        '& .MuiPaper-root.MuiDialog-paper': {
            borderRadius: 20
        },
    },
    cardContainer: {
        margin: "auto",
        minWidth: 500,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    textField: {
        color: '#99A0AB',
        fontSize: 12,
        marginTop: 12,
    }
});

function DialogReceive(props) {
    const { form, setForm, onSave, onClose, open } = props
    const classes = useStyles();

    const [errorField, setErrorField] = useState(false)
    const [loading, setLoading] = useState(false)

    const onChangeText = (prop) => (event) => {
        if (prop === ('firstNameDataType' || 'chartNumDataType' || 'amountDataType' ||
            'lastNameDataType' || 'cellPhoneDataType' || 'emailDataType')
        ) {
            if (prop === 'firstNameDataType' && (event.target.value === 'windowTitle' || event.target.value === '')) {
                setForm({ ...form, [prop]: event.target.value, firstNamePath: '' })
            } else if (prop === 'chartNumDataType' && (event.target.value === 'windowTitle' || event.target.value === '')) {
                setForm({ ...form, [prop]: event.target.value, chartNumberPath: '' })
            } else if (prop === 'amountDataType' && (event.target.value === 'windowTitle' || event.target.value === '')) {
                setForm({ ...form, [prop]: event.target.value, amountPath: '' })
            } else if (prop === 'lastNameDataType' && (event.target.value === 'windowTitle' || event.target.value === '')) {
                setForm({ ...form, [prop]: event.target.value, transactionIdPath: '' })
            } else if (prop === 'cellPhoneDataType' && (event.target.value === 'windowTitle' || event.target.value === '')) {
                setForm({ ...form, [prop]: event.target.value, transactionIdPath: '' })
            } else if (prop === 'emailDataType' && (event.target.value === 'windowTitle' || event.target.value === '')) {
                setForm({ ...form, [prop]: event.target.value, transactionIdPath: '' })
            } else {
                setForm({ ...form, [prop]: event.target.value })
            }
        } else {
            setForm({ ...form, [prop]: event.target.value })
        }
    };

    const send = async () => {
        setLoading(true)
        if (
            (form.amountDataType === 'field' && (form.amountPath === '' || form.amountIndicator === '')) ||
            (form.lastNameDataType === 'field' && (form.lastNamePath === '' || form.lastNIndicator === '')) ||
            (form.firstNameDataType === 'field' && (form.firstNamePath === '' || form.fistNIndicator === '')) ||
            (form.chartNumDataType === 'field' && (form.chartNumberPath === '' || form.chartNumIndicator === '')) ||
            form.processName === '' || form.fistNIndicatorType === '' || form.chartNumIndicatorType === '' || form.amountIndicatorType === '' ||
            (form.lastNameDataType === 'windowTitle' && (form.lNRegularExpression === '' || form.lNFieldPosition === '' || form.lastNIndicator === '')) ||
            (form.firstNameDataType === 'windowTitle' && (form.fNRegularExpression === '' || form.fNFieldPosition === '' || form.fistNIndicator === '')) ||
            (form.chartNumDataType === 'windowTitle' && (form.cNRegularExpression === '' || form.cNFieldPosition === '' || form.chartNumIndicator === '')) ||
            (form.amountDataType === 'windowTitle' && (form.amountRegularExpression === '' || form.amountFieldPosition === '' || form.amountIndicator === ''))
        ) {
            setErrorField(true)
            setLoading(false)
        } else {
            setLoading(false)
            onSave(form)
        }
    }

    const aplicaionsOptions = [
        { label: '', value: '', },
        { label: 'Window Title', value: 'windowTitle', },
        { label: 'Field', value: 'field', },
    ]

    const windowOptions = [
        { label: '', value: '' },
        { label: 'Starts With', value: 'startsWith' },
        { label: 'Contains', value: 'contains' },
    ]

    return (
        <Dialog
            style={{
                justifyContent: "center",
                justifyItems: "center",
                display: "flex",
            }}
            className={classes.dialog}
            open={open}
        >
            <Card className={classes.cardContainer}>
                <div
                    className='flex justify-center items-center py-20'
                    style={{ background: '#FAFAFD' }}
                >
                    <p className='text-primaryText text-13 font-600'>
                        Receive Payment
                    </p>
                </div>
                <div style={{ marginBottom: 38 }} className='px-16'>
                    {/* --------------------------------- First Name ---------------------------------------------- */}
                    <CustomSelect
                        label='First Name'
                        options={aplicaionsOptions}
                        value={form.firstNameDataType}
                        name='firstNameDataType'
                        onChange={onChangeText('firstNameDataType')}
                        error={form.firstNameDataType === '' ? errorField : null}
                    />
                    {form.firstNameDataType === 'windowTitle' ?
                        <>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomSelect
                                        label='Window Title'
                                        options={windowOptions}
                                        value={form.fistNIndicatorType}
                                        name='fistNIndicatorType'
                                        onChange={onChangeText('fistNIndicatorType')}
                                        error={form.fistNIndicatorType === '' ? errorField : null}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField={form.fistNIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                        value={form.fistNIndicator}
                                        onChange={onChangeText('fistNIndicator')}
                                        name='fistNIndicator'
                                        error={form.fistNIndicator === '' ? errorField : null}
                                    />
                                </div>
                            </div>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField='Regular expression'
                                        value={form.fNRegularExpression}
                                        onChange={onChangeText('fNRegularExpression')}
                                        name='fNRegularExpression'
                                        error={form.fNRegularExpression === '' ? errorField : null}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField='Field position'
                                        value={form.fNFieldPosition}
                                        onChange={onChangeText('fNFieldPosition')}
                                        name='fNFieldPosition'
                                        error={form.fNFieldPosition === '' ? errorField : null}
                                    />
                                </div>
                            </div>
                        </>
                        : form.firstNameDataType === 'field' ?
                            <>
                                <div className='flex justify-between items-end'>
                                    <div style={{ width: '48%' }}>
                                        <CustomSelect
                                            label='Window path'
                                            options={windowOptions}
                                            value={form.fistNIndicatorType}
                                            name='fistNIndicatorType'
                                            onChange={onChangeText('fistNIndicatorType')}
                                            error={form.fistNIndicatorType === '' ? errorField : null}
                                        />
                                    </div>
                                    <div style={{ width: '48%' }}>
                                        <CustomTextField
                                            textTopField={form.fistNIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                            value={form.fistNIndicator}
                                            onChange={onChangeText('fistNIndicator')}
                                            name='fistNIndicator'
                                            error={form.fistNIndicator === '' ? errorField : null}
                                        />
                                    </div>
                                </div>
                                <CustomTextField
                                    textTopField='First name path'
                                    value={form.firstNamePath}
                                    onChange={onChangeText('firstNamePath')}
                                    name='firstNamePath'
                                    error={form.firstNamePath === '' ? errorField : null}
                                />
                            </>
                            : ''
                    }
                    <Divider style={{ marginTop: 15 }} />
                    {/* --------------------------------- Last Name ---------------------------------------------- */}
                    <CustomSelect
                        label='Last Name'
                        options={aplicaionsOptions}
                        value={form.lastNameDataType}
                        name='lastNameDataType'
                        onChange={onChangeText('lastNameDataType')}
                    />
                    {form.lastNameDataType === 'windowTitle' ?
                        <>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomSelect
                                        label='Window title'
                                        options={windowOptions}
                                        value={form.lastNIndicatorType}
                                        name='lastNIndicatorType'
                                        onChange={onChangeText('lastNIndicatorType')}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField={form.lastNIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                        value={form.lastNIndicator}
                                        onChange={onChangeText('lastNIndicator')}
                                        name='lastNIndicator'
                                    />
                                </div>
                            </div>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField='Regular expression'
                                        value={form.lNRegularExpression}
                                        onChange={onChangeText('lNRegularExpression')}
                                        name='lNRegularExpression'
                                        error={form.lNRegularExpression === '' ? errorField : null}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField='Field position'
                                        value={form.lNFieldPosition}
                                        onChange={onChangeText('lNFieldPosition')}
                                        name='lNFieldPosition'
                                        error={form.lNFieldPosition === '' ? errorField : null}
                                    />
                                </div>
                            </div>
                        </>
                        : form.lastNameDataType === 'field' ?
                            <>
                                <div className='flex justify-between items-end'>
                                    <div style={{ width: '48%' }}>
                                        <CustomSelect
                                            label='Window path'
                                            options={windowOptions}
                                            value={form.lastNIndicatorType}
                                            name='lastNIndicatorType'
                                            onChange={onChangeText('lastNIndicatorType')}
                                        />
                                    </div>
                                    <div style={{ width: '48%' }}>
                                        <CustomTextField
                                            textTopField={form.lastNIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                            value={form.lastNIndicator}
                                            onChange={onChangeText('lastNIndicator')}
                                            name='lastNIndicator'
                                        />
                                    </div>
                                </div>
                                <CustomTextField
                                    textTopField='Last name path'
                                    value={form.lastNamePath}
                                    onChange={onChangeText('lastNamePath')}
                                    name='lastNamePath'
                                />
                            </>
                            : ''
                    }
                    {/* ---------------------- */}
                    <Divider style={{ marginTop: 15 }} />
                    {/* --------------------------------- Chart Number ---------------------------------------------- */}
                    <CustomSelect
                        label='Chart Number'
                        options={aplicaionsOptions}
                        value={form.chartNumDataType}
                        name='chartNumDataType'
                        onChange={onChangeText('chartNumDataType')}
                        error={form.chartNumDataType === '' ? errorField : null}
                    />
                    {form.chartNumDataType === 'windowTitle' ?
                        <>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomSelect
                                        label='Window Title'
                                        options={windowOptions}
                                        value={form.chartNumIndicatorType}
                                        name='chartNumIndicatorType'
                                        onChange={onChangeText('chartNumIndicatorType')}
                                        error={form.chartNumIndicatorType === '' ? errorField : null}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField={form.chartNumIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                        value={form.chartNumIndicator}
                                        onChange={onChangeText('chartNumIndicator')}
                                        name='chartNumIndicator'
                                        error={form.chartNumIndicator === '' ? errorField : null}
                                    />
                                </div>
                            </div>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField='Regular expression'
                                        value={form.cNRegularExpression}
                                        onChange={onChangeText('cNRegularExpression')}
                                        name='cNRegularExpression'
                                        error={form.cNRegularExpression === '' ? errorField : null}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField='Field position'
                                        value={form.cNFieldPosition}
                                        onChange={onChangeText('cNFieldPosition')}
                                        name='cNFieldPosition'
                                        error={form.cNFieldPosition === '' ? errorField : null}
                                    />
                                </div>
                            </div>
                        </>
                        : form.chartNumDataType === 'field' ?
                            <>
                                <div className='flex justify-between items-end'>
                                    <div style={{ width: '48%' }}>
                                        <CustomSelect
                                            label='Window path'
                                            options={windowOptions}
                                            value={form.chartNumIndicatorType}
                                            name='chartNumIndicatorType'
                                            onChange={onChangeText('chartNumIndicatorType')}
                                            error={form.chartNumIndicatorType === '' ? errorField : null}
                                        />
                                    </div>
                                    <div style={{ width: '48%' }}>
                                        <CustomTextField
                                            textTopField={form.chartNumIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                            value={form.chartNumIndicator}
                                            onChange={onChangeText('chartNumIndicator')}
                                            name='chartNumIndicator'
                                            error={form.chartNumIndicator === '' ? errorField : null}
                                        />
                                    </div>
                                </div>
                                <CustomTextField
                                    textTopField='Chart number path'
                                    value={form.chartNumberPath}
                                    onChange={onChangeText('chartNumberPath')}
                                    name='chartNumberPath'
                                    error={form.chartNumberPath === '' ? errorField : null}
                                />
                            </>
                            : ''
                    }
                    {/* ---------------------- */}
                    <Divider style={{ marginTop: 15 }} />
                    {/* --------------------------------- Amount ---------------------------------------------- */}
                    <CustomSelect
                        label='Amount'
                        options={aplicaionsOptions}
                        value={form.amountDataType}
                        name='amountDataType'
                        onChange={onChangeText('amountDataType')}
                        error={form.amountDataType === '' ? errorField : null}
                    />
                    {form.amountDataType === 'windowTitle' ?
                        <>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomSelect
                                        label='Window Title'
                                        options={windowOptions}
                                        value={form.amountIndicatorType}
                                        name='amountIndicatorType'
                                        onChange={onChangeText('amountIndicatorType')}
                                        error={form.amountIndicatorType === '' ? errorField : null}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField={form.amountIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                        value={form.amountIndicator}
                                        onChange={onChangeText('amountIndicator')}
                                        name='amountIndicator'
                                        error={form.amountIndicator === '' ? errorField : null}
                                    />
                                </div>
                            </div>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField='Regular expression'
                                        value={form.amountRegularExpression}
                                        onChange={onChangeText('amountRegularExpression')}
                                        name='amountRegularExpression'
                                        error={form.amountRegularExpression === '' ? errorField : null}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField='Field position'
                                        value={form.amountFieldPosition}
                                        onChange={onChangeText('amountFieldPosition')}
                                        name='amountFieldPosition'
                                        error={form.amountFieldPosition === '' ? errorField : null}
                                    />
                                </div>
                            </div>
                        </>
                        : form.amountDataType === 'field' ?
                            <>
                                <div className='flex justify-between items-end'>
                                    <div style={{ width: '48%' }}>
                                        <CustomSelect
                                            label='Window path'
                                            options={windowOptions}
                                            value={form.amountIndicatorType}
                                            name='amountIndicatorType'
                                            onChange={onChangeText('amountIndicatorType')}
                                            error={form.amountIndicatorType === '' ? errorField : null}
                                        />
                                    </div>
                                    <div style={{ width: '48%' }}>
                                        <CustomTextField
                                            textTopField={form.amountIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                            value={form.amountIndicator}
                                            onChange={onChangeText('amountIndicator')}
                                            name='amountIndicator'
                                            error={form.amountIndicator === '' ? errorField : null}
                                        />
                                    </div>
                                </div>
                                <CustomTextField
                                    textTopField='Amount path'
                                    value={form.amountPath}
                                    onChange={onChangeText('amountPath')}
                                    name='amountPath'
                                    error={form.amountPath === '' ? errorField : null}
                                />
                            </>
                            : ''
                    }

                    {/* ---------------------- */}
                    <Divider style={{ marginTop: 15 }} />
                    {/* --------------------------------- Email ---------------------------------------------- */}
                    <CustomSelect
                        label='Email'
                        options={aplicaionsOptions}
                        value={form.emailDataType}
                        name='emailDataType'
                        onChange={onChangeText('emailDataType')}
                    />
                    {form.emailDataType === 'windowTitle' ?
                        <>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomSelect
                                        label='Window Title'
                                        options={windowOptions}
                                        value={form.emailIndicatorType}
                                        name='emailIndicatorType'
                                        onChange={onChangeText('emailIndicatorType')}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField={form.emailIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                        value={form.emailIndicator}
                                        onChange={onChangeText('emailIndicator')}
                                        name='emailIndicator'
                                    />
                                </div>
                            </div>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField='Regular expression'
                                        value={form.emailRegularExpression}
                                        onChange={onChangeText('emailRegularExpression')}
                                        name='emailRegularExpression'
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField='Field position'
                                        value={form.emailFieldPosition}
                                        onChange={onChangeText('emailFieldPosition')}
                                        name='emailFieldPosition'
                                    />
                                </div>
                            </div>
                        </>
                        : form.emailDataType === 'field' ?
                            <>
                                <div className='flex justify-between items-end'>
                                    <div style={{ width: '48%' }}>
                                        <CustomSelect
                                            label='Window path'
                                            options={windowOptions}
                                            value={form.emailIndicatorType}
                                            name='emailIndicatorType'
                                            onChange={onChangeText('emailIndicatorType')}
                                        />
                                    </div>
                                    <div style={{ width: '48%' }}>
                                        <CustomTextField
                                            textTopField={form.emailIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                            value={form.emailIndicator}
                                            onChange={onChangeText('emailIndicator')}
                                            name='emailIndicator'
                                        />
                                    </div>
                                </div>
                                <CustomTextField
                                    textTopField='Email path'
                                    value={form.emailPath}
                                    onChange={onChangeText('emailPath')}
                                    name='emailPath'
                                />

                            </>
                            : ''
                    }
                    {/* ---------------------- */}
                    <Divider style={{ marginTop: 15 }} />
                    {/* --------------------------------- Cell phone ---------------------------------------------- */}
                    <CustomSelect
                        label='Cell phone'
                        options={aplicaionsOptions}
                        value={form.cellPhoneDataType}
                        name='cellPhoneDataType'
                        onChange={onChangeText('cellPhoneDataType')}
                    />
                    {form.cellPhoneDataType === 'windowTitle' ?
                        <>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomSelect
                                        label='Window Title'
                                        options={windowOptions}
                                        value={form.cellPIndicatorType}
                                        name='cellPIndicatorType'
                                        onChange={onChangeText('cellPIndicatorType')}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField={form.cellPIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                        value={form.cellPhoneIndicator}
                                        onChange={onChangeText('cellPhoneIndicator')}
                                        name='cellPhoneIndicator'
                                    />
                                </div>
                            </div>
                            <div className='flex justify-between items-end'>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField='Regular expression'
                                        value={form.cPRegularExpression}
                                        onChange={onChangeText('cPRegularExpression')}
                                        name='cPRegularExpression'
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <CustomTextField
                                        textTopField='Field position'
                                        value={form.cPFieldPosition}
                                        onChange={onChangeText('cPFieldPosition')}
                                        name='cPFieldPosition'
                                    />
                                </div>
                            </div>
                        </>
                        : form.cellPhoneDataType === 'field' ?
                            <>
                                <div className='flex justify-between items-end'>
                                    <div style={{ width: '48%' }}>
                                        <CustomSelect
                                            label='Window path'
                                            options={windowOptions}
                                            value={form.cellPIndicatorType}
                                            name='cellPIndicatorType'
                                            onChange={onChangeText('cellPIndicatorType')}
                                        />
                                    </div>
                                    <div style={{ width: '48%' }}>
                                        <CustomTextField
                                            textTopField={form.cellPIndicatorType === 'contains' ? 'Contains' : 'Starts With'}
                                            value={form.cellPhoneIndicator}
                                            onChange={onChangeText('cellPhoneIndicator')}
                                            name='cellPhoneIndicator'
                                        />
                                    </div>
                                </div>
                                <CustomTextField
                                    textTopField='Cell phone Path'
                                    value={form.cellPhonePath}
                                    onChange={onChangeText('cellPhonePath')}
                                    name='cellPhonePath'
                                />
                            </>
                            : ''
                    }
                    <Divider style={{ marginTop: 15 }} />
                    {/* --------------------------------- Process name ---------------------------------------------- */}
                    <CustomTextField
                        textTopField='Process name'
                        value={form.processName}
                        onChange={onChangeText('processName')}
                        name='processName'
                        error={form.processName === '' ? errorField : null}
                    />
                    {loading ?
                        <div className='w-full flex justify-center mt-12'>
                            <CircularProgress size={30} style={{ color: '#0594FB' }} />
                        </div>
                        :
                        <div className='w-full flex justify-end items-center mt-20'>
                            <p
                                className='underline text-textLight mr-10 cursor-pointer'
                                onClick={onClose}
                            >
                                Cancel
                            </p>
                            <CustomButton
                                label='save'
                                onClick={send}
                            />
                        </div>
                    }
                </div>
            </Card>
        </Dialog>
    );
}

export default DialogReceive;