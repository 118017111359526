import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useHistory } from 'react-router-dom';
import { signInRedux } from '../../../firebase/services/userSlice';
import { showMessage } from '../../../firebase/services/messageSlice';

import CustomTextField from '../../CustomTextField';
import CustomButton from '../../CustomButton';

import Navbar from '../../Navbar';

const useStyles = makeStyles({
    typography: {
        textAlign: "left",
        fontSize: 12,
        marginBottom: 8,
        marginTop: 16,
        fontWeight: 500,
        color: '#99A0AB'
    },
    customText: {

        width: '100%',
        '& .MuiFormHelperText-root': {
            color: 'red',
            marginTop: 4,
            fontWeight: 500,
            textAlign: 'right',
        },
    },
});


const Login = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [form, setForm] = useState({
        email: '',
        password: '',
    });
    const [errorField, setErrorField] = useState(false);
    const [emailValidate, setEmailValidate] = useState("");

    const onChangeEmail = (event) => {
        const caracteres = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const t = event.currentTarget.value.split(" ");
        let text = "";
        t.map((r) => {
            if (r !== "") {
                text += r;
            }
        });
        setForm({ ...form, email: text.toLowerCase() });
        if (text !== "") {
            if (caracteres.test(text)) {
                setEmailValidate(false);
            } else {
                setEmailValidate(true);
            }
        } else {
            setEmailValidate(true);
        }
    };

    const subminForm = async (event) => {
        if (form.email === '' || form.password === '' ||
            (form.password.length >= 1 && form.password.length < 6)) {
            setErrorField(true)
        } else {
            try {
                await dispatch(signInRedux(form));
                history.push('/dashboard');
            } catch (err) {
                await dispatch(showMessage({ message: err.message, variant: 'error' }))

            }
        };
    }

    return (
        <Container sx={{ mt: 10, mb: 4 }}>
            <Navbar />
            <Grid
                container
                justifyContent='center'
            >
                <Grid
                    item
                    xs={11} sm={7} md={5}
                    style={{ marginTop: 15 }}
                >
                    <p style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 7, fontFamily: 'SF Pro Display' }}>
                        Login
                    </p>
                    <p className={classes.typography}> Email </p>
                    <CustomTextField
                        name="email"
                        id="email"
                        value={form.email}
                        className={classes.customText}
                        placeholder="your@email.com"
                        error={form.email !== '' ? emailValidate : errorField}
                        onChange={onChangeEmail}
                        helperText={form.email === '' ? "required" : emailValidate ? 'Please enter a valid email' : null}

                    />
                    <p className={classes.typography}> Password </p>
                    <CustomTextField
                        name="password"
                        id="password"
                        value={form.password}
                        placeholder="*******"
                        type="password"
                        className={classes.customText}
                        error={errorField || (form.password.length >= 1 && form.password.length < 6) ? true : false}
                        helperText={form.password === '' ? "required" : (form.password.length >= 1 && form.password.length < 6) ? 'Min 6 caracters' : ''}
                        onChange={e => {
                            setForm({ ...form, password: e.target.value });
                        }}
                    />
                    <div className="flex justify-end mt-8">
                        <Link
                            to='/forgot-password'
                            className={classes.typography}
                            style={{ color: '#F0AD6F', textDecoration: 'none', fontSize: 16, margin: 0 }}
                        >
                            Forgot password?
                        </Link>
                    </div>
                    <div className="text-right mt-12">
                        <CustomButton
                            label='login'
                            onClick={subminForm}
                        />
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Login;