import React from 'react';
import {
    Radio,
    FormControlLabel,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    contentCheck: {
        color: '#333333',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        textAlign: 'left',
    },
    customCheckDisabled: {
        padding: 0,
        '&.MuiCheckbox-colorPrimary.Mui-disabled': {
            color: 'red',
        },
    },
    customRadio: {
        color: '#ECEEF2',
        padding: 0,
        boxShadow:'inset 2px 4px 6px rgba(201, 203, 217, 0.15)',
        '&.MuiRadio-colorPrimary.Mui-checked': {
            color: '#197FE8',
        },
        '&.Mui-disabled': {
            color: '#ECEEF2 !important',
        }
    },
    textCheck: {
        fontSize: 16,
        marginLeft: 6,
    },
}))

// onChange: () => void;
// disabled: boolean;
// checked: boolean;
// text: string;

const CustomRadioCheck = (props) => {
    const { text, disabled, onChange, checked, style, textColor } = props;
    const classes = useStyles();

    return (
        <div className={classes.contentCheck} style={{...style}}>
            <FormControlLabel
                {...props}
                onChange={disabled === true ? null : onChange}
                checked={checked}
                value="end"
                control={<Radio color="primary" className={disabled === true ? classes.customCheckDisabled : classes.customRadio} />}
                style={{  padding: 0, margin: 0 }}
                label=""
            />
            <Typography className={classes.textCheck} style={{ color: textColor }}>
                {text}
            </Typography>
        </div>
    )
}
export default CustomRadioCheck;