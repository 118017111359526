import { Dialog } from '@mui/material';

const CustomDialog = (props) => {
    const { open, title, contentText, actions, img } = props;

    return (
        <Dialog open={open}>
            <div className='flex flex-col items-center py-20'>
                <img src={img ? img : '/images/deleteConfirm.png'} />
                <p className='font-bold text-12 text-center mt-16 w-4/5' style={{ color: '#455866' }}>
                    {title}
                </p>
                <p className=' text-10 m-16 text-center' style={{ color: '#455866' }}>
                    {contentText}
                </p>
                {actions}
            </div>
        </Dialog>
    );
};

export default CustomDialog;
