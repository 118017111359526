import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Card, CircularProgress, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  createCompany,
  getAllCompanies,
} from 'app/firebase/services/companiesSlice';

import CustomTextField from 'app/components/CustomTextField';
import CustomButton from 'app/components/CustomButton';
import CustomPhoneNumberInput from 'app/components/CustomPhoneNumberInput';

const useStyles = makeStyles({
  dialog: {
    '& .MuiPaper-root.MuiDialog-paper': {
      borderRadius: 20
    }
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#F1F3FB',
    marginTop: 20,
    marginBottom: 0
  },
  cardContainer: {
    margin: 'auto',
    minWidth: 450,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 0
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#DFE2E2',
      borderRadius: 4
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#888',
      borderRadius: 4
    },
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  textField: {
    color: '#99A0AB',
    fontSize: 12,
    marginTop: 12,
  }
});

function AddCompany(props) {
  const { onClose, open } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [errorField, setErrorField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    url: '',
    phone: '',
    locationName: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    locationPhone: '',
  });

  const onChangeText = (prop) => (event) => {
    setForm({ ...form, [prop]: event.target.value });
  };

  const sendNewCompany = async () => {
    setLoading(true);
    if (
      form.name === '' ||
      form.url === '' ||
      form.phone === '' ||
      form.phone === undefined ||
      form.locationName === '' ||
      form.city === '' ||
      form.state === '' ||
      form.street === '' ||
      form.zip === '' ||
      form.locationPhone === '' ||
      form.locationPhone === undefined
    ) {
      setErrorField(true);
      setLoading(false);
    } else {
      await dispatch(createCompany(form));
      await dispatch(getAllCompanies());
      setLoading(false);
      onClose();
    }
  };

  const changeNumber = (prop) => (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value.length === 0 || re.test(event.target.value)) {
      setForm({ ...form, [prop]: event.target.value });
    }
  };

  return (
    <Dialog
      style={{
        justifyContent: 'center',
        justifyItems: 'center',
        display: 'flex'
      }}
      open={open}
      className={classes.dialog}
    >
      <Card className={classes.cardContainer}>
        <div
          className="flex justify-center items-center py-12"
          style={{ background: '#FAFAFD' }}
        >
          <p className="text-primaryText text-13 font-600">Add a new Company</p>
        </div>
        <div style={{ marginBottom: 38 }} className="px-16">
          <CustomTextField
            textTopField="Company Name"
            value={form.name}
            onChange={onChangeText('name')}
            name="name"
            error={form.name === '' ? errorField : null}
          />
          <CustomTextField
            textTopField="URL"
            value={form.url}
            onChange={onChangeText('url')}
            name="url"
            error={form.url === '' ? errorField : null}
            placeholder="https://www.example.com"
          />
          <p
            className={classes.textField}
            style={{
              color: (form.phone === '' || form.phone === undefined) && errorField ?
                '#d32f2f' : '#99A0AB'
            }}
          >
            Phone Number
          </p>
          <CustomPhoneNumberInput
            country="US"
            value={form.phone}
            maxLength={14}
            onChange={(e) => {
              setForm({ ...form, phone: e });
            }}
            style={{
              border:
                (form.phone === '' || form.phone === undefined) && errorField ?
                  '1px solid #d32f2f' : null
            }}
          />
          <div className={classes.separator} />
          <CustomTextField
            textTopField="Location Name*"
            value={form.locationName}
            onChange={onChangeText('locationName')}
            name="locationName"
            error={form.locationName === '' ? errorField : null}
          />
          <CustomTextField
            textTopField="Address"
            value={form.street}
            onChange={onChangeText('street')}
            name="street"
            error={form.street === '' ? errorField : null}
          />
          <div className="flex justify-between">
            <div className="mr-6">
              <CustomTextField
                textTopField="City"
                value={form.city}
                onChange={onChangeText('city')}
                name="city"
                error={form.city === '' ? errorField : null}
              />
            </div>
            <div className="ml-6">
              <CustomTextField
                textTopField="State"
                value={form.state}
                onChange={onChangeText('state')}
                name="state"
                error={form.state === '' ? errorField : null}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="mr-6">
              <CustomTextField
                textTopField="Zip"
                value={form.zip}
                onChange={changeNumber('zip')}
                name="zip"
                maxLength={15}
                error={form.zip === '' ? errorField : null}
              />
            </div>
            <div className="ml-6">
              <p
                className={classes.textField}
                style={{
                  color: (form.locationPhone === '' || form.locationPhone === undefined) && errorField ?
                    '#d32f2f' : '#99A0AB'
                }}
              >
                Phone Number
              </p>
              <CustomPhoneNumberInput
                country="US"
                value={form.locationPhone}
                maxLength={14}
                onChange={(e) => {
                  setForm({ ...form, locationPhone: e });
                }}
                style={{
                  border:
                    (form.locationPhone === '' || form.locationPhone === undefined) && errorField ?
                      '1px solid #d32f2f' : null
                }}
              />
            </div>
          </div>
          {loading ? (
            <div className="w-full flex justify-center mt-12">
              <CircularProgress size={30} style={{ color: '#0594FB' }} />
            </div>
          ) : (
            <div className="w-full flex justify-end items-center mt-20">
              <p
                className="underline text-textLight mr-10 cursor-pointer"
                onClick={onClose}
              >
                Cancel
              </p>
              <CustomButton label="save" onClick={sendNewCompany} />
            </div>
          )}
        </div>
      </Card>
    </Dialog>
  );
}

export default AddCompany;
