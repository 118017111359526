import { createContext, useState } from "react";

export const CompanyContext = createContext({
    companyId: null,
    setCompanyId: () => { }
});

export const useCompany = (id) => {
    const [companyId, setCompanyId] = useState(id);

    return { companyId, setCompanyId };
};