import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Card, CircularProgress, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { updateCompany, getCompanyById } from 'app/firebase/services/companiesSlice';

import CustomTextField from 'app/components/CustomTextField';
import CustomButton from 'app/components/CustomButton';
import CustomPhoneNumberInput from 'app/components/CustomPhoneNumberInput';

const useStyles = makeStyles({
    dialog: {
        '& .MuiPaper-root.MuiDialog-paper': {
            borderRadius: 20
        },
    },
    cardContainer: {
        margin: "auto",
        minWidth: 500,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    textField: {
        color: '#99A0AB',
        fontSize: 12,
        marginTop: 12,
    }
});

function EditCompany(props) {
    const { onClose, companyId, dataCompany, open } = props
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errorField, setErrorField] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        name: '',
        url: '',
        phone: '',
        application: '',
        city: '',
        state: '',
        street1: '',
        street2: '',
        zip: '',
    })

    const onChangeText = (prop) => (event) => {
        setForm({ ...form, [prop]: event.target.value })
    };

    useEffect(() => {
        if (dataCompany) {
            setForm({
                ...dataCompany
            })
        }
    }, [dataCompany])

    const sendNewCompany = async () => {
        setLoading(true)
        if (form.name === '' || form.url === '' || form.phone === '' || form.phone === undefined
        ) {
            setErrorField(true)
            setLoading(false)
        } else {
            await dispatch(updateCompany(companyId, form))
            await dispatch(getCompanyById(companyId))
            setLoading(false)
            onClose()
        }
    }

    return (
        <Dialog
            style={{
                justifyContent: "center",
                justifyItems: "center",
                display: "flex",
            }}
            className={classes.dialog}
            open={open}
        >

            <Card className={classes.cardContainer}>
                <div
                    className='flex justify-center items-center py-12'
                    style={{ background: '#FAFAFD' }}
                >
                    <p className='text-primaryText text-13 font-600'>
                        Edit company
                    </p>
                </div>
                <div style={{ marginBottom: 38 }} className='px-16'>
                    <CustomTextField
                        textTopField='Company Name'
                        value={form.name}
                        onChange={onChangeText('name')}
                        name='name'
                        error={form.name === '' ? errorField : null}
                    />
                    <CustomTextField
                        textTopField='URL'
                        value={form.url}
                        onChange={onChangeText('url')}
                        name='url'
                        error={form.url === '' ? errorField : null}
                        placeholder='https://www.example.com'
                    />
                    <p
                        className={classes.textField}
                        style={{
                            color: (form.phone === '' || form.phone === undefined) && errorField ?
                                '#d32f2f' : '#99A0AB'
                        }}
                    >
                        Phone Number
                    </p>
                    <CustomPhoneNumberInput
                        country="US"
                        value={form.phone}
                        maxLength={14}
                        onChange={e => {
                            setForm({ ...form, phone: e });
                        }}
                        style={{
                            border: (form.phone === '' || form.phone === undefined) && errorField ?
                                '1px solid #d32f2f' : null
                        }}
                    />
                    {loading ?
                        <div className='w-full flex justify-center mt-12'>
                            <CircularProgress size={30} style={{ color: '#0594FB' }} />
                        </div>
                        :
                        <div className='w-full flex justify-end items-center mt-20'>
                            <p
                                className='underline text-textLight mr-10 cursor-pointer'
                                onClick={onClose}
                            >
                                Cancel
                            </p>
                            <CustomButton
                                label='save'
                                onClick={sendNewCompany}
                            />
                        </div>
                    }
                </div>
            </Card>
        </Dialog>
    );
}

export default EditCompany;
