import { configureStore } from '@reduxjs/toolkit';

import userSlice from './userSlice';
import showMessage from './messageSlice';
import companiesSlice from './companiesSlice'
import contactSlice from './contactSlice';
import locationsSlice from './locationsSlice';
import uiSchemaSlice from './uiSchemaSlice';
import conectionsSlice from './conectionsSlice';
import notificationsSlice from './notificationsSlice'

export default configureStore({
  reducer: {
    user: userSlice,
    message: showMessage,
    companies: companiesSlice,
    contacts: contactSlice,
    locations: locationsSlice,
    uiSchema: uiSchemaSlice,
    connections: conectionsSlice,
    notifications: notificationsSlice,
  },
  devTools: true,
});
