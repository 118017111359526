import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import firebase from '../firebase';
import { isEmpty } from 'ramda';

/**
 * @author Randall Medina
 * @description Function to get the list of notifications
 */
export const getAllNotifications = () => async (dispatch) => {
  const db = firebase.firestore();
  try {
    const dataCompany = await db.collection('Notifications').get();
    const list = dataCompany.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    await dispatch(setDataNotifications(list));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getAllNotifications *** ${error}`);
  }
};

/**
 * @author Randall Medina
 * @description Function to get the list of companies
 */
export const getAllCompanies = () => async (dispatch) => {
  const db = firebase.firestore();
  try {
    const dataCompany = await db.collection('Companies').get();
    const list = dataCompany.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    await dispatch(setDataCompanies(list));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getAllCompanies *** ${error}`);
  }
};

/**
 * @author Randall Medina
 * @description Function to get version list
 */
export const getAllAppVersioning = () => async (dispatch) => {
  const db = firebase.firestore();
  try {
    const dataAppVersioning = await db.collection('AppVersioning').get();
    const list = dataAppVersioning.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    await dispatch(setDataVersioning(list));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getAllAppVersioning *** ${error}`);
  }
};

/**
 * @author Randall Medina
 * @description Function to get company by id
 * @param companyId
 */
export const getContactsById = (companyId) => async (dispatch) => {
  const db = firebase.firestore();
  try {
    const dataContacts = await db
      .collection('Companies')
      .doc(companyId)
      .collection('Contacts')
      .get();
      const list = dataContacts.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      await dispatch(setDataContacts(list));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getContactsById *** ${error}`);
  }
};

/**
 * @author Randall Medina
 * @description Function to create notifications
 * @param data
 */
export const createNotification = (data) => async (dispatch) => {
  const db = firebase.firestore();
  try {
    const resultCompany = await db.collection('Notifications').add(data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> createNotification *** ${error}`);
  }
};

/**
 * @author Randall Medina
 * @description Function to update notifications
 * @param id
 * @param data
 */
export const updateNotification = (id, data) => async (dispatch) => {
  const db = firebase.firestore();
  try {
    await db.collection('Notifications').doc(id).update(data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> updateNotification *** ${error}`);
  }
};

/**
 * @author Randall Medina
 * @description Function to delete notifications
 * @param id
 */
export const deleteNotification = (id) => async (dispatch) => {
  const db = firebase.firestore();
  try {
    await db.collection('Notifications').doc(id).delete();
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> deleteNotification *** ${error}`);
  }
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    loading: false,
    dataNotifications: [],
    dataContacts: [],
    dataCompanies: [],
    dataVersioning: [],
    enabledIntegration: false,
    notificationById: {}
  },
  reducers: {
    setDataNotifications: (state, action) => {
      state.dataNotifications = action.payload;
    },
    setDataContacts: (state, action) => {
      state.dataContacts = action.payload;
    },
    setDataCompanies: (state, action) => {
      state.dataCompanies = action.payload;
    },
    setDataVersioning: (state, action) => {
      state.dataVersioning = action.payload;
    },
    setNotificationById: (state, action) => {
      state.notificationById = action.payload;
    }
  }
});

export const { setDataNotifications, setDataContacts, setDataCompanies, setDataVersioning, setNotificationById } = notificationsSlice.actions;

export default notificationsSlice.reducer;
