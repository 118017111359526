import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';

import { CompanyContext, useCompany } from './companyContext';
import { getCompanyById } from 'app/firebase/services/companiesSlice';

import Navbar from 'app/components/Navbar';
import DataCompany from './DataCompany';
import Locations from './Locations';
import Contacts from './Contacts';
import Connections from './Connections';

function CompanyDetail(props) {
    const dispatch = useDispatch();
    const params = useParams();

    const data = useSelector(({ companies }) => companies.companyById);
    const companyId = params.id

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetch = async () => {
            setLoading(true)
            await dispatch(getCompanyById(companyId))
            setLoading(false)
        }
        fetch()
    }, [])

    return (
        <CompanyContext.Provider
            value={useCompany(companyId)}
        >
            <Navbar />
            <Grid
                container
                justifyContent='center'
                className='pb-32'
            >
                <Grid
                    item
                    xs={11} lg={10}
                    style={{ marginTop: 15 }}
                >
                    <DataCompany
                        dataCompany={data}
                        companyId={companyId}
                        loading={loading}
                    />
                    <Locations
                        dataCompany={data}
                        companyId={companyId}
                        loadingData={loading}
                    />
                    <Contacts
                        loadingData={loading}
                        companyId={companyId}
                        dataCompany={data}
                    />
                    <Connections
                        loadingData={loading}
                        companyId={companyId}
                        dataCompany={data}
                    />
                </Grid>
            </Grid>
        </CompanyContext.Provider>
    );
}

export default CompanyDetail;
