import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    CircularProgress,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    TableContainer,
    Paper,
    Table,
} from '@mui/material';
import { makeStyles, withStyles, createStyles } from '@mui/styles';
import EditContact from './EditContact';
import CustomPhoneNumberInput from 'app/components/CustomPhoneNumberInput';
import CustomButton from 'app/components/CustomButton';
import CustomDialog from 'app/components/CustomDialog';
import { deleteContact } from 'app/firebase/services/contactSlice';

const StyledTableCell = withStyles(() =>
    createStyles({
        head: {
            color: 'black',
            border: 'none',
            padding: '10 16px',
        },
        body: {
            fontSize: 14,
            padding: '10px 16px',
            border: 'none',
            height: 75,
            color: '#535F68',
            fontWeight: 500
        },
    }),
)(TableCell);

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: 'white',
            },
            backgroundColor: '#FBFBFE',
        },
    }),
)(TableRow);

const useStyles = makeStyles(() => ({
    tableCustom: {
        borderRadius: 6,
        marginTop: 27
    },
    headTableCus: {
        background: '#F1F3FB',
        height: 50
    },
    tablecellCust: {
        color: '#535F68',
        fontSize: 16,
        fontWeight: 'bold',
        width: '25%'
    }
}))

function DataContact(props) {
    const { itemContact, companyId, contactId, loadingData } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [dialogEditContact, setDialogEditContact] = useState(false);
    const [dialogConfirm, setDialogConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const sendDeleteContact = async () => {
        setLoading(true)
        await dispatch(deleteContact(companyId, contactId))
        history.push(`/company/${companyId}`)
        setLoading(false)
    }

    return (
        <div className='w-full flex flex-col py-20 min-h-128'>
            {loadingData ?
                <div className='w-full flex justify-center mt-12'>
                    <CircularProgress size={30} style={{ color: '#0594FB' }} />
                </div>
                :
                <div>
                    <CustomDialog
                        title='Are you sure you want to delete the contact?'
                        open={dialogConfirm}
                        actions={
                            loading ?
                                <CircularProgress size={30} style={{ color: '#0594FB' }} />
                                :
                                <div className='w-full flex justify-center items-end mt-10'>
                                    <p
                                        className='underline text-textLight mr-10 cursor-pointer'
                                        onClick={() => { setDialogConfirm(false) }}
                                    >
                                        Cancel
                                    </p>
                                    <CustomButton
                                        label='Delete'
                                        onClick={sendDeleteContact}
                                    />
                                </div>
                        }
                    />
                    <div className='flex justify-between items-end'>
                        <div className='flex flex-col'>
                            <p className='text-primaryText text-15 font-600'>
                                {itemContact.contact.name}
                            </p>
                            <div className='flex text-textLight' style={{ fontSize: 14 }}>
                                <p
                                    className='underline cursor-pointer'
                                    onClick={() => { history.push(`/company/${companyId}`) }}
                                >
                                    User list
                                </p>
                                &nbsp;/&nbsp;
                                <p className='underline'>
                                    {itemContact.contact.name}
                                </p>
                            </div>
                        </div>
                        <div className='flex'>
                            <CustomButton
                                label='Delete Contact'
                                style={{ background: '#FD8585', marginRight: 15 }}
                                onClick={() => {
                                    setDialogConfirm(true);
                                }}
                            />
                            <CustomButton
                                label='Edit Contact'
                                onClick={() => {
                                    setDialogEditContact(true);
                                }}
                            />
                        </div>
                    </div>
                    <TableContainer component={Paper} className={classes.tableCustom}>
                        <Table sx={{ minWidth: 650 }} >
                            <TableHead className={classes.headTableCus}>
                                <TableRow>
                                    <TableCell className={classes.tablecellCust}>
                                        Name
                                    </TableCell>
                                    <TableCell className={classes.tablecellCust} style={{ width: '25%' }}>
                                        Email
                                    </TableCell>
                                    <TableCell className={classes.tablecellCust}>
                                        Phone
                                    </TableCell>
                                    <TableCell className={classes.tablecellCust} style={{ width: '25%' }}>
                                        Title
                                    </TableCell>
                                    {/* <TableCell className={classes.tablecellCust} /> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow >
                                    <StyledTableCell
                                        align="left"
                                        style={{ width: '20%' }}
                                    >
                                        {itemContact.contact.name}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        style={{ width: '25%', color: '#0594FB' }}
                                    >
                                        {itemContact.contact.email}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        style={{ width: '20%' }}
                                        align="left"
                                    >
                                        <CustomPhoneNumberInput
                                            country="US"
                                            value={itemContact.contact.phone ? itemContact.contact.phone : ''}
                                            maxLength={14}
                                            disabled={true}
                                            onChange={(e) => e.target.value}
                                            style={{
                                                border: '1px solid transparent',
                                                fontSize: 14,
                                                color: '#535F68',
                                                background: 'transparent',
                                                padding: 0
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell
                                        style={{ width: '25%' }}
                                        align="left"
                                    >
                                        {itemContact.contact.title}
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
            <EditContact
                open={dialogEditContact}
                companyId={companyId}
                contactId={contactId}
                itemContact={itemContact.contact}
                onClose={() => {
                    setDialogEditContact(false);
                }}
            />
        </div>
    );
}

export default DataContact;
