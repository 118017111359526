import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { Card, CircularProgress, Dialog, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createUISchema, getAllUISchema } from 'app/firebase/services/uiSchemaSlice';

import CustomTextField from 'app/components/CustomTextField';
import CustomButton from 'app/components/CustomButton';

import DialogReceive from './DialogReceive';
import DialogPost from './DialogPost';
import DialogRefund from './DialogRefund';
import CustomSelect from 'app/components/CustomSelect';

const useStyles = makeStyles({
    dialog: {
        '& .MuiPaper-root.MuiDialog-paper': {
            borderRadius: 20
        },
    },
    cardContainer: {
        margin: "auto",
        minWidth: 500,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    textField: {
        fontSize: 12,
        marginTop: 12,
    }
});

function AddSchema(props) {
    const { onClose, open } = props
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errorField, setErrorField] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        name: '',
        displayName: '',
        appVersion: '',
        dataFromWindowTitle: false,
        instructions_receivePayment: '',
        instructions_postPayment: '',
        instructions_postRefund: '',
        positive_values: true,
        indicator: ''
    });
    const [toggleDialogReceive, setToggleDialogReceive] = useState(false);
    const [toggleDialogPost, setToggleDialogPost] = useState(false);
    const [toggleDialogRefund, setToggleDialogRefund] = useState(false);

    const [formReceive, setFormReceive] = useState({
        firstNameDataType: '', /**Field or windowTitle**/
        fistNIndicatorType: '',/** Indicator type: startsWith or contains **/
        fistNIndicator: '',
        fNRegularExpression: '',/**Window**/
        fNFieldPosition: '',/**Window**/
        firstNamePath: '', /**Path**/

        lastNameDataType: '',
        lastNIndicatorType: '',
        lastNIndicator: '',
        lNRegularExpression: '',
        lNFieldPosition: '',
        lastNamePath: '',

        chartNumDataType: '',
        chartNumIndicatorType: '',
        chartNumIndicator: '',
        cNRegularExpression: '',
        cNFieldPosition: '',
        chartNumberPath: '',

        amountDataType: '',
        amountIndicatorType: '',
        amountIndicator: '',
        amountRegularExpression: '',
        amountFieldPosition: '',
        amountPath: '',

        emailDataType: '',
        emailPath: '',
        emailIndicatorType: '',
        emailIndicator: '',
        emailRegularExpression: '',
        emailFieldPosition: '',

        cellPhoneDataType: '',
        cellPhonePath: '',
        cellPIndicatorType: '',
        cellPhoneIndicator: '',
        cPRegularExpression: '',
        cPFieldPosition: '',

        processName: '',
    });

    const [formPost, setFormPost] = useState({
        firstNameDataType: '',
        firstNamePath: '',
        fistNIndicatorType: '',
        fistNIndicator: '',

        lastNameDataType: '',
        lastNamePath: '',
        lastNIndicatorType: '',
        lastNIndicator: '',

        chartNumDataType: '',
        chartNumberPath: '',
        chartNumIndicatorType: '',
        chartNumIndicator: '',

        amountDataType: '',
        amountPath: '',
        amountIndicatorType: '',
        amountIndicator: '',

        transactionIdDataType: '',
        transactionIdPath: '',
        transactionIdIndicatorType: '',
        transactionIdIndicator: '',

        processName: '',
    });

    const [formRefund, setFormRefund] = useState({
        firstNameDataType: '',
        firstNamePath: '',
        fistNIndicatorType: '',
        fistNIndicator: '',

        lastNameDataType: '',
        lastNamePath: '',
        lastNIndicatorType: '',
        lastNIndicator: '',

        chartNumDataType: '',
        chartNumberPath: '',
        chartNumIndicatorType: '',
        chartNumIndicator: '',

        amountDataType: '',
        amountPath: '',
        amountIndicatorType: '',
        amountIndicator: '',

        transactionIdDataType: '',
        transactionIdPath: '',
        transactionIdIndicatorType: '',
        transactionIdIndicator: '',

        processName: '',
    });

    const onChangeText = (prop) => (event) => {
        setForm({ ...form, [prop]: event.target.value })
    };

    const sendNewUISchema = async () => {
        setLoading(true)
        if (form.name === '' || form.displayName === '' || form.appVersion === '' || form.instructions_receivePayment === ''
            || form.instructions_postPayment === '' || form.instructions_postRefund === '') {
            setErrorField(true)
            setLoading(false)
        } else {
            await dispatch(createUISchema(form))
            await dispatch(getAllUISchema())
            setLoading(false)
            onClose()
        }
    }

    // --------------------------------------------------------
    const saveDialogReceive = async (data) => {
        const code = {
            processName: data.processName
        };

        if (data.firstNameDataType === 'windowTitle') { /* first name */
            if (data.fistNIndicatorType === 'startsWith') {
                code.firstNamePath = { location: data.firstNameDataType, startsWith: data.fistNIndicator }
            } else {
                code.firstNamePath = { location: data.firstNameDataType, contains: data.fistNIndicator }
            }
        } else {
            if (data.fistNIndicatorType === 'startsWith') {
                code.firstNamePath = { location: data.firstNameDataType, startsWith: data.fistNIndicator, path: data.firstNamePath }
            } else {
                code.firstNamePath = { location: data.firstNameDataType, contains: data.fistNIndicator, path: data.firstNamePath }
            }
        }
        if (data.lastNameDataType === 'windowTitle') { /* last name */
            if (data.lastNIndicatorType === 'startsWith') {
                code.lastNamePath = { location: data.lastNameDataType, startsWith: data.lastNIndicator }
            } else {
                code.lastNamePath = { location: data.lastNameDataType, contains: data.lastNIndicator }
            }
        } else {
            if (data.lastNIndicatorType === 'startsWith') {
                code.lastNamePath = { location: data.lastNameDataType, startsWith: data.lastNIndicator, path: data.lastNamePath }
            } else {
                code.lastNamePath = { location: data.lastNameDataType, contains: data.lastNIndicator, path: data.lastNamePath }
            }
        }
        if (data.chartNumDataType === 'windowTitle') { /* chart num */
            if (data.chartNumIndicatorType === 'startsWith') {
                code.chartNumberPath = { location: data.chartNumDataType, startsWith: data.chartNumIndicator }
            } else {
                code.chartNumberPath = { location: data.chartNumDataType, contains: data.chartNumIndicator }
            }
        } else {
            if (data.chartNumIndicatorType === 'startsWith') {
                code.chartNumberPath = { location: data.chartNumDataType, startsWith: data.chartNumIndicator, path: data.chartNumberPath }
            } else {
                code.chartNumberPath = { location: data.chartNumDataType, contains: data.chartNumIndicator, path: data.chartNumberPath }
            }
        }
        if (data.amountDataType === 'windowTitle') { /* amount */
            if (data.amountIndicatorType === 'startsWith') {
                code.amountPath = { location: data.amountDataType, startsWith: data.amountIndicator }
            } else {
                code.amountPath = { location: data.amountDataType, contains: data.amountIndicator }
            }
        } else {
            if (data.amountIndicatorType === 'startsWith') {
                code.amountPath = { location: data.amountDataType, startsWith: data.amountIndicator, path: data.amountPath }
            } else {
                code.amountPath = { location: data.amountDataType, contains: data.amountIndicator, path: data.amountPath }
            }
        }
        if (data.emailDataType === 'windowTitle') {  /* email */
            if (data.emailIndicatorType === 'startsWith') {
                code.emailPath = { location: data.emailDataType, startsWith: data.emailIndicator }
            } else {
                code.emailPath = { location: data.emailDataType, contains: data.emailIndicator }
            }
        } else {
            if (data.emailIndicatorType === 'startsWith') {
                code.emailPath = { location: data.emailDataType, startsWith: data.emailIndicator, path: data.emailPath }
            } else {
                code.emailPath = { location: data.emailDataType, contains: data.emailIndicator, path: data.emailPath }
            }
        }
        if (data.cellPhoneDataType === 'windowTitle') {  /* cellphone */
            if (data.cellPIndicatorType === 'startsWith') {
                code.cellPhonePath = { location: data.cellPhoneDataType, startsWith: data.cellPhoneIndicator }
            } else {
                code.cellPhonePath = { location: data.cellPhoneDataType, contains: data.cellPhoneIndicator }
            }
        } else {
            if (data.cellPIndicatorType === 'startsWith') {
                code.cellPhonePath = { location: data.cellPhoneDataType, startsWith: data.cellPhoneIndicator, path: data.cellPhonePath }
            } else {
                code.cellPhonePath = { location: data.cellPhoneDataType, contains: data.cellPhoneIndicator, path: data.cellPhonePath }
            }
        }
        const fromWindow = (data.firstNameDataType || data.lastNameDataType || data.chartNumDataType || data.amountDataType || data.emailDataType || data.cellPhoneDataType) === 'windowTitle'
        if (fromWindow) {
            const newData = {
                ...form,
                instructions_receivePayment: JSON.stringify(code),
                dataFromWindowTitle: fromWindow,
            };
            if (data.firstNameDataType === 'windowTitle') {
                newData.firstNameInstr = { regularExprs: data.fNRegularExpression, position: data.fNFieldPosition }
            }
            if (data.lastNameDataType === 'windowTitle') {
                newData.lastNameInstr = { regularExprs: data.lNRegularExpression, position: data.lNFieldPosition }
            }
            if (data.chartNumDataType === 'windowTitle') {
                newData.chartNumInstr = { regularExprs: data.cNRegularExpression, position: data.cNFieldPosition }
            }
            if (data.amountDataType === 'windowTitle') {
                newData.amountInstr = { regularExprs: data.amountRegularExpression, position: data.amountFieldPosition }
            }
            if (data.emailDataType === 'windowTitle') {
                newData.emailInstr = { regularExprs: data.emailRegularExpression, position: data.emailFieldPosition }
            }
            if (data.cellPhoneDataType === 'windowTitle') {
                newData.cellPhoneInstr = { regularExprs: data.cPRegularExpression, position: data.cPFieldPosition }
            }
            setForm(newData)
        } else {
            setForm({
                ...form,
                instructions_receivePayment: JSON.stringify(code),
                dataFromWindowTitle: fromWindow,
            })
        }
        setToggleDialogReceive(false);
    }

    const closeDialogReceive = async () => {
        setFormReceive({
            firstNameDataType: '',
            lastNameDataType: '',
            chartNumDataType: '',
            amountDataType: '',
            emailDataType: '',
            cellPhoneDataType: '',
            processName: '',
        });
        setToggleDialogReceive(false);
    }

    // --------------------------------------------------------
    const saveDialogPost = async (data) => {
        const code = {
            processName: data.processName
        };
        if (data.fistNIndicatorType === 'startsWith') {
            code.firstNamePath = { location: data.firstNameDataType, startsWith: data.fistNIndicator, path: data.firstNamePath }
        } else {
            code.firstNamePath = { location: data.firstNameDataType, contains: data.fistNIndicator, path: data.firstNamePath }
        }
        if (data.lastNIndicatorType === 'startsWith') {
            code.lastNamePath = { location: data.lastNameDataType, startsWith: data.lastNIndicator, path: data.lastNamePath }
        } else {
            code.lastNamePath = { location: data.lastNameDataType, contains: data.lastNIndicator, path: data.lastNamePath }
        }
        if (data.chartNumIndicatorType === 'startsWith') {
            code.chartNumberPath = { location: data.chartNumDataType, startsWith: data.chartNumIndicator, path: data.chartNumberPath }
        } else {
            code.chartNumberPath = { location: data.chartNumDataType, contains: data.chartNumIndicator, path: data.chartNumberPath }
        }
        if (data.amountIndicatorType === 'startsWith') {
            code.amountPath = { location: data.amountDataType, startsWith: data.amountIndicator, path: data.amountPath }
        } else {
            code.amountPath = { location: data.amountDataType, contains: data.amountIndicator, path: data.amountPath }
        }
        if (data.transactionIdIndicatorType === 'startsWith') {
            code.transactionIdPath = { location: data.transactionIdDataType, startsWith: data.transactionIdIndicator, path: data.transactionIdPath }
        } else {
            code.transactionIdPath = { location: data.transactionIdDataType, contains: data.transactionIdIndicator, path: data.transactionIdPath }
        }
        setForm({ ...form, instructions_postPayment: JSON.stringify(code) })
        setToggleDialogPost(false);
    }

    const closeDialogPost = async () => {
        setFormPost({
            firstNameDataType: '',
            lastNameDataType: '',
            chartNumDataType: '',
            amountDataType: '',
            emailDataType: '',
            cellPhoneDataType: '',
            processName: '',
            transactionDataType: '',
        });
        setToggleDialogPost(false);
    }
    // --------------------------------------------------------
    const saveDialogRefund = async (data) => {
        const code = {
            processName: data.processName
        };
        if (data.fistNIndicatorType === 'startsWith') {
            code.firstNamePath = { location: data.firstNameDataType, startsWith: data.fistNIndicator, path: data.firstNamePath }
        } else {
            code.firstNamePath = { location: data.firstNameDataType, contains: data.fistNIndicator, path: data.firstNamePath }
        }
        if (data.lastNIndicatorType === 'startsWith') {
            code.lastNamePath = { location: data.lastNameDataType, startsWith: data.lastNIndicator, path: data.lastNamePath }
        } else {
            code.lastNamePath = { location: data.lastNameDataType, contains: data.lastNIndicator, path: data.lastNamePath }
        }
        if (data.chartNumIndicatorType === 'startsWith') {
            code.chartNumberPath = { location: data.chartNumDataType, startsWith: data.chartNumIndicator, path: data.chartNumberPath }
        } else {
            code.chartNumberPath = { location: data.chartNumDataType, contains: data.chartNumIndicator, path: data.chartNumberPath }
        }
        if (data.amountIndicatorType === 'startsWith') {
            code.amountPath = { location: data.amountDataType, startsWith: data.amountIndicator, path: data.amountPath }
        } else {
            code.amountPath = { location: data.amountDataType, contains: data.amountIndicator, path: data.amountPath }
        }
        if (data.transactionIdIndicatorType === 'startsWith') {
            code.transactionIdPath = { location: data.transactionIdDataType, startsWith: data.transactionIdIndicator, path: data.transactionIdPath }
        } else {
            code.transactionIdPath = { location: data.transactionIdDataType, contains: data.transactionIdIndicator, path: data.transactionIdPath }
        }
        setForm({ ...form, instructions_postRefund: JSON.stringify(code) })
        setToggleDialogRefund(false);
    }

    const closeDialogRefund = async () => {
        setFormRefund({
            firstNameDataType: '',
            lastNameDataType: '',
            chartNumDataType: '',
            amountDataType: '',
            emailDataType: '',
            cellPhoneDataType: '',
            processName: '',
            transactionDataType: '',
        });
        setToggleDialogRefund(false);
    }
    // --------------------------------------------------------
    const onChangeIntegration = (e) => {
        setForm({ ...form, positive_values: !form.positive_values })
    };

    const windowOptions = [
        { label: 'Comma  (1,00)', value: ',' },
        { label: 'Dot  (1.00)', value: '.' },
    ]

    return (
        <Dialog
            style={{
                justifyContent: "center",
                justifyItems: "center",
                display: "flex",
            }}
            className={classes.dialog}
            open={open}
        >
            <Card className={classes.cardContainer}>
                <div className='flex justify-center items-center py-14' style={{ background: '#FAFAFD' }}>
                    <p className='text-primaryText text-13 font-600'>
                        Add UI Schema
                    </p>
                </div>
                <div
                    style={{ marginBottom: 38 }}
                    className='px-20'
                >
                    <CustomTextField
                        textTopField='UI Schemas Application Name*'
                        value={form.name}
                        onChange={onChangeText('name')}
                        name='name'
                        error={form.name === '' ? errorField : null}
                    />
                    <CustomTextField
                        textTopField='Display Name*'
                        value={form.displayName}
                        onChange={onChangeText('displayName')}
                        name='displayName'
                        error={form.displayName === '' ? errorField : null}
                    />
                    <CustomTextField
                        textTopField='UI Application Version'
                        value={form.appVersion}
                        onChange={onChangeText('appVersion')}
                        name='appVersion'
                        error={form.appVersion === '' ? errorField : null}
                    />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: form.instructions_receivePayment === '' && errorField ? '#d32f2f' : '#99A0AB'
                    }}>
                        <p className={classes.textField}>
                            UI Schema Instructions Receive Payment
                        </p>
                        <p
                            className={clsx(classes.textField, 'underline text-textLight mr-10 cursor-pointer')}
                            onClick={() => {
                                setToggleDialogReceive(true);
                            }}
                        >
                            Configure
                        </p>
                    </div>
                    <CustomTextField
                        value={form.instructions_receivePayment}
                        onChange={onChangeText('instructions_receivePayment')}
                        name='instructions_receivePayment'
                        error={form.instructions_receivePayment === '' ? errorField : null}
                        rows={3}
                        multiline={true}
                        disabled={true}
                    />
                    {/* ------------------------------------------------------ */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: form.instructions_receivePayment === '' && errorField ? '#d32f2f' : '#99A0AB'
                    }}>
                        <p className={classes.textField}>
                            UI Schema Instructions Post Payment
                        </p>
                        <p
                            className={clsx(classes.textField, 'underline text-textLight mr-10 cursor-pointer')}
                            onClick={() => {
                                setToggleDialogPost(true);
                            }}
                        >
                            Configure
                        </p>
                    </div>
                    <CustomTextField
                        disabled={true}
                        value={form.instructions_postPayment}
                        onChange={onChangeText('instructions_postPayment')}
                        name='instructions_postPayment'
                        error={form.instructions_postPayment === '' ? errorField : null}
                        rows={3}
                        multiline={true}
                    />
                    {/* ------------------------------------------------------ */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: form.instructions_receivePayment === '' && errorField ? '#d32f2f' : '#99A0AB'
                    }}>
                        <p className={classes.textField}>
                            UI Schema Instructions Post Refund
                        </p>
                        <p
                            className={clsx(classes.textField, 'underline text-textLight mr-10 cursor-pointer')}
                            onClick={() => {
                                setToggleDialogRefund(true);
                            }}
                        >
                            Configure
                        </p>
                    </div>
                    <CustomTextField
                        disabled={true}
                        value={form.instructions_postRefund}
                        onChange={onChangeText('instructions_postRefund')}
                        name='instructions_postRefund'
                        error={form.instructions_postRefund === '' ? errorField : null}
                        rows={3}
                        multiline={true}
                    />
                    <div className='flex justify-between'>
                        <div style={{ width: '48%' }}>
                            <CustomSelect
                                label='Decimal separator (post, refund)'
                                options={windowOptions}
                                value={form.indicator}
                                name='indicator'
                                onChange={onChangeText('indicator')}
                                error={form.indicator === '' ? errorField : null}
                            />
                        </div>
                        <div style={{ width: '48%' }} className='flex justify-end'>
                            <div className='flex justify-between'>
                                <div>
                                    <p className='text-primaryText font-500 mt-10'>
                                        Send positive values
                                    </p>
                                    <div className='flex items-center'>
                                        <p className='text-10'>
                                            No
                                        </p>
                                        <Switch
                                            checked={form.positive_values}
                                            className={classes.switchCustom}
                                            onChange={onChangeIntegration}
                                        />
                                        <p className='text-10'>
                                            Yes
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ?
                        <div className='w-full flex justify-center mt-12'>
                            <CircularProgress size={30} style={{ color: '#0594FB' }} />
                        </div>
                        :
                        <div className='w-full flex justify-end items-center mt-20'>
                            <p
                                className='underline text-textLight mr-10 cursor-pointer'
                                onClick={onClose}
                            >
                                Cancel
                            </p>
                            <CustomButton
                                label='save'
                                onClick={sendNewUISchema}
                            />
                        </div>
                    }
                </div>
                <div>
                    {toggleDialogReceive && (
                        <DialogReceive
                            open={toggleDialogReceive}
                            form={formReceive}
                            setForm={setFormReceive}
                            onSave={saveDialogReceive}
                            onClose={closeDialogReceive}
                        />
                    )}
                    {toggleDialogPost && (
                        <DialogPost
                            open={toggleDialogPost}
                            form={formPost}
                            setForm={setFormPost}
                            onSave={saveDialogPost}
                            onClose={closeDialogPost}
                        />
                    )}
                    {toggleDialogRefund && (
                        <DialogRefund
                            open={toggleDialogRefund}
                            form={formRefund}
                            setForm={setFormRefund}
                            onSave={saveDialogRefund}
                            onClose={closeDialogRefund}
                        />
                    )}
                </div>
            </Card>
        </Dialog>
    );
}

export default AddSchema;